import { useHistory } from "react-router-dom";
import { auth, database } from "../services/firebase";

export async function CheckUserAuth (idCompany: string) {

    const history = useHistory();

    const user = auth.currentUser;

    let idUser = '';

    if(user){
        
        await database.collection("companies").doc(`${idCompany}`).get().then ( (doc) => {
            if(doc.exists){ 
                idUser = doc.data()?.idUser;

                if( (user?.uid !== doc.data()?.idUser) && (doc.data()?.admin === false) ){
                    history.push('/pageNotFound');
                    return;
                }
            }
            else{
                history.push('/pageNotFound');
                return;
            }
        });
    }

    var unsubscribe = auth.onAuthStateChanged((user) => {
        if(user) {

            if(user?.uid !== idUser){
                history.push('/pageNotFound');
                return;
            }

        } else {
            history.push('/');
            return;
        }
    });

    unsubscribe();
}