//Usado para fazer navegação pelas páginas do projeto react
import { Link, useHistory } from 'react-router-dom';

import { FormEvent, useState } from 'react';

import loginImg1 from '../assets/images/gwcard-login1.jpg';
import loginImg2 from '../assets/images/gwcard-login2.jpg';
import loginImg3 from '../assets/images/gwcard-login3.jpg';

import gwcardLogo from '../assets/images/gwcardlogo.svg';


import '../styles/auth.scss';
import { Button } from '../components/Button';
import { auth, database } from '../services/firebase';
import { Toaster, toast } from 'react-hot-toast';
import { Loading } from '../components/Loading';

const randomNumber = Math.floor(Math.random() * 3);

export function Login(){
    const history = useHistory();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    
    const [isLoading, setIsLoading] = useState(false);

    async function navigateToDashboard(event: FormEvent){
        event.preventDefault();

        setIsLoading(true);

        let authUid = '';

        await auth.signInWithEmailAndPassword(email, password)
        .then( (userCredential) => {
            var user = userCredential.user;

            if(!user){
                setIsLoading(false);
                toast.error("Email ou senha incorreto.");
                return;
            }
                
            if(user){

                const { uid, email } = user;
    
                if( !email ){
                    toast.error("Insira um email.");
                    setIsLoading(false);
                    return;
                }

                authUid = uid;
            }
        })
        .catch((error) => {

            if(error){
                setIsLoading(false);
                toast.error('Falha ao realizar login!');
                return;
            }
        });

        //Faz GET de informacoes com o firebase
        const result = await database.collection("companies").where("idUser", "==", `${authUid}`).get();

        if(!result){
            toast.error("Empresa não encontrada!");
            return;
        }
        
        result.forEach( (doc) => {

            if((doc.data().active) === false){
                setIsLoading(false);
                toast.error("Empresa desativada!");
                return;
            }
            
            if( (doc.data().admin) === true ){
                setIsLoading(false);
                history.push(`/dashboard/admin/${doc.id}`);
                return;
            } else {
                setIsLoading(false);
                history.push(`/dashboard/client/${doc.id}`);
                return;
            }
        });

        return;
    }
    
    return(

        <div className="page-login">
            
            { isLoading ? <Loading /> :
                <div id="page-auth">
                    <aside>
                        {/*<img src={IlustrationImg} alt="Ilustração simbolizando perguntas e respostas" />
                        <strong>Cartão Online Prime</strong>
                        <p>Cartões digitais personalizados para sua empresa!</p>*/}
                        {randomNumber === 0 && <img src={loginImg1} alt="Ilustação com pessoa usando o Cartão Online Prime" />}
                        {randomNumber === 1 && <img src={loginImg2} alt="Ilustação com pessoa usando o Cartão Online Prime" />}
                        {randomNumber === 2 && <img src={loginImg3} alt="Ilustação com pessoa usando o Cartão Online Prime" />}
                        {/*<img src={loginImg2} alt="Ilustação com pessoa usando o Cartão Online Prime" />*/}
                        
                    </aside>
                    <main>
                        <div className="main-content">
                            {/*<img src={logoImg} alt="Cartão Online Prime"/>*/}
                            <img src={gwcardLogo} alt="Logo do Cartão Online Prime" />
                            {/*<div className="separator">Faça login na sua conta</div>*/}
                            <h5><strong>Bem-vindo ao Cartão Online Prime!</strong></h5>
                            <form onSubmit={navigateToDashboard}>
                                <input className="input-email"
                                    type="text"
                                    placeholder="Digite seu email"
                                    onChange={event => setEmail(event.target.value)}
                                    value={email}
                                />
                                <input 
                                    type="password"
                                    placeholder="Digite sua senha"
                                    onChange={event => setPassword(event.target.value)}
                                    value={password} 
                                />

                                <div style={{ display: "flex" }}>
                                    <Button style={{ marginBottom: "12px", marginRight: "24px" }} disabled={email === '' || password === ''} type="submit">
                                        Acessar
                                    </Button>
                                    <Link style={{ marginTop: "auto", marginBottom: "auto", fontSize: '14px' }} to="/password">Esqueceu sua senha?</Link>
                                </div>

                                <div className="loading"></div>

                                <p style={{ fontSize: '14px', marginBottom: '12px', marginTop: "88px" }}>Ainda não tem seu Cartão Online Prime?</p>
                                <a href="https://api.whatsapp.com/send?phone=5517920002146" target="_blank" style={{ textDecoration: "none" }} rel="noreferrer"> <p style={{ fontSize: '14px', color: "#fec401", cursor: "pointer", width: "125px" }}>Contrate o seu aqui.</p></a>
                            </form>
                            <div><Toaster /></div>
                        </div>
                    </main>
                </div>
            }
        </div>
    )

    
}