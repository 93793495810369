import { ReactNode, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { database, firebase } from "../services/firebase";
import toast from "react-hot-toast";


type CardProps = {
    id: string;
    idCompany: string;
    name: string;
    layout: number;
    children: ReactNode;
}

export function ModalDeleteCard (props: CardProps){
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleDelete = async () => {

        //Deleta a tabela do cartão digital.
        await database.collection("cards").doc(props.id).delete().then(() => {
          console.log("Tabela do cartão foi deletada!");
        }).catch((error) => {
            console.error("Error ao remover tabela do cartão: ", error);
        });

        //Deleta as tabelas com as informações das mídias sociais.
        (await database.collection("mediaControl").where("idCard", "==", `${props.id}`).get()).forEach( async (doc) => {
                
            await database.collection("mediaControl").doc(doc.id).delete().then(() => {
                console.log("Controles de mídias sociais foram deletados!!");
            }).catch((error) => {
                console.error("Error removing control social medias: ", error);
            });
        });

        //Deleta a imagem de background da parte de tras do cartão
        await firebase.storage().ref(`${props.idCompany}/Cards/${props.id}/Background/Back`).listAll().then((listImages) => {
        
            const promises = listImages.items.map((item) => {
                return item.delete();
            });

            Promise.all(promises);
        });

        //Deleta a imagem de background da parte frontal do cartão
        await firebase.storage().ref(`${props.idCompany}/Cards/${props.id}/Background/Front`).listAll().then((listImages) => {
        
            const promises = listImages.items.map((item) => {
                return item.delete();
            });

            Promise.all(promises);
        }); 

        if(props.layout === 1){
            //Deleta a imagem de perfil caso tenha um layout 1 cadastrado 
            await firebase.storage().ref(`${props.idCompany}/Cards/${props.id}/ProfilePicture`).listAll().then((listImages) => {
            
                const promises = listImages.items.map((item) => {
                    return item.delete();
                });

                Promise.all(promises);
            });
        }
        setShow(false);
    }

    return (
        <>
        <button
            className="delete-button"
            type="button"
            aria-label="Apagar empresa"
            onClick={handleShow}
        >
            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/>
                <path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"/>
            </svg>
        </button>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Excluir Cartão Digital</Modal.Title>
            </Modal.Header>
            <Modal.Body>Tem certeza que deseja excluir o cartão: {props.name}?</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Cancelar
            </Button>
            <Button variant="danger" onClick={() => {
                toast.promise(
                    handleDelete(),
                    {
                        loading: 'Excluindo cartão...',
                        success: <b>Cartão excluído com sucesso!</b>,
                        error:   <b>Erro ao excluir o cartão.</b>,
                    }
                );
            }}>
                Excluir
            </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
}