import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Logout } from '../hooks/logout';


export function ModalLogoutUser () {
    const history = useHistory();

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const logout = () => {
        setShow(false);
        Logout();
        //window.location.reload();
        history.push('/');

        return
    }

    return (
        <>
        <button className="button-logout" onClick={handleShow}>
            Sair
        </button>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>Sair da conta</Modal.Title>
            </Modal.Header>
            <Modal.Body>Tem certeza que deseja deslogar da sua conta?</Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Cancelar
            </Button>
            <Button variant="danger" onClick={logout}>
                Sair
            </Button>
            </Modal.Footer>
        </Modal>
        </>
    );
  
}