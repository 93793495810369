import { ReactNode, useState } from "react";
import { useParams } from 'react-router-dom';
import { Button, Carousel, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { Dropzone } from "../components/Dropzone/index";

import ReactFlagsSelect from "react-flags-select";

//import schemaCard  from '../schemas/schemaCard';
//import schemaCardL2  from '../schemas/schemaCard';
//import schemaCardL3  from '../schemas/schemaCard';
//import schemaCardL4  from '../schemas/schemaCard';

import Input from "../components/Input";
//import LabelWithInfo from "../components/LabelWithInfo";

import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/modal.scss';
import '../styles/card.scss';
import { database, firebase} from "../services/firebase";
import toast from "react-hot-toast";

import layout1 from '../references/Layout1.png';
import layout2 from '../references/Layout2.png';
import layout3 from '../references/Layout3.png';
import layout4 from '../references/Layout4.png';
import layout5 from '../references/Layout5.png';
import layout6 from '../references/Layout6.png';
import layout7 from '../references/Layout7.png';
import Toggle from "react-toggle";
import { ErrorMessage, Field, Form, Formik } from "formik";

type ModalCardProps = {
    logo?: string;
    nameCompany?: string;
    idCompany?: string;
    names: string[];
    children: ReactNode;
}

export function ModalAddCard( props: ModalCardProps) {

    const [nameExists, setNameExists] = useState(false);

    const [selectedFileBackgroundF, setSelectedFileBackgroundF] = useState<File>();
    const [selectedFileBackgroundB, setSelectedFileBackgroundB] = useState<File>();
    const [selectedFileProfilePicture, setSelectedFileProfilePicture] = useState<File>();

    /** Controle de código de países **/

    const [whatsappSelected, setWhatsappSelected] = useState('');
    const [whatsappCountryCode, setWhatsappCountryCode] = useState('');

    const [phoneSelected, setPhoneSelected] = useState('');
    const [phoneCountryCode, setPhoneCountryCode] = useState('');

    const [phoneCustomSelected1, setPhoneCustomSelected1] = useState('');
    const [phoneCustomCountryCode1, setPhoneCustomCountryCode1] = useState('');
    const [phoneCustomSelected2, setPhoneCustomSelected2] = useState('');
    const [phoneCustomCountryCode2, setPhoneCustomCountryCode2] = useState('');
    const [phoneCustomSelected3, setPhoneCustomSelected3] = useState('');
    const [phoneCustomCountryCode3, setPhoneCustomCountryCode3] = useState('');
    const [phoneCustomSelected4, setPhoneCustomSelected4] = useState('');
    const [phoneCustomCountryCode4, setPhoneCustomCountryCode4] = useState('');

    //Cores dinâmicas do cartão digital
    const [arrowIconColor, setArrowIconColor] = useState('#000000');
    const [textColor, setTextColor] = useState('#000000');
    const [iconColor, setIconColor] = useState('#000000');
    const [iconColorHover, setIconColorHover] = useState('#000000');
    const [backgroundIconColor, setBackgroundIconColor] = useState('#000000');
    const [backgroundIconColorHover, setBackgroundIconColorHover] = useState('#ffffff');
    const [textIconColor, setTextIconColor] = useState('#000000');
    const [textIconColorHover, setTextIconColorHover] = useState('#ffffff');

    const [showAddLayout, setShowAddLayout] = useState(false);
    const [showAddCard, setShowAddCard] = useState(false);
    const [showAddCardColor, setShowAddCardColor] = useState(false);
    const [showControlSocial, setControlSocial] = useState(false);

    const { idCompanyClient } = useParams<{idCompanyClient?: string}>();

    const [cardName, setCardName] = useState('');
    const [layout, setLayout] = useState(1);

    // Dynamic Social Icons 
    const [nSocialIcons, setNSocialIcons] = useState(6);

    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [site, setSite] = useState('');
    const [face, setFace] = useState('');
    const [insta, setInsta] = useState('');
    const [whats, setWhats] = useState('');
    const [location, setLocation] = useState('');
    const [tour, setTour] = useState('');
    const [youtube, setYoutube] = useState('');
    const [linkedin, setLinkedin] = useState('');
    const [github, setGithub] = useState('');
    const [twitter, setTwitter] = useState('');
    const [pinterest, setPinterest] = useState('');

    //Nova atualização 13/05
    const [pix, setPix] = useState('');
    const [bank, setBank] = useState('');
    const [menu, setMenu] = useState('');
    const [schedule, setSchedule] = useState('');

    const [pixControl, setPixControl] = useState(false);
    const [bankControl, setBankControl] = useState(false);
    const [menuControl, setMenuControl] = useState(false);
    const [scheduleControl, setScheduleControl] = useState(false);

    /*****************************/

    //Nova atualização 24/06

    const [android, setAndroid] = useState('');
    const [ios, setIos] = useState('');
    const [delivery, setDelivery] = useState('');

    const [androidControl, setAndroidControl] = useState(false);
    const [iosControl, setIosControl] = useState(false);
    const [deliveryControl, setDeliveryControl] = useState(false);

    /*****************************/

    //Personalization Social Icons 
    const [typeIcon, setTypeIcon] = useState("whatsapp");
    const [contentIcon, setContentIcon] = useState('');
    const [iconControl, setIconControl] = useState(false);

    const [typeIcon1, setTypeIcon1] = useState('whatsapp');
    const [contentIcon1, setContentIcon1] = useState('');
    const [iconControl1, setIconControl1] = useState(false);

    const [typeIcon2, setTypeIcon2] = useState('whatsapp');
    const [contentIcon2, setContentIcon2] = useState('');
    const [iconControl2, setIconControl2] = useState(false);

    const [typeIcon3, setTypeIcon3] = useState('whatsapp');
    const [contentIcon3, setContentIcon3] = useState('');
    const [iconControl3, setIconControl3] = useState(false);

    //Variaveis de controle para as social media

    const[countTrueState, setCountTrueState] = useState(0);

    const [phoneControl, setPhoneControl] = useState(false);
    const [emailControl, setEmailControl] = useState(false);
    const [siteControl, setSiteControl] = useState(false);
    const [faceControl, setFaceControl] = useState(false);
    const [instaControl, setInstaControl] = useState(false);
    const [whatsControl, setWhatsControl] = useState(false);
    const [locationControl, setLocationControl] = useState(false);
    const [tourControl, setTourControl] = useState(false);
    const [youtubeControl, setYoutubeControl] = useState(false);
    const [linkedinControl, setLinkedinControl] = useState(false);
    const [githubControl, setGithubControl] = useState(false);
    const [twitterControl, setTwitterControl] = useState(false);
    const [pinterestControl, setPinterestControl] = useState(false);

    const [facePageControl, setFacePageControl] = useState(false);
    const [facebookId, setFacebookId] = useState('');

    const [standardColorControl, setStandardColorControl] = useState(false);
    const [iconTextControl, setIconTextControl] = useState(false);

    const [logoControl, setLogoControl] = useState(true);

    //Variaveis de componentes especificos de layouts
    const [description, setDescription] = useState('');
    const [personalName, setPersonalName] = useState('');
    const [occupation, setOccupation] = useState('');
    const [occupationComplement, setOccupationComplement] = useState('');

    //transação de layout para cards
    const layoutClick =  () => {
        setShowAddLayout(false);
        setShowAddCard(true);
    }

    const addInfoCardClick = () => {
        setShowAddCard(false);
        setShowAddCardColor(true);
    }

    const addCardColorClick = () => {
        setShowAddCardColor(false);
        setControlSocial(true);
    }

    const beforeInfoCardClick = () => {
        setShowAddCard(false);
        setShowAddLayout(true);

        setLayout(1);
    }

    const beforeCardColorClick = () => {
        setShowAddCard(true);
        setShowAddCardColor(false);
    }

    const beforeControlMediaClick = () => {
        setShowAddCardColor(true);
        setControlSocial(false);
    }
    
    const handleClose = () => {

        setPixControl(false);
        setBankControl(false);
        setMenuControl(false);

        setShowAddLayout(false);
        setShowAddCard(false);
        setControlSocial(false);
        setShowAddCardColor(false);

        setCountTrueState(0);
        setEmailControl(false);
        setPhoneControl(false);
        setSiteControl(false);
        setFaceControl(false);
        setFacePageControl(false);
        setInstaControl(false);
        setWhatsControl(false);
        setLocationControl(false);
        setTourControl(false);
        setYoutubeControl(false);
        setLinkedinControl(false);
        setGithubControl(false);
        setTwitterControl(false);
        setPinterestControl(false);

        setMenuControl(false);
        setBankControl(false);
        setPixControl(false);
        setScheduleControl(false);

        setAndroidControl(false);
        setIosControl(false);
        setDeliveryControl(false);

        setLogoControl(true);

        setLayout(1);
        setNSocialIcons(6);

        setCardName('');

        setArrowIconColor('#000000');
        setTextColor('#000000');
        setIconColor('#000000');
        setIconColorHover('#000000');
        setBackgroundIconColor('#000000');
        setBackgroundIconColorHover('#ffffff');
        setTextIconColor('#000000');
        setTextIconColorHover('#fffffff');

        setStandardColorControl(false);
        setIconTextControl(false);

        setPhone('');
        setEmail('');
        setSite('');
        setFace('');
        setFacebookId('');
        setInsta('');
        setWhats('');
        setLocation('');
        setTour('');
        setYoutube('');
        setLinkedin('');
        setGithub('');
        setTwitter('');
        setPinterest('');
        setDescription('');
        setPersonalName('');
        setOccupation('');
        setOccupationComplement('');

        setWhatsappSelected('');
        setWhatsappCountryCode('');
        setPhoneSelected('');
        setPhoneCountryCode('');
        setPhoneCustomSelected1('');
        setPhoneCustomCountryCode1('');
        setPhoneCustomSelected2('');
        setPhoneCustomCountryCode2('');
        setPhoneCustomSelected3('');
        setPhoneCustomCountryCode3('');
        setPhoneCustomSelected4('');
        setPhoneCustomCountryCode4('');

        setPix('');
        setBank('');
        setMenu('');
        setSchedule('');

        setAndroid('');
        setIos('');
        setDelivery('');

        setTypeIcon('whatsapp');
        setTypeIcon1('whatsapp');
        setTypeIcon2('whatsapp');
        setTypeIcon3('whatsapp');

        setContentIcon('');
        setContentIcon1('');
        setContentIcon2('');
        setContentIcon3('');

        setIconControl(false);
        setIconControl1(false);
        setIconControl2(false);
        setIconControl3(false);

        setSelectedFileBackgroundF( undefined );
        setSelectedFileBackgroundB( undefined );
        setSelectedFileProfilePicture( undefined );
    }

    const handleShow = () => setShowAddLayout(true);

    const pushCard = async () => {

        setControlSocial(false);

        let idCard = '';
        let backgroundF = '';
        let backgroundB = '';
        let profilePicture = '';

        let logo = "";

        if(props.logo === undefined){
            logo = ""
        } else {
            logo = props.logo
        }
        
        switch (layout) {
            case 0:
                console.log("Layout is undefined.");
                break;
            case 1:
                await database.collection("cards").add({
                    idCompany: idCompanyClient,
                    layout: 1,
                    cardName: cardName,
                    logo: logo,
                    active: true,
                    nSocialIcons: nSocialIcons,

                    personalName: personalName,
                    description: description,
                    occupation: occupation,
                    occupationComplement: occupationComplement,

                    created_at: new Date(),
                    updated_at: new Date()
                })
                .then((docRef) => {
                    idCard = docRef.id;
                })
                .catch((error) => {
                    toast.error("Erro ao adicionar cartão digital!");
                });

                if(selectedFileProfilePicture){
                    //Upload da foto de perfil
                    await firebase.storage().ref(`${props.idCompany}/Cards/${idCard}/ProfilePicture/${selectedFileProfilePicture?.name}`).put(selectedFileProfilePicture as File).then( async function(snapshot) {
                    
                        await snapshot.ref.getDownloadURL().then(function(downloadURL) {
                            profilePicture = downloadURL;
                        });
                    });
                }

                await database.collection('cards').doc(`${idCard}`).update({
                    photo: profilePicture,

                    updated_at: new Date()
                });
                break;

            case 2:
                await database.collection("cards").add({
                    idCompany: idCompanyClient,
                    layout: 2,
                    cardName: cardName,
                    logo: logo,
                    active: true,
                    nSocialIcons: nSocialIcons,

                    personalName: personalName,
                    description: description,
                    occupation: occupation,

                    created_at: new Date(),
                    updated_at: new Date()
                })
                .then((docRef) => {
                    idCard = docRef.id;
                })
                .catch((error) => {
                    console.log(error);
                });
            break;

            case 3:
                await database.collection("cards").add({
                    idCompany: idCompanyClient,
                    layout: 3,
                    cardName: cardName,
                    logo: logo,
                    active: true,
                    nSocialIcons: nSocialIcons,

                    created_at: new Date(),
                    updated_at: new Date()
                })
                .then((docRef) => {
                    idCard = docRef.id;
                })
                .catch((error) => {
                    console.log(error);
                });
            break;

            case 4:
                await database.collection("cards").add({
                    idCompany: idCompanyClient,
                    layout: 4,
                    cardName: cardName,
                    logo: logo,
                    active: true,
                    nSocialIcons: nSocialIcons,

                    description: description,

                    created_at: new Date(),
                    updated_at: new Date()
                })
                .then((docRef) => {
                    idCard = docRef.id;
                })
                .catch((error) => {
                    console.log(error);
                });
            break;

            case 5:
                await database.collection("cards").add({
                    idCompany: idCompanyClient,
                    layout: 5,
                    cardName: cardName,
                    logo: logo,
                    active: true,
                    nSocialIcons: nSocialIcons,

                    created_at: new Date(),
                    updated_at: new Date()
                })
                .then((docRef) => {
                    idCard = docRef.id;
                })
                .catch((error) => {
                    console.log(error);
                });
            break;

            case 6:
                await database.collection("cards").add({
                    idCompany: idCompanyClient,
                    layout: 6,
                    cardName: cardName,
                    logo: logo,
                    active: true,
                    nSocialIcons: nSocialIcons,

                    description: description,

                    created_at: new Date(),
                    updated_at: new Date()
                })
                .then((docRef) => {
                    idCard = docRef.id;
                })
                .catch((error) => {
                    console.log(error);
                });
            break;

            case 7:
                await database.collection("cards").add({
                    idCompany: idCompanyClient,
                    layout: 7,
                    cardName: cardName,
                    logo: logo,
                    active: true,
                    nSocialIcons: nSocialIcons,

                    description: description,

                    created_at: new Date(),
                    updated_at: new Date()
                })
                .then((docRef) => {
                    idCard = docRef.id;
                })
                .catch((error) => {
                    console.log(error);
                });
            break;
        }

        await database.collection("mediaControl").add({
            idCard: idCard,
            counter: countTrueState,
            phone: phoneControl,
            email: emailControl,
            site: siteControl,
            face: faceControl,
            facePage: facePageControl,
            insta: instaControl,
            whats: whatsControl,
            location: locationControl,
            tour: tourControl,
            youtube: youtubeControl,
            linkedin: linkedinControl,
            github: githubControl,
            twitter: twitterControl,
            pinterest: pinterestControl,

            pix: pixControl,
            bank: bankControl,
            menu: menuControl,
            schedule: scheduleControl,

            android: androidControl,
            ios: iosControl,
            delivery: deliveryControl,

            icon: iconControl,
            icon1: iconControl1,
            icon2: iconControl2,
            icon3: iconControl3,

            created_at: new Date(),
            updated_at: new Date()
        })
        .catch((error) => {
            console.log(error);
        });

        if(selectedFileBackgroundF){
            //Upload do background frontal no storage
            await firebase.storage().ref(`${props.idCompany}/Cards/${idCard}/Background/Front/${selectedFileBackgroundF?.name}`).put(selectedFileBackgroundF as File).then( async function(snapshot) {
        
                await snapshot.ref.getDownloadURL().then(function(downloadURL) {
                    backgroundF = downloadURL;
                });

            });
        }

        if(selectedFileBackgroundB){
            //Upload do background de trás no storage
            await firebase.storage().ref(`${props.idCompany}/Cards/${idCard}/Background/Back/${selectedFileBackgroundB?.name}`).put(selectedFileBackgroundB as File).then( async function(snapshot) {
        
                await snapshot.ref.getDownloadURL().then(function(downloadURL) {
                    backgroundB = downloadURL;
                });

            });
        }

        await database.collection('cards').doc(`${idCard}`).update({
            backgroundF: backgroundF,
            backgroundB: backgroundB,

            //Cores do cartão digital
            arrowColor: arrowIconColor,
            textColor: textColor,
            iconColor: iconColor,
            iconColorHover: iconColorHover,
            backgroundIconColor: backgroundIconColor,
            backgroundIconColorHover: backgroundIconColorHover,
            textIconColor: textIconColor,
            textIconColorHover: textIconColorHover,

            //Variáveis de controle para cor e texto
            standardColor: standardColorControl,
            iconText: iconTextControl,

            phone: phone,
            phoneCC: phoneCountryCode,

            email: email,
            location: location,
            tour: tour,
            site: site,
            face: face,
            faceId: facebookId,
            insta: insta,

            logoControl: logoControl,

            whats: whats,
            whatsCC: whatsappCountryCode,

            youtube: youtube,
            linkedin: linkedin,
            github: github,
            twitter: twitter,
            pinterest: pinterest,

            pix: pix,
            bank: bank,
            menu: menu,
            schedule: schedule,

            android: android,
            ios: ios,
            delivery: delivery,

            typeIcon: typeIcon,
            typeIcon1: typeIcon1,
            typeIcon2: typeIcon2,
            typeIcon3: typeIcon3,
            
            contentIcon: contentIcon,
            phoneCustomCC: phoneCustomCountryCode1,
            contentIcon1: contentIcon1,
            phoneCustomCC1: phoneCustomCountryCode2,
            contentIcon2: contentIcon2,
            phoneCustomCC2: phoneCustomCountryCode3,
            contentIcon3: contentIcon3,
            phoneCustomCC3: phoneCustomCountryCode4,

            updated_at: new Date()
        });

        setCountTrueState(0);
        setEmailControl(false);
        setSiteControl(false);
        setPhoneControl(false);
        setFaceControl(false);
        setFacePageControl(false);
        setInstaControl(false);
        setWhatsControl(false);
        setLocationControl(false);
        setTourControl(false);
        setYoutubeControl(false);
        setLinkedinControl(false);
        setGithubControl(false);
        setTwitterControl(false);
        setPinterestControl(false);

        setBankControl(false);
        setPixControl(false);
        setMenuControl(false);
        setScheduleControl(false);

        setAndroidControl(false);
        setIosControl(false);
        setDeliveryControl(false);

        setLogoControl(true);

        setShowAddLayout(false);
        setShowAddCard(false);
        setControlSocial(false);
        setCardName('');
        setLayout(1);
        setNSocialIcons(6);
        setPhone('');
        setEmail('');
        setSite('');
        setFace('');
        setFacebookId('');
        setInsta('');
        setWhats('');
        setLocation('');
        setTour('');
        setYoutube('');
        setLinkedin('');
        setGithub('');
        setTwitter('');
        setPinterest('');
        setDescription('');
        setPersonalName('');
        setOccupation('');
        setOccupationComplement('');

        setWhatsappSelected('');
        setWhatsappCountryCode('');
        setPhoneSelected('');
        setPhoneCountryCode('');
        setPhoneCustomSelected1('');
        setPhoneCustomCountryCode1('');
        setPhoneCustomSelected2('');
        setPhoneCustomCountryCode2('');
        setPhoneCustomSelected3('');
        setPhoneCustomCountryCode3('');
        setPhoneCustomSelected4('');
        setPhoneCustomCountryCode4('');

        setPix('');
        setBank('');
        setMenu('');
        setSchedule('');

        setAndroid('');
        setIos('');
        setDelivery('');

        setTypeIcon('whatsapp');
        setTypeIcon1('whatsapp');
        setTypeIcon2('whatsapp');
        setTypeIcon3('whatsapp');

        setContentIcon('');
        setContentIcon1('');
        setContentIcon2('');
        setContentIcon3('');

        setIconControl(false);
        setIconControl1(false);
        setIconControl2(false);
        setIconControl3(false);

        setIconTextControl(false);
        setStandardColorControl(false);

        setArrowIconColor('#000000');
        setTextColor('#000000');
        setIconColor('#000000');
        setIconColorHover('#000000');
        setBackgroundIconColor('#000000');
        setBackgroundIconColorHover('#ffffff');
        setTextIconColor('#000000');
        setTextIconColorHover('#ffffff');

        idCard = '';
        backgroundF = '';
        backgroundB = '';
        profilePicture = '';

        setSelectedFileBackgroundF( undefined );
        setSelectedFileBackgroundB( undefined );
        setSelectedFileProfilePicture( undefined );
    }

    const handleSelect = (selectedIndex: any) => {
        setLayout(selectedIndex + 1);

        if( (selectedIndex + 1) === 1) setNSocialIcons(6);
        if( (selectedIndex + 1) === 2) setNSocialIcons(6);
        if( (selectedIndex + 1) === 3) setNSocialIcons(8);
        if( (selectedIndex + 1) === 4) setNSocialIcons(5);
        if( (selectedIndex + 1) === 5) setNSocialIcons(4);
        if( (selectedIndex + 1) === 6) setNSocialIcons(6);
        if( (selectedIndex + 1) === 7) setNSocialIcons(6);
    };

    const verifyCardName = (nameCard: string) => {

        for (var i = 0; i < props.names.length; i++){
            
            if(props.names[i] === nameCard){
                setNameExists(true);
                return;
            }
        }

        setNameExists(false);
        return;
    }

    const popoverName = (
        <Popover id="popover-basic">
          <Popover.Body>
            Esse campo é utilizado para <strong>SEO</strong> do Cartão Digital. Preencha corretamente!
          </Popover.Body>
        </Popover>
    );

    const popoverPicture = (
        <Popover id="popover-basic">
          <Popover.Body>
            Utilize imagens no formato <strong>PNG</strong> com dimensão de <strong>500x500</strong> pixels em alta resolução para preservar a qualidade do Cartão Digital.<p style={{ marginTop: "8px" }}>Tamanho máximo da imagem: <strong>200 KB</strong></p>
          </Popover.Body>
        </Popover>        
    );

    const popoverBackground = (
        <Popover id="popover-basic">
          <Popover.Body>
            Utilize imagens no formato <strong>PNG</strong> com dimensão de <strong>320x550</strong> pixels em alta resolução para preservar a qualidade do Cartão Digital.<p style={{ marginTop: "8px" }}>Tamanho máximo da imagem: <strong>200 KB</strong></p>
          </Popover.Body>
        </Popover>        
    );
      
    const Name = () => (
        <OverlayTrigger trigger={["hover", "hover"]} placement="bottom" overlay={popoverName}>
            <svg style={{ marginLeft: "8px" }} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" className="svg-inline--fa fa-info-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" width="18px" viewBox="0 0 512 512">
                <path fill="#c4c4c4" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
            </svg>
        </OverlayTrigger>
    );

    const Picture = () => (
        <OverlayTrigger trigger={["hover", "hover"]} placement="bottom" overlay={popoverPicture}>
            <svg style={{ marginLeft: "8px" }} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" className="svg-inline--fa fa-info-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" width="18px" viewBox="0 0 512 512">
                <path fill="#c4c4c4" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
            </svg>
        </OverlayTrigger>
    );

    const Background = () => (
        <OverlayTrigger trigger={["hover", "hover"]} placement="bottom" overlay={popoverBackground}>
            <svg style={{ marginLeft: "8px" }} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" className="svg-inline--fa fa-info-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" width="18px" viewBox="0 0 512 512">
                <path fill="#c4c4c4" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
            </svg>
        </OverlayTrigger>
    );
  
    return (
        <>
            <button className="plus-icon" onClick={handleShow} >
            +
            </button>

            <Modal show={showAddLayout} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Escolha o Layout do Cartão.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Carousel slide={false} fade={false} interval={null} variant={'dark'} onSelect={handleSelect}>
                        <Carousel.Item>
                            <img
                            className="img-carousel"
                            src={layout1}
                            alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="img-carousel"
                            src={layout2}
                            alt="Second slide"
                            onSelect={() => setLayout(2)}
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="img-carousel"
                            src={layout3}
                            alt="Third slide"
                            onSelect={() => setLayout(3)}
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="img-carousel"
                            src={layout4}
                            alt="Fourth slide"
                            onSelect={() => setLayout(4)}
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="img-carousel"
                            src={layout5}
                            alt="Fifth slide"
                            onSelect={() => setLayout(5)}
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="img-carousel"
                            src={layout6}
                            alt="Sixth slide"
                            onSelect={() => setLayout(6)}
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                            className="img-carousel"
                            src={layout7}
                            alt="Seventh slide"
                            onSelect={() => setLayout(7)}
                            />
                        </Carousel.Item>
                    </Carousel>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
                    <Button variant="primary" onClick={layoutClick}>Próximo</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showAddCard} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Informações do Cartão</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { layout === 1 &&

                        <Formik

                            //validationSchema={schemaCard}
                            initialValues={{
                                cardName: cardName,
                                personalName: personalName,
                                occupation: occupation,
                                occupationComplement: occupationComplement,
                            }}

                            onSubmit={
                                ( values ) => {

                                    setCardName(values.cardName);
                                    setPersonalName(values.personalName);
                                    setOccupation(values.occupation);
                                    setOccupationComplement(values.occupationComplement);

                                    values.cardName = '';
                                    values.personalName = '';
                                    values.occupation = '';
                                    values.occupationComplement = '';

                                    addInfoCardClick();
                            }}
                            >
                            { ( { handleBlur } ) => (

                                <Form className="form">
                                    
                                    {/*<LabelWithInfo htmlForText="cardName" labelText="Nome do cartão: " popoverText={["Esse campo é utilizado para ", <strong>SEO</strong>,  " do Cartão Digital. Preencha corretamente!"]} />*/}
                                    <div style={{ display: "flex", alignItems: "end" }}>
                                        <label htmlFor="cardName">Nome do cartão: </label>
                                        <Name />
                                    </div>
                                    <Field onBlur={ e => { handleBlur(e); verifyCardName(e.currentTarget.value); }} id="cardName" name="cardName" type="text" placeholder="ex: Cartão Online Prime - Amauri Garrido"></Field>
                                    <ErrorMessage component="span" name="cardName"/>
                                    { nameExists && <span>Nome já cadastrado no sistema!</span> }

                                    {/*<LabelWithInfo htmlForText="fileBackgroundFrontal" labelText="Background frontal do cartão: " popoverText={["Utilize imagens no formato ", <strong>PNG</strong>,  " com tamanho de ", <strong>320x550</strong> ," pixels em alta resolução para preservar a qualidade do Cartão Digital."]} />*/}
                                    <div style={{ display: "flex", alignItems: "end" }}>
                                        <label htmlFor="fileBackgroundFrontal">Background frontal do cartão: </label>
                                        <Background />
                                    </div>
                                    <Dropzone onFileUploaded={setSelectedFileBackgroundF} text="Background frontal do cartão" />

                                    {/*<LabelWithInfo htmlForText="fileBackgroundBack" labelText="Background traseiro do cartão: " popoverText={["Utilize imagens no formato ", <strong>PNG</strong>,  " com tamanho de ", <strong>320x550</strong> ," pixels em alta resolução para preservar a qualidade do Cartão Digital."]} />*/}
                                    <div style={{ display: "flex", alignItems: "end" }}>
                                        <label htmlFor="fileBackgroundBack">Background traseiro do cartão: </label>
                                        <Background />
                                    </div>
                                    <Dropzone onFileUploaded={setSelectedFileBackgroundB} text="Background traseiro do cartão"/>

                                    {/*<LabelWithInfo htmlForText="fileProfilePicture" labelText="Foto de perfil do cartão: " popoverText={["Utilize imagens no formato ", <strong>PNG</strong>,  " com tamanho de ", <strong>500x500</strong> ," pixels em alta resolução para preservar a qualidade do Cartão Digital."]} />*/}
                                    <div style={{ display: "flex", alignItems: "end" }}>
                                        <label htmlFor="fileProfilePicture">Foto de perfil do cartão: </label>
                                        <Picture />
                                    </div>
                                    <Dropzone onFileUploaded={setSelectedFileProfilePicture} text="Foto de perfil"/>

                                    <div className="active-logo" style={{ display: 'flex', alignItems: 'center', marginTop: '24px', marginBottom: "32px" }}>
                                        <p style={{ marginRight: '20px' }} ><strong>Utilizar logo no Cartão Online Prime?</strong></p>
                                        <Toggle
                                            defaultValue={logoControl as any}
                                            defaultChecked={logoControl}
                                            onChange={(e) => {
                                                setLogoControl(e.target.checked);
                                            }}
                                        />
                                    </div>

                                    <label htmlFor="personalName">Nome da pessoa: </label>
                                    <Field id="personalName" name="personalName" type="text" placeholder="ex: Amauri Garrido"></Field>
                                    <ErrorMessage component="span" name="personalName"/>

                                    <label htmlFor="occupation">Cargo: </label>
                                    <Field id="occupation" name="occupation" type="text" placeholder="ex: Analista de E-commerce"></Field>
                                    <ErrorMessage component="span" name="occupation"/>

                                    <label htmlFor="occupationComplement">Complemento de Cargo: </label>
                                    <Field id="occupationComplement" name="occupationComplement" type="text" placeholder="ex: CRM: XXXXX-XX"></Field>
                                    <ErrorMessage component="span" name="occupationComplement"/>

                                    <div style={{ marginTop: "48px", paddingBottom: "0" }}className="modal-footer">
                                        <Button variant="secondary" onClick={beforeInfoCardClick}>Anterior</Button>
                                        <Button disabled={ (nameExists === true) } variant="primary" type="submit">Próximo</Button>
                                    </div>
                                    
                                </Form>

                            )}
                        </Formik>

                    }

                    { layout === 2 &&

                        <Formik

                            //validationSchema={schemaCardL2}
                            initialValues={{
                                cardName: cardName,
                                personalName: personalName,
                                occupation: occupation,
                                occupationComplement: occupationComplement,
                                companyDescription: description,
                            }}

                            onSubmit={
                                ( values ) => {

                                    setCardName(values.cardName);
                                    setPersonalName(values.personalName);
                                    setOccupation(values.occupation);
                                    setDescription(values.companyDescription);

                                    values.cardName = '';
                                    values.personalName = '';
                                    values.occupation = '';
                                    values.companyDescription = '';

                                    addInfoCardClick();
                            }}
                            >
                            { ( { handleBlur } ) => (

                                <Form className="form">
                                    
                                    {/*<LabelWithInfo htmlForText="cardName" labelText="Nome do cartão: " popoverText={["Esse campo é utilizado para ", <strong>SEO</strong>,  " do Cartão Digital. Preencha corretamente!"]} />*/}
                                    <div style={{ display: "flex", alignItems: "end" }}>
                                        <label htmlFor="cardName">Nome do cartão: </label>
                                        <Name />
                                    </div>
                                    <Field onBlur={ e => { handleBlur(e); verifyCardName(e.currentTarget.value); }} id="cardName" name="cardName" type="text" placeholder="ex: Cartão Online Prime - Amauri Garrido"></Field>
                                    <ErrorMessage component="span" name="cardName"/>
                                    { nameExists && <span>Nome já cadastrado no sistema!</span> }

                                    {/*<LabelWithInfo htmlForText="fileBackgroundFrontal" labelText="Background frontal do cartão: " popoverText={["Utilize imagens no formato ", <strong>PNG</strong>,  " com tamanho de ", <strong>320x550</strong> ," pixels em alta resolução para preservar a qualidade do Cartão Digital."]} />*/}
                                    <div style={{ display: "flex", alignItems: "end" }}>
                                        <label htmlFor="fileBackgroundFrontal">Background frontal do cartão: </label>
                                        <Background />
                                    </div>
                                    <Dropzone onFileUploaded={setSelectedFileBackgroundF} text="Background frontal do cartão" />

                                    {/*<LabelWithInfo htmlForText="fileBackgroundBack" labelText="Background traseiro do cartão: " popoverText={["Utilize imagens no formato ", <strong>PNG</strong>,  " com tamanho de ", <strong>320x550</strong> ," pixels em alta resolução para preservar a qualidade do Cartão Digital."]} />*/}
                                    <div style={{ display: "flex", alignItems: "end" }}>
                                        <label htmlFor="fileBackgroundBack">Background traseiro do cartão: </label>
                                        <Background />
                                    </div>
                                    <Dropzone onFileUploaded={setSelectedFileBackgroundB} text="Background traseiro do cartão"/>

                                    <div className="active-logo" style={{ display: 'flex', alignItems: 'center', marginTop: '24px', marginBottom: "32px" }}>
                                        <p style={{ marginRight: '20px' }} ><strong>Utilizar logo no Cartão Online Prime?</strong></p>
                                        <Toggle
                                            defaultValue={logoControl as any}
                                            defaultChecked={logoControl}
                                            onChange={(e) => {
                                                setLogoControl(e.target.checked);
                                            }}
                                        />
                                    </div>

                                    <label htmlFor="personalName">Nome da pessoa: </label>
                                    <Field id="personalName" name="personalName" type="text" placeholder="ex: Amauri Garrido"></Field>
                                    <ErrorMessage component="span" name="personalName"/>

                                    <label htmlFor="occupation">Cargo: </label>
                                    <Field id="occupation" name="occupation" type="text" placeholder="ex: Analista de E-commerce"></Field>
                                    <ErrorMessage component="span" name="occupation"/>

                                    <label htmlFor="companyDescription">Descrição: </label>
                                    <Field id="companyDescription" name="companyDescription" type="text" placeholder="ex: Empresa de Marketing Digital"></Field>
                                    <ErrorMessage component="span" name="companyDescription"/>

                                    <div style={{ marginTop: "48px", paddingBottom: "0" }}className="modal-footer">
                                        <Button variant="secondary" onClick={beforeInfoCardClick}>Anterior</Button>
                                        <Button disabled={ (nameExists === true) } variant="primary" type="submit">Próximo</Button>
                                    </div>
                                    
                                </Form>
                        )}
                        </Formik>
                    }

                    { (layout === 3 || layout === 5) &&

                        <Formik

                            //validationSchema={schemaCardL3}
                            initialValues={{
                                cardName: cardName,
                            }}

                            onSubmit={
                                ( values ) => {

                                    setCardName(values.cardName);

                                    values.cardName = '';
                                
                                    addInfoCardClick();
                            }}
                            >
                            { ( { handleBlur } ) => (

                                <Form className="form">
                                    
                                    {/*<LabelWithInfo htmlForText="cardName" labelText="Nome do cartão: " popoverText={["Esse campo é utilizado para ", <strong>SEO</strong>,  " do Cartão Digital. Preencha corretamente!"]} />*/}
                                    <div style={{ display: "flex", alignItems: "end" }}>
                                        <label htmlFor="cardName">Nome do cartão: </label>
                                        <Name />
                                    </div>
                                    <Field onBlur={ e => { handleBlur(e); verifyCardName(e.currentTarget.value); }} id="cardName" name="cardName" type="text" placeholder="ex: Cartão Online Prime - Amauri Garrido"></Field>
                                    <ErrorMessage component="span" name="cardName"/>
                                    { nameExists && <span>Nome já cadastrado no sistema!</span> }

                                    {/*<LabelWithInfo htmlForText="fileBackgroundFrontal" labelText="Background frontal do cartão: " popoverText={["Utilize imagens no formato ", <strong>PNG</strong>,  " com tamanho de ", <strong>320x550</strong> ," pixels em alta resolução para preservar a qualidade do Cartão Digital."]} />*/}
                                    <div style={{ display: "flex", alignItems: "end" }}>
                                        <label htmlFor="fileBackgroundFrontal">Background frontal do cartão: </label>
                                        <Background />
                                    </div>
                                    <Dropzone onFileUploaded={setSelectedFileBackgroundF} text="Background frontal do cartão" />

                                    {/*<LabelWithInfo htmlForText="fileBackgroundBack" labelText="Background traseiro do cartão: " popoverText={["Utilize imagens no formato ", <strong>PNG</strong>,  " com tamanho de ", <strong>320x550</strong> ," pixels em alta resolução para preservar a qualidade do Cartão Digital."]} />*/}
                                    <div style={{ display: "flex", alignItems: "end" }}>
                                        <label htmlFor="fileBackgroundBack">Background traseiro do cartão: </label>
                                        <Background />
                                    </div>
                                    <Dropzone onFileUploaded={setSelectedFileBackgroundB} text="Background traseiro do cartão"/>

                                    <div className="active-logo" style={{ display: 'flex', alignItems: 'center', marginTop: '24px', marginBottom: "32px" }}>
                                        <p style={{ marginRight: '20px' }} ><strong>Utilizar logo no Cartão Online Prime?</strong></p>
                                        <Toggle
                                            defaultValue={logoControl as any}
                                            defaultChecked={logoControl}
                                            onChange={(e) => {
                                                setLogoControl(e.target.checked);
                                            }}
                                        />
                                    </div>

                                    <div style={{ marginTop: "48px", paddingBottom: "0" }}className="modal-footer">
                                        <Button variant="secondary" onClick={beforeInfoCardClick}>Anterior</Button>
                                        <Button disabled={ (nameExists === true) } variant="primary" type="submit">Próximo</Button>
                                    </div>
                                    
                                </Form>
                            )}
                        </Formik>
                    }

                    { (layout === 4 || layout === 6 || layout === 7)  &&
                        
                        <Formik

                            //validationSchema={schemaCardL4}
                            initialValues={{
                                cardName: cardName,
                                companyDescription: description,
                            }}

                            onSubmit={
                                ( values ) => {

                                    setCardName(values.cardName);
                                    setDescription(values.companyDescription);

                                    values.cardName = '';
                                    values.companyDescription = '';
                                
                                    addInfoCardClick();
                            }}
                            >
                            { ( { handleBlur } ) => (

                                <Form className="form">
                                    
                                    {/*<LabelWithInfo htmlForText="cardName" labelText="Nome do cartão: " popoverText={["Esse campo é utilizado para ", <strong>SEO</strong>,  " do Cartão Digital. Preencha corretamente!"]} />*/}
                                    <div style={{ display: "flex", alignItems: "end" }}>
                                        <label htmlFor="cardName">Nome do cartão: </label>
                                        <Name />
                                    </div>
                                    <Field onBlur={ e => { handleBlur(e); verifyCardName(e.currentTarget.value); }} id="cardName" name="cardName" type="text" placeholder="ex: Cartão Online Prime - Amauri Garrido"></Field>
                                    <ErrorMessage component="span" name="cardName"/>
                                    { nameExists && <span>Nome já cadastrado no sistema!</span> }

                                    {/*<LabelWithInfo htmlForText="fileBackgroundFrontal" labelText="Background frontal do cartão: " popoverText={["Utilize imagens no formato ", <strong>PNG</strong>,  " com tamanho de ", <strong>320x550</strong> ," pixels em alta resolução para preservar a qualidade do Cartão Digital."]} />*/}
                                    <div style={{ display: "flex", alignItems: "end" }}>
                                        <label htmlFor="fileBackgroundFrontal">Background frontal do cartão: </label>
                                        <Background />
                                    </div>
                                    <Dropzone onFileUploaded={setSelectedFileBackgroundF} text="Background frontal do cartão" />

                                    {/*<LabelWithInfo htmlForText="fileBackgroundBack" labelText="Background traseiro do cartão: " popoverText={["Utilize imagens no formato ", <strong>PNG</strong>,  " com tamanho de ", <strong>320x550</strong> ," pixels em alta resolução para preservar a qualidade do Cartão Digital."]} />*/}
                                    <div style={{ display: "flex", alignItems: "end" }}>
                                        <label htmlFor="fileBackgroundBack">Background traseiro do cartão: </label>
                                        <Background />
                                    </div>
                                    <Dropzone onFileUploaded={setSelectedFileBackgroundB} text="Background traseiro do cartão"/>

                                    <div className="active-logo" style={{ display: 'flex', alignItems: 'center', marginTop: '24px', marginBottom: "32px" }}>
                                        <p style={{ marginRight: '20px' }} ><strong>Utilizar logo no Cartão Online Prime?</strong></p>
                                        <Toggle
                                            defaultValue={logoControl as any}
                                            defaultChecked={logoControl}
                                            onChange={(e) => {
                                                setLogoControl(e.target.checked);
                                            }}
                                        />
                                    </div>

                                    <label htmlFor="companyDescription">Descrição: </label>
                                    <Field id="companyDescription" name="companyDescription" type="text" placeholder="ex: Empresa de Marketing Digital"></Field>
                                    <ErrorMessage component="span" name="companyDescription"/>

                                    <div style={{ marginTop: "48px", paddingBottom: "0" }}className="modal-footer">
                                        <Button variant="secondary" onClick={beforeInfoCardClick}>Anterior</Button>
                                        <Button disabled={ (nameExists === true) } variant="primary" type="submit">Próximo</Button>
                                    </div>
                                    
                                </Form>
                            )}
                        </Formik>
                    }
                </Modal.Body>

            </Modal>

            <Modal show={showAddCardColor} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Cores do Cartão</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                        <div className="standard-color" style={{ display: 'flex', alignItems: 'center' }}>
                            <p style={{ marginRight: '10px',  marginLeft: '20px' }} ><strong>Utilizar cor original dos ícones?</strong></p>
                            <Toggle
                                defaultValue={standardColorControl as any}
                                defaultChecked={standardColorControl}
                                onChange={(e) => {
                                    setStandardColorControl(e.target.checked);
                                }}
                            />
                        </div>

                        <div className="text-icon" style={{ display: 'flex', alignItems: 'center', marginTop: '15px', marginBottom: '15px' }}>
                            <p style={{ marginRight: '10px',  marginLeft: '20px' }} ><strong>Utilizar textos nos ícones?</strong></p>
                            <Toggle
                                defaultValue={iconTextControl as any}
                                defaultChecked={iconTextControl}
                                onChange={(e) => {
                                    setIconTextControl(e.target.checked);
                                }}
                            />
                        </div>

                        <form className="form-color-card">

                            <div style={{ display: 'flex' }}>

                                <label style={{ marginRight: "10px", marginTop: "0"}} htmlFor="arrowIconColor">Seta superior: </label>
                                <input 
                                    type="color"
                                    id="arrowIconColor"
                                    placeholder="Digite a cor da seta"
                                    onChange={event => setArrowIconColor(event.target.value)}
                                    value={arrowIconColor} 
                                />

                            </div>

                            <div style={{ display: 'flex', marginTop: "10px" }}>

                                <label style={{ marginRight: "10px", marginTop: "0"}} htmlFor="textColor">Textos: </label>
                                <input 
                                    type="color"
                                    id="textColor"
                                    placeholder="Digite a cor dos textos"
                                    onChange={event => setTextColor(event.target.value)}
                                    value={textColor} 
                                />

                            </div>

                            <div style={{ display: 'flex', marginTop: "10px" }}>

                                <label style={{ marginRight: "10px", marginTop: "0" }} htmlFor="iconColor">Cor dos ícones: </label>
                                <input
                                    type="color"
                                    id="iconColor"
                                    placeholder="Digite a cor dos ícones"
                                    onChange={event => setIconColor(event.target.value)}
                                    value={iconColor}
                                />

                            </div>

                            <div style={{ display: 'flex', marginTop: "10px" }}>

                                <label style={{ marginRight: "10px", marginTop: "0" }} htmlFor="backgroundIconColor">Fundo dos ícones: </label>
                                <input
                                    type="color"
                                    id="backgroundIconColor"
                                    placeholder="Digite a cor de fundo dos ícones"
                                    onChange={event => setBackgroundIconColor(event.target.value)}
                                    value={backgroundIconColor}
                                />

                            </div>

                            <div style={{ display: 'flex', marginTop: "10px" }}>

                                <label style={{ marginRight: "10px", marginTop: "0" }} htmlFor="textIconColor">Texto dos ícones: </label>
                                <input
                                    type="color"
                                    id="textIconColor"
                                    placeholder="Digite a cor do texto dos ícones"
                                    onChange={event => setTextIconColor(event.target.value)}
                                    value={textIconColor}
                                />

                            </div>

                            <p style={{ fontSize: '18px', marginTop: '20px', paddingBottom: "10px", borderBottom: "1px solid #dee2e6" }}><strong>Cor passando o mouse: </strong></p>

                            <div style={{ display: 'flex', marginTop: "10px" }}>

                                <label style={{ marginRight: "10px", marginTop: "0" }} htmlFor="backgroundIconColorHover">Fundo dos ícones: </label>
                                <input
                                    type="color"
                                    id="backgroundIconColorHover"
                                    placeholder="Digite a cor de fundo dos ícones"
                                    onChange={event => setBackgroundIconColorHover(event.target.value)}
                                    value={backgroundIconColorHover}
                                />

                            </div>

                            <div style={{ display: 'flex', marginTop: "10px" }}>

                                <label style={{ marginRight: "10px", marginTop: "0" }} htmlFor="iconColorHover">Cor dos ícones: </label>
                                <input
                                    type="color"
                                    id="iconColorHover"
                                    placeholder="Cor dos ícones"
                                    onChange={event => setIconColorHover(event.target.value)}
                                    value={iconColorHover}
                                />

                            </div>

                            <div style={{ display: 'flex', marginTop: "10px" }}>

                                <label style={{ marginRight: "10px", marginTop: "0" }} htmlFor="textIconColor">Texto dos ícones: </label>
                                <input
                                    type="color"
                                    id="textIconColor"
                                    placeholder="Digite a cor do texto dos ícones"
                                    onChange={event => setTextIconColorHover(event.target.value)}
                                    value={textIconColorHover}
                                />

                            </div>

                            <div style={{ marginTop: "16px", paddingBottom: "0" }}className="modal-footer">
                                <Button variant="secondary" onClick={beforeCardColorClick}>Anterior</Button>
                                <Button variant="primary" onClick={addCardColorClick}>Próximo</Button>
                            </div>

                        </form>

                </Modal.Body>

            </Modal>

            <Modal className="modal-social-media" show={showControlSocial} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Controle de mídias sociais.</Modal.Title>
                    <p className="quantity-social-medias">Max. {nSocialIcons}</p>
                </Modal.Header>
                <Modal.Body>

                    <form className="form-control-media" onSubmit={pushCard}>

                        <label htmlFor="iWhatsApp">WhatsApp: </label>
                        <div style={{ display: "flex", alignItems: "center" }}>

                            <ReactFlagsSelect

                                selected={whatsappSelected}

                                onSelect={(code) => {

                                    setWhatsappSelected(code);

                                    if(code === "BR")
                                        setWhatsappCountryCode("55");
                                    if(code === "PY")
                                        setWhatsappCountryCode("595");
                                    if(code === "AR")
                                        setWhatsappCountryCode("54");
                                }}

                                placeholder="País"
                                countries={["BR", "PY", "AR"]}
                                customLabels={{
                                    "BR": { primary: "+55" },
                                    "PY": { primary: "+595" },
                                    "AR": { primary: "+54" }
                                }}

                                optionsSize={16}
                                selectedSize={16}
                            />
                            <Input 
                                id="iWhatsApp"
                                type="text"
                                mask="phone"
                                country={whatsappSelected as any}
                                placeholder="Número do WhatsApp"
                                onInputChanged={setWhats}

                                onChange={event => setWhats(event.target.value)}
                                value={whats}
                            />
                            <Toggle
                                defaultValue={whatsControl as any}
                                defaultChecked={whatsControl}
                                disabled={ ( countTrueState === nSocialIcons) && (whatsControl !== true)}
                                onChange={(e) => {
                                    setWhatsControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1)
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1)
                                }}
                            />

                        </div>
                        
                        <label htmlFor="iPhone">Telefone:</label>
                        <div style={{ display: "flex", alignItems: "center" }}>

                            <ReactFlagsSelect

                                selected={phoneSelected}

                                onSelect={(code) => {

                                    setPhoneSelected(code);

                                    if(code === "BR")
                                        setPhoneCountryCode("55");
                                    if(code === "PY")
                                        setPhoneCountryCode("595");
                                    if(code === "AR")
                                        setPhoneCountryCode("54");
                                }}

                                placeholder="País"
                                countries={["BR", "PY", "AR"]}
                                customLabels={{
                                    "BR": { primary: "+55" },
                                    "PY": { primary: "+595" },
                                    "AR": { primary: "+54" }
                                }}

                                optionsSize={16}
                                selectedSize={16}
                            />
                            <Input 
                                mask="phone"
                                country={phoneSelected as any}
                                type="text"
                                id="iPhone"
                                placeholder="Digite o Telefone"
                                onInputChanged={setPhone}

                                onChange={event => setPhone(event.target.value)}
                                value={phone}
                            />
                            <Toggle
                                defaultValue={phoneControl as any}
                                defaultChecked={phoneControl}
                                disabled={( countTrueState === nSocialIcons) && (phoneControl !== true)}
                                onChange={(e) => {
                                    setPhoneControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />

                        </div>

                        <label htmlFor="iEmail">E-mail: </label>
                        <div style={{ display: "flex" }}>

                            <input
                                type="email"
                                id="iEmail"
                                placeholder="ex: atendimento@gowebby.com.br"
                                onChange={event => setEmail(event.target.value)}
                                value={email}
                            />
                            <Toggle
                                defaultValue={emailControl as any}
                                defaultChecked={emailControl}
                                disabled={( countTrueState === nSocialIcons) && (emailControl !== true)}
                                onChange={(e) => {
                                    setEmailControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />

                        </div>

                        <label htmlFor="iSite">Site: </label>
                        <div style={{ display: "flex" }}>

                            <input
                                type="text"
                                id="iSite"
                                placeholder="Use (https ou http) ex: https://gwcard.com.br"
                                onChange={event => setSite(event.target.value)}
                                value={site}
                            />
                            <Toggle
                                defaultValue={siteControl as any}
                                defaultChecked={siteControl}
                                disabled={( countTrueState === nSocialIcons) && (siteControl !== true)}
                                onChange={
                                    (e) => {setSiteControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />
                        
                        </div>

                        <label htmlFor="iInstagram">Instagram: </label>
                        <div style={{ display: "flex" }}>

                            <input
                                type="text"
                                id="iInstagram"
                                placeholder="Use (https ou http) ex: https://instagram.com"
                                onChange={event => setInsta(event.target.value)}
                                value={insta}
                            />
                            <Toggle
                                defaultValue={instaControl as any}
                                defaultChecked={instaControl}
                                disabled={( countTrueState === nSocialIcons) && (instaControl !== true)}
                                onChange={
                                    (e) => {setInstaControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />

                        </div>

                        <label htmlFor="iFacebook">Facebook: </label>
                        <div style={{ display: "flex" }}>
                    
                            <input
                                type="text"
                                id="iFacebook"
                                placeholder="Use (https ou http) ex: https://facebook.com"
                                onChange={event => setFace(event.target.value)}
                                value={face}
                            />
                            <Toggle
                                defaultValue={faceControl as any}
                                defaultChecked={faceControl}
                                disabled={( countTrueState === nSocialIcons) && (faceControl !== true)}
                                onChange={
                                    (e) => {setFaceControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />

                        </div>

                        { faceControl &&
                            <div className="div-admin">
                                <label className="label-admin" htmlFor="fadmin">É uma página de empresa?</label>
                
                                <Toggle
                                    className="isCompany"
                                    defaultChecked={facePageControl}
                                    defaultValue={facePageControl as any}
                                    onChange={(e) => {setFacePageControl(e.target.checked)}}
                                />
                            </div> 
                        }

                        { (facePageControl && faceControl) &&

                            <div className="face-page-control">
                                <label htmlFor="iFacebookId">ID do Facebook para Página de Empresa: </label>
                                <input
                                    type="text"
                                    id="iFacebookId"
                                    placeholder="Digite o ID do Facebook"
                                    onChange={event => setFacebookId(event.target.value)}
                                    value={facebookId}
                                />
                            </div>

                        }

                        { (faceControl && facePageControl === false ) &&

                            <div className="face-page-control">
                            <label htmlFor="iLocation">ID do Facebook para Perfil Pessoal: </label>
                            <input
                                type="text"
                                id="iLocation"
                                placeholder="Digite o ID do Facebook"
                                onChange={event => setFacebookId(event.target.value)}
                                value={facebookId}
                            />
                            </div>

                        }

                        <label htmlFor="iLocation">Localização: </label>
                        <div style={{ display: "flex" }}>

                            <input
                                type="text"
                                id="iLocation"
                                placeholder="Use (https ou http) ex: https://goo.gl/maps"
                                onChange={event => setLocation(event.target.value)}
                                value={location}
                            />
                            <Toggle
                                defaultValue={locationControl as any}
                                defaultChecked={locationControl}
                                disabled={( countTrueState === nSocialIcons) && (locationControl !== true)}
                                onChange={
                                    (e) => {setLocationControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />

                        </div>

                        <label htmlFor="tour360">Tour 360º: </label>
                        <div style={{ display: "flex" }}>

                            <input
                                type="text"
                                id="tour360"
                                placeholder="Use (https ou http) ex: https://gwcard.com.br"
                                onChange={event => setTour(event.target.value)}
                                value={tour}
                            />
                            <Toggle
                                defaultValue={tourControl as any}
                                defaultChecked={tourControl}
                                disabled={( countTrueState === nSocialIcons) && (tourControl !== true)}
                                onChange={
                                    (e) => {setTourControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />

                        </div>

                        <label htmlFor="iYoutube">Youtube: </label>
                        <div style={{ display: "flex" }}>
                            
                            <input
                                type="text"
                                id="iYoutube"
                                placeholder="Use (https ou http) ex: https://youtube.com"
                                onChange={event => setYoutube(event.target.value)}
                                value={youtube}
                            />
                            <Toggle
                                defaultValue={youtubeControl as any}
                                defaultChecked={youtubeControl}
                                disabled={( countTrueState === nSocialIcons) && (youtubeControl !== true)}
                                onChange={
                                    (e) => {setYoutubeControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />

                        </div>

                        <label htmlFor="iLinkedin">Linkedin: </label>
                        <div style={{ display: "flex" }}>

                            <input
                                type="text"
                                id="iLinkedin"
                                placeholder="Use (https ou http) ex: https://linkedin.com"
                                onChange={event => setLinkedin(event.target.value)}
                                value={linkedin}
                            />
                            <Toggle
                                defaultValue={linkedinControl as any}
                                defaultChecked={linkedinControl}
                                disabled={( countTrueState === nSocialIcons) && (linkedinControl !== true)}
                                onChange={
                                    (e) => {setLinkedinControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />

                        </div>

                        <label htmlFor="iGithub">GitHub: </label>
                        <div style={{ display: "flex" }}>

                            <input
                                type="text"
                                id="iGithub"
                                placeholder="Use (https ou http) ex: https://github.com"
                                onChange={event => setGithub(event.target.value)}
                                value={github}
                            />
                            <Toggle
                                defaultValue={githubControl as any}
                                defaultChecked={githubControl}
                                disabled={( countTrueState === nSocialIcons) && (githubControl !== true)}
                                onChange={
                                    (e) => {setGithubControl(e.target.checked)
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1)
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1)
                                }}
                            />

                        </div>

                        <label htmlFor="iTwitter">Twitter: </label>
                        <div style={{ display: "flex" }}>

                            <input
                                type="text"
                                id="iTwitter"
                                placeholder="Use (https ou http) ex: https://twitter.com"
                                onChange={event => setTwitter(event.target.value)}
                                value={twitter}
                            />
                            <Toggle
                                defaultValue={twitterControl as any}
                                defaultChecked={twitterControl}
                                disabled={( countTrueState === nSocialIcons) && (twitterControl !== true)}
                                onChange={
                                    (e) => {setTwitterControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />

                        </div>

                        <label htmlFor="iPinterest">Pinterest: </label>
                        <div style={{ display: "flex" }}>

                            <input
                                type="text"
                                id="iPinterest"
                                placeholder="Use (https ou http) ex: https://pinterest.com"
                                onChange={event => setPinterest(event.target.value)}
                                value={pinterest}
                            />
                            <Toggle
                                defaultValue={pinterestControl as any}
                                defaultChecked={pinterestControl}
                                disabled={( countTrueState === nSocialIcons) && (pinterestControl !== true)}
                                onChange={
                                    (e) => {setPinterestControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />
                        </div>

                        <label htmlFor="iPix">Pix: </label>
                        <div style={{ display: "flex" }}>

                            <input
                                type="text"
                                id="iPix"
                                placeholder="Use (https ou http) ex: https://banco.com.br"
                                onChange={event => setPix(event.target.value)}
                                value={pix}
                            />
                            <Toggle
                                defaultValue={pixControl as any}
                                defaultChecked={pixControl}
                                disabled={( countTrueState === nSocialIcons) && (pixControl !== true)}
                                onChange={
                                    (e) => {setPixControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />

                        </div>

                        <label htmlFor="iBank">Banco: </label>
                        <div style={{ display: "flex" }}>

                            <input
                                type="text"
                                id="iBank"
                                placeholder="Use (https ou http) ex: https://sharedLink.com.br"
                                onChange={event => setBank(event.target.value)}
                                value={bank}
                            />
                            <Toggle
                                defaultValue={bankControl as any}
                                defaultChecked={bankControl}
                                disabled={( countTrueState === nSocialIcons) && (bankControl !== true)}
                                onChange={
                                    (e) => {setBankControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />

                        </div>

                        <label htmlFor="iMenu">Cardápio: </label>
                        <div style={{ display: "flex" }}>

                            <input
                                type="text"
                                id="iMenu"
                                placeholder="Use (https ou http) ex: https://sharedLink.com.br"
                                onChange={event => setMenu(event.target.value)}
                                value={menu}
                            />
                            <Toggle
                                defaultValue={menuControl as any}
                                defaultChecked={menuControl}
                                disabled={( countTrueState === nSocialIcons) && (menuControl !== true)}
                                onChange={
                                    (e) => {setMenuControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />

                        </div>

                        <label htmlFor="iSchedule">Agendamento: </label>
                        <div style={{ display: "flex" }}>

                            <input
                                type="text"
                                id="iSchedule"
                                placeholder="Use (https ou http) ex: https://sharedLink.com.br"
                                onChange={event => setSchedule(event.target.value)}
                                value={schedule}
                            />
                            <Toggle
                                defaultValue={scheduleControl as any}
                                defaultChecked={scheduleControl}
                                disabled={( countTrueState === nSocialIcons) && (scheduleControl !== true)}
                                onChange={
                                    (e) => {setScheduleControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />

                        </div>

                        <label htmlFor="iPlayStore">Play Store: </label>
                        <div style={{ display: "flex" }}>

                            <input
                                type="text"
                                id="iPlayStore"
                                placeholder="Use (https ou http) ex: https://playstore.com.br"
                                onChange={event => setAndroid(event.target.value)}
                                value={android}
                            />
                            <Toggle
                                defaultValue={androidControl as any}
                                defaultChecked={androidControl}
                                disabled={( countTrueState === nSocialIcons) && (androidControl !== true)}
                                onChange={
                                    (e) => {setAndroidControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />

                        </div>

                        <label htmlFor="iAppleStore">Apple Store: </label>
                        <div style={{ display: "flex" }}>

                            <input
                                type="text"
                                id="iAppleStore"
                                placeholder="Use (https ou http) ex: https://applestore.com.br"
                                onChange={event => setIos(event.target.value)}
                                value={ios}
                            />
                            <Toggle
                                defaultValue={iosControl as any}
                                defaultChecked={iosControl}
                                disabled={( countTrueState === nSocialIcons) && (iosControl !== true)}
                                onChange={
                                    (e) => {setIosControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />

                        </div>

                        <label htmlFor="iDelivery">Delivery: </label>
                        <div style={{ display: "flex" }}>

                            <input
                                type="text"
                                id="iDelivery"
                                placeholder="Use (https ou http) ex: https://delivery.com.br"
                                onChange={event => setDelivery(event.target.value)}
                                value={delivery}
                            />
                            <Toggle
                                defaultValue={deliveryControl as any}
                                defaultChecked={deliveryControl}
                                disabled={( countTrueState === nSocialIcons) && (deliveryControl !== true)}
                                onChange={
                                    (e) => {setDeliveryControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />

                        </div>

                        <h5 style={{ marginTop: "32px", paddingBottom: "10px", borderBottom: "1px solid #dee2e6" }}><strong>Opções Personalizadas</strong></h5>

                        
                        <span style={{ color: "black", fontSize: "18px", marginRight: "15px" }} ><strong>#1</strong></span>
                        <select name="typeIcon" id="type-icon" style={{ width: "145px" }} onChange={event => setTypeIcon(event.target.value)} value={typeIcon}>
                            <option value="whatsapp">WhatsApp</option>
                            <option value="telefone">Telefone</option>
                            <option value="email">E-mail</option>
                            <option value="site">Site</option>
                            <option value="siteTour">Site Tour</option>
                            <option value="instagram">Intagram</option>
                            <option value="localização">Localização</option>
                            <option value="youtube">Youtube</option>
                            <option value="linkedin">Linkedin</option>
                            <option value="github">GitHub</option>
                            <option value="twitter">Twitter</option>
                            <option value="pinterest">Pinterest</option>
                            <option value="pix">Pix</option>
                            <option value="bank">Banco</option>
                            <option value="menu">Cardápio</option>
                            <option value="schedule">Agendamento</option>
                            <option value="android">Play Store</option>
                            <option value="ios">Apple Store</option>
                            <option value="delivery">Delivery</option>
                            <option value="tiktok">TikTok</option>
                            <option value="360">Tour 360</option>
                            <option value="ifood">Ifood</option>
                            <option value="waze">Waze</option>
                            <option value="salonDigital">Salon Digital</option>
                            <option value="guiaFacil">Guia Fácil</option>
                        </select>

                        { typeIcon === 'whatsapp' || typeIcon === 'telefone' ? 

                            <div style={{ display: "flex", marginTop: "10px", alignItems: "center" }}>
                                
                                <ReactFlagsSelect

                                    selected={phoneCustomSelected1}

                                    onSelect={(code) => {

                                        setPhoneCustomSelected1(code);

                                        if(code === "BR")
                                            setPhoneCustomCountryCode1("55");
                                        if(code === "PY")
                                            setPhoneCustomCountryCode1("595");
                                        if(code === "AR")
                                            setPhoneCustomCountryCode1("54");
                                    }}

                                    placeholder="País"
                                    countries={["BR", "PY", "AR"]}
                                    customLabels={{
                                        "BR": { primary: "+55" },
                                        "PY": { primary: "+595" },
                                        "AR": { primary: "+54" }
                                    }}

                                    optionsSize={16}
                                    selectedSize={16}
                                />
                                <Input 
                                    id="customInput1"
                                    type="text"
                                    mask="phone"
                                    country={phoneCustomSelected1 as any}
                                    onInputChanged={setContentIcon}
                                />

                                <Toggle
                                    defaultValue={iconControl as any}
                                    defaultChecked={iconControl}
                                    disabled={ ( countTrueState === nSocialIcons) && (iconControl !== true)}
                                    onChange={(e) => {
                                        setIconControl(e.target.checked);
                                        if(e.target.checked === true) setCountTrueState(countTrueState + 1)
                                        if(e.target.checked === false) setCountTrueState(countTrueState - 1)
                                    }}
                                />

                            </div>

                            :

                            <div style={{ display: "flex", marginTop: "10px" }}>
                                <input
                                    type="text"
                                    id="customInput1"
                                    placeholder="Use (https ou http) ex: https://gwcard.com.br"
                                    onChange={event => setContentIcon(event.target.value)}
                                    value={contentIcon}
                                />
                                <Toggle
                                    defaultValue={iconControl as any}
                                    defaultChecked={iconControl}
                                    disabled={( countTrueState === nSocialIcons) && (iconControl !== true)}
                                    onChange={
                                        (e) => {setIconControl(e.target.checked);
                                        if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                        if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                    }}
                                />
                                    
                            </div>
    
                        }


                        <div style={{ borderTop: "1px solid #dee2e6", marginTop: "10px", paddingTop: "8px" }} className="personalization-input">
                            <span style={{ color: "black", fontSize: "18px", marginRight: "15px" }} ><strong>#2</strong></span>
                            <select name="typeIcon" id="type-icon" style={{ width: "145px" }} onChange={event => setTypeIcon1(event.target.value)} value={typeIcon1}>
                                <option value="whatsapp">WhatsApp</option>
                                <option value="telefone">Telefone</option>
                                <option value="email">E-mail</option>
                                <option value="site">Site</option>
                                <option value="siteTour">Site Tour</option>
                                <option value="instagram">Intagram</option>
                                <option value="localização">Localização</option>
                                <option value="youtube">Youtube</option>
                                <option value="linkedin">Linkedin</option>
                                <option value="github">GitHub</option>
                                <option value="twitter">Twitter</option>
                                <option value="pinterest">Pinterest</option>
                                <option value="pix">Pix</option>
                                <option value="bank">Banco</option>
                                <option value="menu">Cardápio</option>
                                <option value="schedule">Agendamento</option>
                                <option value="android">Play Store</option>
                                <option value="ios">Apple Store</option>
                                <option value="delivery">Delivery</option>
                                <option value="tiktok">TikTok</option>
                                <option value="360">Tour 360</option>
                                <option value="ifood">Ifood</option>
                                <option value="waze">Waze</option>
                                <option value="salonDigital">Salon Digital</option>
                                <option value="guiaFacil">Guia Fácil</option>
                            </select>

                            { typeIcon1 === 'whatsapp' || typeIcon1 === 'telefone' ? 

                                <div style={{ display: "flex", marginTop: "10px", alignItems: "center" }}>

                                    <ReactFlagsSelect

                                        selected={phoneCustomSelected2}

                                        onSelect={(code) => {

                                            setPhoneCustomSelected2(code);

                                            if(code === "BR")
                                                setPhoneCustomCountryCode2("55");
                                            if(code === "PY")
                                                setPhoneCustomCountryCode2("595");
                                            if(code === "AR")
                                                setPhoneCustomCountryCode2("54");
                                        }}

                                        placeholder="País"
                                        countries={["BR", "PY", "AR"]}
                                        customLabels={{
                                            "BR": { primary: "+55" },
                                            "PY": { primary: "+595" },
                                            "AR": { primary: "+54" }
                                        }}

                                        optionsSize={16}
                                        selectedSize={16}
                                    />
                                    <Input 
                                        id="customInput2"
                                        type="text"
                                        mask="phone"
                                        country={phoneCustomSelected2 as any}
                                        onInputChanged={setContentIcon1}
                                    />

                                    <Toggle
                                        defaultValue={iconControl1 as any}
                                        defaultChecked={iconControl1}
                                        disabled={ ( countTrueState === nSocialIcons) && (iconControl1 !== true)}
                                        onChange={(e) => {
                                            setIconControl1(e.target.checked);
                                            if(e.target.checked === true) setCountTrueState(countTrueState + 1)
                                            if(e.target.checked === false) setCountTrueState(countTrueState - 1)
                                        }}
                                    />

                                </div>

                                :

                                <div style={{ display: "flex", marginTop: "10px" }}>
                                    <input
                                        type="text"
                                        id="customInput2"
                                        placeholder="Use (https ou http) ex: https://gwcard.com.br"
                                        onChange={event => setContentIcon1(event.target.value)}
                                        value={contentIcon1}
                                    />
                                    <Toggle
                                        defaultValue={iconControl1 as any}
                                        defaultChecked={iconControl1}
                                        disabled={ ( countTrueState === nSocialIcons) && (iconControl1 !== true) }
                                        onChange={
                                            (e) => {setIconControl1(e.target.checked);
                                            if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                            if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                        }}
                                    />
                                        
                                </div>
                            }
                        </div>

                        <div style={{ borderTop: "1px solid #dee2e6", marginTop: "10px", paddingTop: "8px" }} className="personalization-input">
                            <span style={{ color: "black", fontSize: "18px", marginRight: "15px" }} ><strong>#3</strong></span>
                            <select name="typeIcon" id="type-icon" style={{ width: "145px" }} onChange={event => setTypeIcon2(event.target.value)} value={typeIcon2}>
                                <option value="whatsapp">WhatsApp</option>
                                <option value="telefone">Telefone</option>
                                <option value="email">E-mail</option>
                                <option value="site">Site</option>
                                <option value="siteTour">Site Tour</option>
                                <option value="instagram">Intagram</option>
                                <option value="localização">Localização</option>
                                <option value="youtube">Youtube</option>
                                <option value="linkedin">Linkedin</option>
                                <option value="github">GitHub</option>
                                <option value="twitter">Twitter</option>
                                <option value="pinterest">Pinterest</option>
                                <option value="pix">Pix</option>
                                <option value="bank">Banco</option>
                                <option value="menu">Cardápio</option>
                                <option value="schedule">Agendamento</option>
                                <option value="android">Play Store</option>
                                <option value="ios">Apple Store</option>
                                <option value="delivery">Delivery</option>
                                <option value="tiktok">TikTok</option>
                                <option value="360">Tour 360</option>
                                <option value="ifood">Ifood</option>
                                <option value="waze">Waze</option>
                                <option value="salonDigital">Salon Digital</option>
                                <option value="guiaFacil">Guia Fácil</option>
                            </select>

                            { typeIcon2 === 'whatsapp' || typeIcon2 === 'telefone' ? 

                                <div style={{ display: "flex", marginTop: "10px", alignItems: "center" }}>

                                    <ReactFlagsSelect

                                        selected={phoneCustomSelected3}

                                        onSelect={(code) => {

                                            setPhoneCustomSelected3(code);

                                            if(code === "BR")
                                                setPhoneCustomCountryCode3("55");
                                            if(code === "PY")
                                                setPhoneCustomCountryCode3("595");
                                            if(code === "AR")
                                                setPhoneCustomCountryCode3("54");
                                        }}

                                        placeholder="País"
                                        countries={["BR", "PY", "AR"]}
                                        customLabels={{
                                            "BR": { primary: "+55" },
                                            "PY": { primary: "+595" },
                                            "AR": { primary: "+54" }
                                        }}

                                        optionsSize={16}
                                        selectedSize={16}
                                    />
                                    <Input 
                                        id="customInput3"
                                        type="text"
                                        mask="phone"
                                        country={phoneCustomSelected3 as any}
                                        onInputChanged={setContentIcon2}
                                    />

                                    <Toggle
                                        defaultValue={iconControl2 as any}
                                        defaultChecked={iconControl2}
                                        disabled={ ( countTrueState === nSocialIcons) && (iconControl2 !== true)}
                                        onChange={(e) => {
                                            setIconControl2(e.target.checked);
                                            if(e.target.checked === true) setCountTrueState(countTrueState + 1)
                                            if(e.target.checked === false) setCountTrueState(countTrueState - 1)
                                        }}
                                    />

                                </div>

                                :

                                <div style={{ display: "flex", marginTop: "10px" }}>
                                    <input
                                        type="text"
                                        id="customInput3"
                                        placeholder="Use (https ou http) ex: https://gwcard.com.br"
                                        onChange={event => setContentIcon2(event.target.value)}
                                        value={contentIcon2}
                                    />
                                    <Toggle
                                        defaultValue={iconControl2 as any}
                                        defaultChecked={iconControl2}
                                        disabled={ ( countTrueState === nSocialIcons) && (iconControl2 !== true) }
                                        onChange={
                                            (e) => {setIconControl2(e.target.checked);
                                            if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                            if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                        }}
                                    />
                                        
                                </div>
                            }
                        </div>

                        <div style={{ borderTop: "1px solid #dee2e6", marginTop: "10px", paddingTop: "8px" }} className="personalization-input">
                            <span style={{ color: "black", fontSize: "18px", marginRight: "15px" }} ><strong>#4</strong></span>
                            <select name="typeIcon" id="type-icon" style={{ width: "145px" }} onChange={event => setTypeIcon3(event.target.value)} value={typeIcon3}>
                                <option value="whatsapp">WhatsApp</option>
                                <option value="telefone">Telefone</option>
                                <option value="email">E-mail</option>
                                <option value="site">Site</option>
                                <option value="siteTour">Site Tour</option>
                                <option value="instagram">Intagram</option>
                                <option value="localização">Localização</option>
                                <option value="youtube">Youtube</option>
                                <option value="linkedin">Linkedin</option>
                                <option value="github">GitHub</option>
                                <option value="twitter">Twitter</option>
                                <option value="pinterest">Pinterest</option>
                                <option value="pix">Pix</option>
                                <option value="bank">Banco</option>
                                <option value="menu">Cardápio</option>
                                <option value="schedule">Agendamento</option>
                                <option value="android">Play Store</option>
                                <option value="ios">Apple Store</option>
                                <option value="delivery">Delivery</option>
                                <option value="tiktok">TikTok</option>
                                <option value="360">Tour 360</option>
                                <option value="ifood">Ifood</option>
                                <option value="waze">Waze</option>
                                <option value="salonDigital">Salon Digital</option>
                                <option value="guiaFacil">Guia Fácil</option>
                            </select>

                            { typeIcon3 === 'whatsapp' || typeIcon3 === 'telefone' ? 

                                <div style={{ display: "flex", marginTop: "10px", alignItems: "center" }}>

                                    <ReactFlagsSelect

                                        selected={phoneCustomSelected4}

                                        onSelect={(code) => {

                                            setPhoneCustomSelected4(code);

                                            if(code === "BR")
                                                setPhoneCustomCountryCode4("55");
                                            if(code === "PY")
                                                setPhoneCustomCountryCode4("595");
                                            if(code === "AR")
                                                setPhoneCustomCountryCode4("54");
                                        }}

                                        placeholder="País"
                                        countries={["BR", "PY", "AR"]}
                                        customLabels={{
                                            "BR": { primary: "+55" },
                                            "PY": { primary: "+595" },
                                            "AR": { primary: "+54" }
                                        }}

                                        optionsSize={16}
                                        selectedSize={16}
                                    />
                                    <Input 
                                        id="customInput4"
                                        type="text"
                                        mask="phone"
                                        country={phoneCustomSelected4 as any}
                                        onInputChanged={setContentIcon3}
                                    />

                                    <Toggle
                                        defaultValue={iconControl3 as any}
                                        defaultChecked={iconControl3}
                                        disabled={ ( countTrueState === nSocialIcons) && (iconControl3 !== true)}
                                        onChange={(e) => {
                                            setIconControl3(e.target.checked);
                                            if(e.target.checked === true) setCountTrueState(countTrueState + 1)
                                            if(e.target.checked === false) setCountTrueState(countTrueState - 1)
                                        }}
                                    />

                                </div>

                                :

                                <div style={{ display: "flex", marginTop: "10px" }}>
                                    <input
                                        type="text"
                                        id="customInput4"
                                        placeholder="Use (https ou http) ex: https://gwcard.com.br"
                                        onChange={event => setContentIcon3(event.target.value)}
                                        value={contentIcon3}
                                    />
                                    <Toggle
                                        defaultValue={iconControl3 as any}
                                        defaultChecked={iconControl3}
                                        disabled={ ( countTrueState === nSocialIcons) && (iconControl3 !== true) }
                                        onChange={
                                            (e) => {setIconControl3(e.target.checked);
                                            if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                            if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                        }}
                                    />
                                        
                                </div>
                            }
                        </div>

                    </form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={beforeControlMediaClick}>Anterior</Button>
                    <Button variant="primary" onClick={() => {  
                        toast.promise(
                            pushCard(),
                            {
                                loading: 'Criando cartão digital...',
                                success: <b>Cartão digital criado com sucesso!</b>,
                                error: <b>Erro ao criar cartão digital.</b>,
                            }
                        );
                    }}>Salvar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}