import { useState } from "react";
import { Button, Modal, OverlayTrigger, Popover } from "react-bootstrap";

import { Formik, Form, Field, ErrorMessage } from 'formik';
import schemaAccountCompany from '../schemas/schemaAccountCompany';
//import schemaCompany from '../schemas/schemaCompany';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/modal.scss';
import { database } from "../services/firebase";
import toast from "react-hot-toast";
import firebase from "firebase";
import { Dropzone } from "../components/Dropzone/index";
//import LabelWithInfo from "../components/LabelWithInfo";

import Input from "../components/Input";
import { useHistory } from "react-router-dom";

export function ModalAddCompany( emails: any) {

    const history = useHistory();

    const [selectedFileLogo, setSelectedFileLogo] = useState<File>();
    const [selectedFileSeo, setSelectedFileSeo] = useState<File>();

    const [showLogin, setShowLogin] = useState(false);
    const [showAddCompany, setShowAddCompany] = useState(false);

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [emailExists, setEmailExists] = useState(false);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [card, setCard] = useState(0);
    const [cnpj, setCnpj] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [description, setDescription] = useState('');
    const [site, setSite] = useState('');
    const [facebook, setFacebook] = useState('');
    const [instagram, setInstagram] = useState('');
    const [whatsapp, setWhatsapp] = useState('');

    const handleBefore = ( values: any) =>{

        setName(values.nameCompany);
        setDescription(values.description);
        setCard(values.nCards);
        setAddress(values.address);
        setSite(values.site);
        setFacebook(values.facebook);
        setInstagram(values.instagram);

        setShowAddCompany(false);
        setShowLogin(true);
    }
    
    const handleClose = () => {
        setShowLogin(false);
        setShowAddCompany(false);

        setPassword('');
        setName('');
        setEmail('');
        setCard(0);
        setCnpj('');
        setAddress('');
        setPhone('');
        setDescription('');
        setSite('');
        setFacebook('');
        setInstagram('');
        setWhatsapp('');
    }

    const handleShow = () => setShowLogin(true);

    const loginClick =  () => {
        setShowLogin(false);
        setShowAddCompany(true);
    }

    async function pushCompany( values: any ){

        setShowAddCompany(false);

        let logo = '';
        let seo = '';
        let idCompany = '';
        let userUid = '';

        await firebase.auth().createUserWithEmailAndPassword(email, password)
        .then( (userCredential) => {

            userUid = userCredential.user?.uid as any;
            setPassword('');
            setConfirmPassword('');
            
        })
        .catch((error) => {
            console.log(error);
        });

        await database.collection("companies").add({
            idUser: userUid,
            name: values.nameCompany,
            email: email,
            nCards: values.nCards,
            cnpj: cnpj,
            address: values.address,
            phone: phone,
            description: values.description,
            site: values.site,
            facebook: values.facebook,
            instagram: values.instagram,
            whatsapp: whatsapp,
            admin: false,
            active: true,

            created_at: new Date()
        })
        .then((docRef) => {
            idCompany = docRef.id;

            history.push(`/dashboard/client/${idCompany}`);
        })
        .catch((error) => {
            console.log(error);
        });

        if(selectedFileLogo){
            //Upload do logo da Empresa
            await firebase.storage().ref(`${idCompany}/Logo/${selectedFileLogo?.name}`).put(selectedFileLogo as File).then( async function(snapshot) {
        
                await snapshot.ref.getDownloadURL().then(function(downloadURL) {
                    logo = downloadURL;
                });

            });
        }

        if(selectedFileSeo){
            //Upload do logo da Empresa
            await firebase.storage().ref(`${idCompany}/SEO/${selectedFileSeo?.name}`).put(selectedFileSeo as File).then( async function(snapshot) {
        
                await snapshot.ref.getDownloadURL().then(function(downloadURL) {
                    seo = downloadURL;
                });

            });
        }

        await database.collection('companies').doc(`${idCompany}`).update({
            logo: logo,
            seo: seo,

            updated_at: new Date()
        });

        setName('');
        setEmail('');
        setCard(0);
        setCnpj('');
        setAddress('');
        setPhone('');
        setDescription('');
        setSite('');
        setFacebook('');
        setInstagram('');
        setWhatsapp('');
    }

    function verifyEmail( emailCompany: string ) {

        for (var i = 0; i < emails.emails.length; i++){
            
            if(emails.emails[i] === emailCompany){
                setEmailExists(true);
                return;
            }
        }

        setEmailExists(false);
        return;
    }

    const popoverDescription = (
        <Popover id="popover-basic">
          <Popover.Body>
            Esse campo é utilizado para <strong>SEO</strong> do Cartão Digital. Preencha corretamente!
          </Popover.Body>
        </Popover>
    );

    const popoverLogo = (
        <Popover id="popover-basic">
          <Popover.Body>
            Utilize imagens no formato <strong>PNG</strong> com dimensão de <strong>200x100</strong> pixels em alta resolução para preservar a qualidade do Cartão Digital.<p style={{ marginTop: "8px" }}>Tamanho máximo da imagem: <strong>200 KB</strong></p>
          </Popover.Body>
        </Popover>        
    );

    const popoverSeo = (
        <Popover id="popover-basic">
          <Popover.Body>
            Utilize imagens no formato <strong>PNG</strong> com dimensão de <strong>1200x628</strong> pixels em alta resolução para preservar a qualidade do SEO.<p style={{ marginTop: "8px" }}>Tamanho máximo da imagem: <strong>200 KB</strong></p>
          </Popover.Body>
        </Popover>        
    );
      
    const Description = () => (
        <OverlayTrigger trigger="hover" placement="bottom" overlay={popoverDescription}>
            <svg style={{ marginLeft: "8px" }} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" className="svg-inline--fa fa-info-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" width="18px" viewBox="0 0 512 512">
                <path fill="#c4c4c4" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
            </svg>
        </OverlayTrigger>
    );

    const Logo = () => (
        <OverlayTrigger trigger="hover" placement="bottom" overlay={popoverLogo}>
            <svg style={{ marginLeft: "8px" }} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" className="svg-inline--fa fa-info-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" width="18px" viewBox="0 0 512 512">
                <path fill="#c4c4c4" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
            </svg>
        </OverlayTrigger>
    );

    const Seo = () => (
        <OverlayTrigger trigger="hover" placement="bottom" overlay={popoverSeo}>
            <svg style={{ marginLeft: "8px" }} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" className="svg-inline--fa fa-info-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" width="18px" viewBox="0 0 512 512">
                <path fill="#c4c4c4" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
            </svg>
        </OverlayTrigger>
    );

    return (
        <>
            <button className="plus-icon" onClick={handleShow}>
            +
            </button>

            <Modal show={showLogin} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Nova Empresa</Modal.Title>
                </Modal.Header>
                <Modal.Body> 
                    <Formik

                        validationSchema={schemaAccountCompany}
                        initialValues={{
                            emailCompany: email,
                            password: password,
                            confirmPassword: confirmPassword,
                        }}
                        
                        onSubmit={
                            ( values ) => {

                                setEmail(values.emailCompany);
                                setPassword(values.password);
                                setConfirmPassword(values.confirmPassword);

                                values.emailCompany = '';
                                values.password = '';
                                values.confirmPassword = '';

                                loginClick();
                        }}
                    >
                        { ( { isValid , handleBlur } ) => (

                            <Form className="form">
                                
                                <label htmlFor="emailCompany">E-mail: </label>
                                <Field onBlur={ e => { handleBlur(e); verifyEmail(e.currentTarget.value); }} id="emailCompany" name="emailCompany" type="email" placeholder="ex: email@gmail.com"></Field>
                                <ErrorMessage component="span" name="emailCompany"/>
                                { emailExists && <span>E-mail já cadastrado no sistema!</span> }

                                <label htmlFor="password">Senha: </label>
                                <Field id="password" name="password" type="password"></Field>
                                <ErrorMessage component="span" name="password"/>

                                <label htmlFor="confirmPassword">Confirme a Senha: </label>
                                <Field id="confirmPassword" name="confirmPassword" type="password"></Field>
                                <ErrorMessage component="span" name="confirmPassword"/>

                                <div style={{ marginTop: "48px", paddingBottom: "0" }}className="modal-footer">
                                    <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
                                    <Button disabled={ !isValid || (emailExists === true) } variant="primary" type="submit">Próximo</Button>
                                </div>
                                
                            </Form>

                        )}
                    </Formik>
        
                </Modal.Body>
            </Modal>

            <Modal show={showAddCompany} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Nova Empresa</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Formik

                        //validationSchema={schemaCompany}
                        initialValues={{
                            nameCompany: name,
                            nCards: card,
                            cnpj: cnpj,
                            address: address,
                            description: description,
                            site: site,
                            facebook: facebook,
                            instagram: instagram
                        }}

                        onSubmit={
                            ( values ) => {
                                
                                //Chama a função para gravar as informações no banco
                                toast.promise(
                                    pushCompany( values ),
                                    {
                                        loading: 'Criando empresa...',
                                        success: <b>Empresa criada com sucesso!</b>,
                                        error: <b>Erro ao criar empresa.</b>,
                                    }
                                );
                        }}
                        >
                        { ( { values } ) => (

                            <Form className="form">

                                <label htmlFor="nameCompany">Nome da empresa: </label>
                                <Field id="nameCompany" name="nameCompany" type="text" placeholder="ex: Cartão Online Prime"></Field>
                                <ErrorMessage component="span" name="nameCompany"/>

                                <label htmlFor="nCards">Número de cartões: </label>
                                <Field id="nCards" name="nCards" type="number"></Field>
                                <ErrorMessage component="span" name="nCards"/>

                                <label htmlFor="cnpj">CNPJ/CPF: </label>
                                <Input
                                    mask="cnpj"
                                    id="cnpj"
                                    name="cnpj"
                                    type="text"
                                    placeholder="ex: XX.XXX.XXX/0001-XX"
                                    onInputChanged={setCnpj}
                                />

                                <label htmlFor="address">Endereço: </label>
                                <Field id="address" name="address" type="text" placeholder="Rua, nº, Bairro, CEP"></Field>
                                <ErrorMessage component="span" name="address"/>

                                {/*<LabelWithInfo htmlForText="description" labelText="Descrição: " popoverText={["Esse campo é utilizado para ", <strong>SEO</strong>,  " do Cartão Digital. Preencha corretamente!"]} />*/}
                                <div style={{ display: "flex", alignItems: "end" }}>
                                    <label htmlFor="description">Descrição da empresa: </label>
                                    <Description />
                                </div>
                                <Field id="description" name="description" type="text" placeholder="ex: Empresa de Marketing Digital"></Field>
                                <ErrorMessage component="span" name="description"/>

                                <label htmlFor="phone">Telefone: </label>
                                <Input 
                                    mask="phone"
                                    id="phone"
                                    name="phone"
                                    placeholder="ex: (XX) XXXX-XXXX"
                                    onInputChanged={setPhone}
                                />
                                
                                <label htmlFor="whatsApp">WhatsApp: </label>
                                <Input 
                                    mask="phone"
                                    id="whatsapp"
                                    name="whatsapp"
                                    placeholder="ex: (XX) XXXXX-XXXX"
                                    onInputChanged={setWhatsapp}
                                />

                                {/*<LabelWithInfo htmlForText="fileLogoCompany" labelText="Logo da empresa: " popoverText={["Utilize imagens no formato ", <strong>PNG</strong>,  " com tamanho de ", <strong>200x100</strong> ," pixels em alta resolução para preservar a qualidade do Cartão Digital."]} />*/}
                                <div style={{ display: "flex", alignItems: "end" }}>
                                    <label htmlFor="fileLogoCompany">Logo da empresa: </label>
                                    <Logo />
                                </div>
                                <Dropzone onFileUploaded={setSelectedFileLogo} text="Logo da empresa" />

                                {/*<LabelWithInfo htmlForText="fileSeoCompany" labelText="Imagem para SEO: " popoverText={["Utilize imagens no formato ", <strong>PNG</strong>,  " com tamanho de ", <strong>1200x628</strong> ," pixels em alta resolução para preservar a qualidade do SEO."]} />*/}
                                <div style={{ display: "flex", alignItems: "end" }}>
                                    <label htmlFor="fileSeoCompany">Imagem para SEO: </label>
                                    <Seo />
                                </div>
                                <Dropzone onFileUploaded={setSelectedFileSeo} text="Imagem para SEO" />

                                <label htmlFor="site">Site: </label>
                                <Field id="site" name="site" type="text" placeholder="Use (https ou http) ex: https://gwcard.com.br"></Field>
                                <ErrorMessage component="span" name="site"/>

                                <label htmlFor="facebook">Facebook: </label>
                                <Field id="facebook" name="facebook" type="text" placeholder="Use (https ou http) ex: https://facebook.com"></Field>
                                <ErrorMessage component="span" name="facebook"/>

                                <label htmlFor="instagram">Instagram: </label>
                                <Field id="instagram" name="instagram" type="text" placeholder="Use (https ou http) ex: https://instagram.com"></Field>
                                <ErrorMessage component="span" name="instagram"/>

                                <div style={{ marginTop: "48px", paddingBottom: "0" }}className="modal-footer">
                                    <Button variant="secondary" onClick={() => handleBefore(values)}>Anterior</Button>
                                    <Button variant="primary" type="submit">Salvar</Button>
                                </div>      
                            </Form>
                        )}
                    </Formik>

                </Modal.Body>
                
            </Modal>
        </>
    );
}