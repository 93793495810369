import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import VCard from 'vcard-creator';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/modal.scss';

type dataModalDownloadContact = {
  nameCompany: string;
  phoneCompany: string;
  whatsCompany: string;
  addressCompany: string;
  descriptionCompany: string;
  siteCompany: string;
  emailCompany: string;
  seoCompany: string;
  cardName: string;
  iconColor: string;
  textIconColor:string;
}

export function ModalDownloadContact(props: dataModalDownloadContact) {

  const[smShow, setSmShow] = useState(false);
  const [ fileUrl, setFileUrl ] = useState('');

  const handleClickAddContact = () => {
    
    const myVCard = new VCard("vcard");

    myVCard
      // Add personal data
      .addName(undefined , props.cardName , undefined, undefined, undefined)
      // Add work data
      .addCompany(props.nameCompany)
      .addJobtitle(props.descriptionCompany)
      .addEmail(props.emailCompany)
      .addPhoneNumber(props.whatsCompany.replace(/[^0-9]/g,''), 'PREF;WORK')
      .addPhoneNumber(props.phoneCompany.replace(/[^0-9]/g,''), 'WORK')
      .addAddress(undefined, undefined, props.addressCompany, undefined, undefined, undefined, undefined)
      .addURL(props.siteCompany);

    let blob = new Blob([myVCard.toString()], { type: 'text/plain' });


    setFileUrl( URL.createObjectURL(blob) );

  }


  return (
    <>
        <div onClick={ () => {
          handleClickAddContact();
          setSmShow(true);

        }} className="add-contact">

            <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512" width="26px" fill={props.iconColor}>
                <path d="M106 304v-54h54v-36h-54v-54H70v54H16v36h54v54h36z"/><circle cx="288" cy="144" r="112"/><path d="M288 288c-69.42 0-208 42.88-208 128v64h416v-64c0-85.12-138.58-128-208-128z"/>
            </svg>
            <p style={ {color: `${props.textIconColor}` } }>Adicionar contato</p>
  
        </div>

        <Modal
            size="sm"
            show={smShow}
            onHide={() => {
              URL.revokeObjectURL(fileUrl);
              setSmShow(false);
            }}
            aria-labelledby="example-modal-sizes-title-sm"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-sm">
                Salvar Contato
              </Modal.Title>
            </Modal.Header>
            <Modal.Body> Clique no botão para fazer download do contato. <br /><br /> Para sistemas <strong>IOS</strong>, o contato é importado automaticamente após o download. <br /><br /> Para sistemas <strong>ANDROID</strong>, é necessário clicar no arquivo baixado para importar na lista de contatos. </Modal.Body>
            <a style={{ display: "flex", justifyContent: "center", textDecoration: "none" }} href={fileUrl} download="contato.vcf" target="_blank" rel="noreferrer"><button id="btn-add-contact" style={{ marginBottom: "16px", marginTop: '12px' }} onClick={() => {
              setSmShow(false);

              setTimeout(function(){
                URL.revokeObjectURL(fileUrl);
              }, 5000);
            }}> Salvar </button></a>
            
        </Modal>
    </>
  );
}