import { useHistory, useParams } from 'react-router-dom';
import { Layout1 } from '../components/CardStyle/Layout1';
import { Layout2 } from '../components/CardStyle/Layout2';
import { Layout3 } from '../components/CardStyle/Layout3';
import { Layout4 } from '../components/CardStyle/Layout4';
import { Layout5 } from '../components/CardStyle/Layout5';
import { Layout6 } from '../components/CardStyle/Layout6';
import { Layout7 } from '../components/CardStyle/Layout7';
import { database } from '../services/firebase';
import { useState } from "react";

export function Card(){

    const history = useHistory();

    const { idCard } = useParams<{idCard?: string}>();
    const { idCompany } = useParams<{idCompany?: string}>();

    const [layout, setLayout] = useState(-1);
    const [activeCard, setActiveCard] = useState(true);
    const [activeCompany, setActiveCompany] = useState(true);

    const GetData = async () => {

        //Faz a consulta das informações da empresa no banco de dados
        let docCardRef = database.collection("cards").doc(`${idCard}`);

        await docCardRef.get().then((doc) => {
            if (doc.exists) {
                setLayout(doc.data()?.layout);
                setActiveCard(doc.data()?.active);

                if(doc.data()?.active === false){
                    history.push('/cardNotFound');
                }

            } else {
                history.push('/cardNotFound');
            }
        });

        let docCompanyRef = database.collection("companies").doc(`${idCompany}`);

        await docCompanyRef.get().then((doc) => {
            if (doc.exists) {
                setActiveCompany(doc.data()?.active);

                if(doc.data()?.active === false){
                    history.push('/cardNotFound');
                }

            } else {
                history.push('/cardNotFound');
            }
        });
    }

    GetData();
        
    return (

        <div>
           
            { (layout === 1 && activeCard === true && activeCompany === true) &&

                <Layout1
                    idCard={idCard}
                    idCompany={idCompany}
                >

                </Layout1>

            }

            { (layout === 2 && activeCard === true && activeCompany === true) &&

                <Layout2
                    idCard={idCard}
                    idCompany={idCompany}
                >

                </Layout2>

            }

            { (layout === 3 && activeCard === true && activeCompany === true) &&

                <Layout3
                    idCard={idCard}
                    idCompany={idCompany}
                >

                </Layout3>

            }

            { (layout === 4 && activeCard === true && activeCompany === true) &&

                (<Layout4
                    idCard={idCard}
                    idCompany={idCompany}
                >

                </Layout4>)
            }

            { (layout === 5 && activeCard === true && activeCompany === true) &&

                (<Layout5
                    idCard={idCard}
                    idCompany={idCompany}
                >

                </Layout5>)
            }

            { (layout === 6 && activeCard === true && activeCompany === true) &&

                (<Layout6
                    idCard={idCard}
                    idCompany={idCompany}
                >

                </Layout6>)
            }

            { (layout === 7 && activeCard === true && activeCompany === true) &&

                (<Layout7
                    idCard={idCard}
                    idCompany={idCompany}
                >

                </Layout7>)
            }
                    
        </div>
        
    )   
}
