import { ReactNode, useState } from 'react';
import toast from 'react-hot-toast';
import Toggle from 'react-toggle';
import { database } from '../services/firebase';
import '../styles/company.scss';
import '../styles/toggle.scss';

type CardProps = {
  id: string;
  name: string;
  active: boolean;
  children: ReactNode;
}

export function Card(props: CardProps) {

  const [toggle, setToggle] = useState(props.active);

  return (
    <div className="company">
      <div className="header-company">
        <div onClick={async () => {

          await navigator.clipboard.writeText(props.name).then( () => {
              toast.success("Nome do cartão digital copiado para área de transferência!");
          });

          }}>
          <p style={{ cursor: "pointer" }}>{props.name}</p>

        </div>
        <Toggle
          id='cheese-status'
          defaultChecked={props.active}
          defaultValue={props.active as any}
          onChange={(e) => {
            setToggle(e.target.checked);
            database.collection("cards").doc(props.id).set({
              active: e.target.checked,
            }, { merge: true } )
            .then(() => {
                {toggle===true ? toast.success(`O cartão digital ${props.name} foi desativado`)
                  : toast.success(`O cartão digital ${props.name} foi ativado`)
                }
            })
            .catch((error) => {
                toast.error("Erro ao ativar/desativar cartão!");
                console.log(error);
            });
          }}
        />
      </div>
  
      <footer>
        <div className="separator"></div>
        <div className="icons">
          {props.children}
        </div>
      </footer>
      
    </div>
  );
}