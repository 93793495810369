export function cep(e: React.FormEvent<HTMLInputElement>){
    e.currentTarget.maxLength = 9;
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, "");
    value = value.replace(/^(\d{5})(\d)/, "$1-$2");
    e.currentTarget.value = value;
    return e;
}

export function phone(e: React.FormEvent<HTMLInputElement>, country){

    if(country === "BR" || country === undefined || country === ""){
        e.currentTarget.maxLength = 15;

        let value = e.currentTarget.value;

        value = value.replace(/\D/g, "");
        value = value.replace(/(\d{2})(\d)/, "($1) $2");
        value = value.replace(/(\d{4})(\d)/, "$1-$2");
        e.currentTarget.value = value;

        if(value.length === 15){

            let newValue = value.replace(/\-/g, "");
            newValue = newValue.replace(/\D/g, "");
            newValue = newValue.replace(/(\d{2})(\d)/, "($1) $2");
            newValue = newValue.replace(/(\d{5})(\d)/, "$1-$2");
            e.currentTarget.value = newValue;

            return e;
        }

        e.currentTarget.value = value;
        return e;
    }

    if(country === "PY"){
        e.currentTarget.maxLength = 10;

        let value = e.currentTarget.value;

        value = value.replace(/\D/g, "");
        value = value.replace(/(\d{3})(\d)/, "$1 $2");
        e.currentTarget.value = value;

        return e;
    }

    if(country === "AR"){
        e.currentTarget.maxLength = 14;

        let value = e.currentTarget.value;

        value = value.replace(/\D/g, "");
        value = value.replace(/(\d{1})(\d)/, "$1 $2");
        value = value.replace(/(\d{3})(\d{3})/, "$1 $2");
        value = value.replace(/(\d{3})(\d{4})/, "$1 $2");
        e.currentTarget.value = value;

        return e;
    }
}

export function cnpj(e: React.FormEvent<HTMLInputElement>){
    e.currentTarget.maxLength = 18;
    let value = e.currentTarget.value;

    value = value.replace(/\D/g, '');
    value = value.replace(/^(\d{3})(\d)/, '$1.$2');
    value = value.replace(/^(\d{3})\.(\d{3})(\d)/, '$1.$2.$3');
    value = value.replace(/\.(\d{3})(\d{2})/, '.$1-$2');

    if(value.length >= 15){

        let newValue = value.replace(/\./g, "");
        newValue = newValue.replace(/\-/g, "");
    
        newValue = newValue.replace(/\D/g, ''); 
        newValue = newValue.replace(/^(\d{2})(\d)/, '$1.$2'); 
        newValue = newValue.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3'); 
        newValue = newValue.replace(/\.(\d{3})(\d)/, '.$1/$2'); 
        newValue = newValue.replace(/(\d{4})(\d)/, '$1-$2');


        e.currentTarget.value = newValue;

        return e;
    }

    e.currentTarget.value = value;
    return e;
}