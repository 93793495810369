import { BrowserRouter, Route, Switch } from "react-router-dom";
import { AdminDashboard } from "./pages/adminDashboard";
import { Login } from "./pages/Login";
import { AuthContextProvider } from './contexts/AuthContext';
import { ClientDashboard } from "./pages/clientDashboard";
import { AdminCards } from './pages/adminCards';
import { Card } from "./pages/card";
import { ChangePassword } from "./pages/changePassword";
import { LoginChangePassword } from './pages/loginChangePassword';
import { ChangeEmail } from "./pages/changeEmail";
import { PageNotFound } from "./pages/pageNotFound";
import { CardNotFound } from "./pages/cardNotFound";

function App() {

  return (
    <BrowserRouter>
      <AuthContextProvider>
        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/password" exact component={LoginChangePassword} />
          <Route path="/dashboard/admin/:idCompany" exact component={AdminDashboard} />
          <Route path="/dashboard/admin/:idCompanyAdmin/:idCompanyClient/cards" exact component={AdminCards} />
          <Route path="/dashboard/client/:idCompany" exact component={ClientDashboard} />
          <Route path="/dashboard/client/:idCompany/password" exact component={ChangePassword} />
          <Route path="/dashboard/client/:idCompany/email" exact component={ChangeEmail} />
          <Route path="/card/:idCompany/:idCard" exact component={Card} />
          <Route path="/pageNotFound" exact component={PageNotFound} />
          <Route path="/cardNotFound" exact component={CardNotFound} />
          <Route component={PageNotFound} />
        </Switch>
      </AuthContextProvider> 
    </BrowserRouter>
  );
}

export default App;
