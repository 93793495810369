import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Dropdown, Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router-dom';
import logoImg from '../assets/images/gwcardlogo.svg';
import { Loading } from '../components/Loading';
import { ModalLogoutUser } from '../components/ModalLogoutUser';
import { CheckUserAuth } from '../hooks/checkUserAuth';
import { database, auth } from '../services/firebase';

import schemaChangeEmail from '../schemas/schemaChangeEmail';

//import '../styles/auth.scss';
import '../styles/modal.scss';
import { BrowserView, MobileOnlyView } from 'react-device-detect';
import { Logout } from '../hooks/logout';

export function ChangeEmail(){

    const history = useHistory();

    const { idCompany } = useParams<{idCompany?: string}>();

    CheckUserAuth(idCompany as string);

    const [isLoading, setIsLoading] = useState(true);

    const [nameCompany, setNameCompany] = useState('');

    const [emails, setEmails] = useState<Array<string>>([]);

    const [emailExists, setEmailExists] = useState(false);

    const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);

    const user = auth.currentUser;

    const changePassword = () => {
        history.push(`/dashboard/client/${idCompany}/password`);
    }

    async function changeEmail( values ){

        setIsLoadingUpdate(true);

        if (user !== null) {

            const email = user.email;

            if( email !== values.confirmEmail ){
                setIsLoadingUpdate(false);
                toast.error("E-mail antigo incorreto!");

                return;

            } else {

                user.updateEmail(values.newEmail).then( async () => {

                    await database.collection("companies").doc(idCompany).update({
                        email: values.newEmail,

                        updated_at: new Date()
            
                    }).catch((error) => {
                        setIsLoadingUpdate(false);

                        console.log(error);
                    });

                    setIsLoadingUpdate(false);
                    setEmailExists(false);

                    toast.success("Email alterado com sucesso!");

                }).catch(() => {

                    auth.signOut().then(() => {
                        setIsLoadingUpdate(false);
                        toast.error("Sessão expirada! Faça login novamente.");

                        history.push("/");
                    });
                });
            }
        }
    }

    useEffect( () => {

        var unsubscribeEmail = database.collection("companies").onSnapshot((querySnapshot) => {
            let parsedEmails: Array<string> = [];

            querySnapshot.forEach((doc) => {
                parsedEmails.push(doc.data().email);
            });

            setEmails(parsedEmails);
            return;
        });

        var unsubscribeCompany = database.collection("companies").doc(`${idCompany}`).onSnapshot((doc) => {
            if (doc.exists) {
                
                if(doc.data()?.active === false){
                    Logout();
                    history.push('/');
                }

                setNameCompany(doc.data()?.name);
                setIsLoading(false);
            } else {
                setIsLoading(false);
                history.push('/pageNotFound');
            }
            
        }, (error) => {
            if(error){
                history.push('/');
            }
        });

        return () => {
            unsubscribeEmail();
            unsubscribeCompany();
        }

    }, [history, idCompany ]);

    function verifyEmail( emailCompany: string ) {

        for (var i = 0; i < emails.length; i++){
            
            if(emails[i] === emailCompany ){
                setEmailExists(true);
                return;
            }
        }
        setEmailExists(false);
        return;
    }

    return (
        <div>
            { isLoading ? <Loading /> :
                <div id="page-dashboard">
                    <header>
                        <div className="content-client-dashboard">
                            <div className="content-left">
                                <img style={{ cursor: "pointer" }} onClick={() => history.push(`/dashboard/client/${idCompany}`)} src={logoImg} alt="Logo Cartão Online Prime" />
                                <BrowserView>
                                    <p style={{ fontWeight: 'bold' }}>Bem-vinda(o), {nameCompany}!</p>
                                </BrowserView> 
                            </div>
                            <div className="content-right">

                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512" width="24" height="24">
                                            <path d="M256 176a80 80 0 1080 80 80.24 80.24 0 00-80-80zm172.72 80a165.53 165.53 0 01-1.64 22.34l48.69 38.12a11.59 11.59 0 012.63 14.78l-46.06 79.52a11.64 11.64 0 01-14.14 4.93l-57.25-23a176.56 176.56 0 01-38.82 22.67l-8.56 60.78a11.93 11.93 0 01-11.51 9.86h-92.12a12 12 0 01-11.51-9.53l-8.56-60.78A169.3 169.3 0 01151.05 393L93.8 416a11.64 11.64 0 01-14.14-4.92L33.6 331.57a11.59 11.59 0 012.63-14.78l48.69-38.12A174.58 174.58 0 0183.28 256a165.53 165.53 0 011.64-22.34l-48.69-38.12a11.59 11.59 0 01-2.63-14.78l46.06-79.52a11.64 11.64 0 0114.14-4.93l57.25 23a176.56 176.56 0 0138.82-22.67l8.56-60.78A11.93 11.93 0 01209.94 26h92.12a12 12 0 0111.51 9.53l8.56 60.78A169.3 169.3 0 01361 119l57.2-23a11.64 11.64 0 0114.14 4.92l46.06 79.52a11.59 11.59 0 01-2.63 14.78l-48.69 38.12a174.58 174.58 0 011.64 22.66z"/>
                                        </svg>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={changePassword}>Alterar Senha</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                                <ModalLogoutUser />
                            </div>
                        </div>
                    </header>
                        <main>
                            <MobileOnlyView>
                                <p style={{ fontWeight: 'bold', marginTop: '12px' }}>Bem-vinda(o), {nameCompany}!</p>
                            </MobileOnlyView>  
                            <div className="title">
                                <h1 style={{marginRight: '12px', marginTop: '10px'}}>Deseja alterar seu e-mail?</h1>
                            </div>

                            <div style={{ display: 'grid', marginTop: '12px'}}>

                                <Formik

                                    validationSchema={schemaChangeEmail}
                                    initialValues={{
                                        confirmEmail: '',
                                        newEmail: '',
                                        confirmNewEmail: '',
                                    }}

                                    onSubmit={
                                        async ( values, { resetForm } ) => {

                                            await changeEmail( values );
                                            resetForm();
                                    }}
                                    >
                                    { ( { values } ) => (

                                        <Form style={{ maxWidth: "80%", paddingLeft: "0" }} className="form">

                                            <label htmlFor="confirmEmail">E-mail Antigo: </label>
                                            <Field id="confirmEmail" name="confirmEmail" type="text"></Field>
                                            <ErrorMessage component="span" name="confirmEmail"/>
                                            
                                            <label htmlFor="newEmail">Novo E-mail: </label>
                                            <Field validateOnBlur={ verifyEmail(values.newEmail) } id="newEmail" name="newEmail" type="text" placeholder="ex: email@gmail.com"></Field>
                                            <ErrorMessage component="span" name="newEmail"/>
                                            { emailExists && <span>E-mail já cadastrado no sistema!</span> }

                                            <label htmlFor="confirmNewEmail">Confirme seu novo E-mail: </label>
                                            <Field id="confirmNewEmail" name="confirmNewEmail" type="text"></Field>
                                            <ErrorMessage component="span" name="confirmNewEmail" className="confirm-new-email"/>
                                            
                                            <button disabled={ emailExists } type="submit" className="change-email">
                                                { isLoadingUpdate ? 
                                                    <Spinner
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                :
                                                    <>Alterar</>  
                                                }
                                                
                                            </button>
                                        
                                        </Form>

                                    )}
                                </Formik>

                            </div>
                        </main>
                </div>
            }
        </div>
    );
}
