import { createContext, ReactNode, useEffect, useState } from "react";
import { auth } from "../services/firebase";

type UserLogin = {
    id: string,
    email: string | null,
}
  
type AuthContextType = {
    userLogin: UserLogin | undefined,
    userNormalLogin: (email: string, password: string) => Promise<void>
}

type AuthContextProviderProps = {
    children: ReactNode
}

export const AuthContext = createContext( {} as AuthContextType );

export function AuthContextProvider(props: AuthContextProviderProps){

    const [userLogin, setUserLogin] = useState<UserLogin>();

    useEffect(() => {
      const  unsubscribe = auth.onAuthStateChanged(userLogin =>{
        if( userLogin ) {
          const { uid, email } = userLogin
  
          setUserLogin({
            id: uid,
            email: email
          })
        }
      })
  
      return () => {
        unsubscribe();
      } 
    }, [])

    async function userNormalLogin(email: string, password: string){

      const userCredentials = await auth.signInWithEmailAndPassword(email, password);

      if(!userCredentials){
        throw new Error("Email ou senha incorreto.")
      }
          
      if(userCredentials.user){
        const { uid, email } = userCredentials.user;

        if( !email ){
          throw new Error("Missing Information from User account");
        }

        setUserLogin({
          id: uid,
          email: email
        })

        return;
      }
    }

    return (
        <AuthContext.Provider value={{userLogin, userNormalLogin}} >
            {props.children}
        </AuthContext.Provider>
    );
}