import logoImg from '../assets/images/gwcardlogo.svg';
import { useHistory, useParams } from 'react-router-dom';

import '../styles/dashboard.scss';
import { useEffect, useState } from 'react';
import { database } from '../services/firebase';
import { ModalLogoutUser } from '../components/ModalLogoutUser';
import { CheckUserAuth } from '../hooks/checkUserAuth';
import { ClientCard } from '../components/ClientCard';
import toast from 'react-hot-toast';
import { Dropdown } from 'react-bootstrap';
import { ModalEditCompanyClient } from '../components/ModalEditCompanyClient';
//import { ModalEditCardClient } from '../components/ModalEditCardClient';
import { Loading } from '../components/Loading';
import { ModalEditCard } from '../components/ModalEditCard';
import { MobileOnlyView, BrowserView  } from 'react-device-detect';

import { Logout } from '../hooks/logout'

type Cards = Record<string,{
    id : string
    idUser: string,
    name: string,
    email: string,
    phone: string,
    admin: boolean,
    active: boolean
}>

export function ClientDashboard(){

    const [companyNotFound, setCompanyNotFound] = useState(true);
    const { idCompany } = useParams<{idCompany?: string}>();

    CheckUserAuth(idCompany as string);

    //Verifica se o usuário está logado na sessão
    const history = useHistory();

    const [names, setNames] = useState<Array<string>>([]);

    const [nameCompany, setNameCompany] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [description, setDescription] = useState('');
    const [facebook, setFacebook] = useState('');
    const [instagram, setInstagram] = useState('')
    const [logoCompany, setLogoCompany] = useState('');
    const [seo, setSeo] = useState('');
    const [nCards, setNCards] = useState(0);
    const [phone, setPhone] = useState('');
    const [site, setSite] = useState('');
    const [whatsapp, setWhatsapp] = useState('');
    
    const [cards, setCards] = useState<Cards[]>([]);
    const [foundCards, setFoundCards] = useState(cards);
    
    const changePassword = () => {
        history.push(`/dashboard/client/${idCompany}/password`);
    }
    
    const changeEmail = () => {
        history.push(`/dashboard/client/${idCompany}/email`);
    }

    useEffect( () => {

        var unsubscribeCard = database.collection("cards").orderBy("cardName").onSnapshot((querySnapshot) => {
            let parsedCards = [{}];
            let parsedNameCards: Array<string> = [];
            parsedCards.shift();
            querySnapshot.forEach((doc) => {
                if(doc.data().idCompany === idCompany) {
                    parsedCards.push(Object.assign(doc.data(), {id: doc.id}));
                    parsedNameCards.push(doc.data().cardName);
                }
            });
    
            setCards(parsedCards);
            setNames(parsedNameCards);
            setFoundCards(parsedCards);

        }, (error) => {
            if(error){
                history.push('/');
            }
        });

        var unsubscribeCompany = database.collection("companies").doc(`${idCompany}`).onSnapshot((doc) => {
            if (doc.exists) {

                if(doc.data()?.active === false){
                    Logout();
                    history.push('/');
                }

                setNameCompany(doc.data()?.name);
                setEmail(doc.data()?.email);
                setDescription(doc.data()?.description);
                setCnpj(doc.data()?.cnpj);
                setLogoCompany(doc.data()?.logo);
                setSeo(doc.data()?.seo);
                setAddress(doc.data()?.address);
                setPhone(doc.data()?.phone);
                setFacebook(doc.data()?.facebook);
                setInstagram(doc.data()?.instagram);
                setWhatsapp(doc.data()?.whatsapp);
                setSite(doc.data()?.site);

                setCompanyNotFound(false);
                return;
            } else {
                setCompanyNotFound(false);
                history.push(`/pageNotFound`);
                return;
            }

        }, (error) => {
            if(error){
                history.push('/');
                return;
            }
        });

        return () => {
            unsubscribeCard();
            unsubscribeCompany();
        }

    }, [history, idCompany]);

    function viewCard(cardId: string) {

        const win = window.open(`/card/${idCompany}/${cardId}`);
        win?.focus();
    }

    const [name, setName] = useState('');
    
    const filter = (e: any) => {
        const keyword = e.target.value;

        if (keyword !== '') {

            const lowerBusca = keyword.toLowerCase().normalize('NFD').replace(/[^a-zA-Zs]/g, "");

            const results = cards.filter((card: any) => {

                return card.cardName.toLowerCase().normalize('NFD').replace(/[^a-zA-Zs]/g, "").includes(lowerBusca);
            });

            setFoundCards(results);
        } else {
        setFoundCards(cards);
        }
        setName(keyword);
    };

    return(
        <div id="page-dashboard">
            {companyNotFound ? <Loading /> :
                <div>
                    <header>
                        <div className="content-client-dashboard">
                            <div className="content-left">
                                <img src={logoImg} alt="Logo Cartão Online Prime" />
                                <BrowserView>
                                    <p style={{ fontWeight: 'bold' }}>Bem-vinda(o), {nameCompany}!</p>
                                </BrowserView>
                            </div>
                            <div className="content-right">
                                <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="ionicon" viewBox="0 0 512 512" width="24" height="24">
                                            <path d="M256 176a80 80 0 1080 80 80.24 80.24 0 00-80-80zm172.72 80a165.53 165.53 0 01-1.64 22.34l48.69 38.12a11.59 11.59 0 012.63 14.78l-46.06 79.52a11.64 11.64 0 01-14.14 4.93l-57.25-23a176.56 176.56 0 01-38.82 22.67l-8.56 60.78a11.93 11.93 0 01-11.51 9.86h-92.12a12 12 0 01-11.51-9.53l-8.56-60.78A169.3 169.3 0 01151.05 393L93.8 416a11.64 11.64 0 01-14.14-4.92L33.6 331.57a11.59 11.59 0 012.63-14.78l48.69-38.12A174.58 174.58 0 0183.28 256a165.53 165.53 0 011.64-22.34l-48.69-38.12a11.59 11.59 0 01-2.63-14.78l46.06-79.52a11.64 11.64 0 0114.14-4.93l57.25 23a176.56 176.56 0 0138.82-22.67l8.56-60.78A11.93 11.93 0 01209.94 26h92.12a12 12 0 0111.51 9.53l8.56 60.78A169.3 169.3 0 01361 119l57.2-23a11.64 11.64 0 0114.14 4.92l46.06 79.52a11.59 11.59 0 01-2.63 14.78l-48.69 38.12a174.58 174.58 0 011.64 22.66z"/>
                                        </svg>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={changeEmail}>Alterar E-mail</Dropdown.Item>
                                        <Dropdown.Item onClick={changePassword}>Alterar Senha</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
            
                                <ModalLogoutUser />
                            </div>
                        </div>
                    </header>

                    <main style={{ marginBottom: "64px" }}>
                        <MobileOnlyView>
                            <p style={{ fontWeight: 'bold', marginTop: '12px' }}>Bem-vinda(o), {nameCompany}!</p>
                        </MobileOnlyView>
                        <div className="title">
                            <h1 style={{marginRight: '12px', marginTop: '10px'}}>Informações da sua empresa</h1>
                            <ModalEditCompanyClient
                                id={idCompany}
                                name={nameCompany}
                                description={description}
                                address={address}
                                cnpj={cnpj}
                                facebook={facebook}
                                instagram={instagram}
                                whatsapp={whatsapp}
                                logo={logoCompany}
                                seo={seo}
                                phone={phone}
                                site={site}
                            >

                            </ModalEditCompanyClient>
                        </div>

                        <div className="info-company">
                            <p style={{ textAlign: 'center', marginTop:  '8px', marginBottom: '8px' }}><img  style={{ maxWidth: '200px', maxHeight: '100px' }} src={logoCompany} alt={`Logo da empresa ${nameCompany}`} /></p>
                            <p><strong>Nome: </strong>{nameCompany}</p>
                            <p><strong>E-mail: </strong>{email}</p>
                            <p><strong>Descrição: </strong>{description}</p>
                            <p><strong>CNPJ/CPF: </strong>{cnpj}</p>
                            <p><strong>Endereço: </strong>{address}</p>
                            <p><strong>Telefone: </strong>{phone}</p>
                            <p><strong>WhatsApp: </strong>{whatsapp}</p>
                            <p><strong>Facebook: </strong>{facebook}</p>
                            <p><strong>Instagram: </strong>{instagram}</p>
                            <p><strong>Site: </strong>{site}</p>
                        </div>

                        <div className="title">
                            <h1 style={{ marginBottom: '0' }}>Cartões Digitais</h1>
                            {cards.length > 0 && <span>{cards.length}</span>}
                        </div>

                        <label style={{ marginRight: '14px' }} htmlFor="ffilter">Busque seu cartão digital: </label>
                        <input
                            type="search"
                            id="ffilter"
                            value={name}
                            onChange={filter}
                            className="input-filter"
                            placeholder="Filtro"
                        />

                        <div className="company-list">
                        {foundCards && foundCards.length > 0 ? (
                            foundCards.map((card) => {
                                return(
                                    <ClientCard 
                                        key={card.id as any}
                                        name={card.cardName as any}
                                        active={card.active as any}
                                        id={card.id as any}
                                    >
                                        <button
                                            className="cards"
                                            type="button"
                                            aria-label="Visualizar os cartão"
                                            onClick={() => viewCard(card.id as any)}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/>
                                                <path d="M12 6c3.79 0 7.17 2.13 8.82 5.5C19.17 14.87 15.79 17 12 17s-7.17-2.13-8.82-5.5C4.83 8.13 8.21 6 12 6m0-2C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 5c1.38 0 2.5 1.12 2.5 2.5S13.38 14 12 14s-2.5-1.12-2.5-2.5S10.62 9 12 9m0-2c-2.48 0-4.5 2.02-4.5 4.5S9.52 16 12 16s4.5-2.02 4.5-4.5S14.48 7 12 7z"/>
                                            </svg>
                                        </button>

                                        <button
                                            className="copy-card-link"
                                            type="button"
                                            aria-label="Visualizar os cartão"
                                            onClick={ async () => {
                                                
                                                await navigator.clipboard.writeText(`https://gowebbycard.com.br/card/${idCompany}/${card.id}`).then( () => {
                                                    toast.success("Link do cartão digital copiado para área de transferência!");
                                                });
                                            }}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512" width="24px" height="24px">
                                                <path d="M200.66 352H144a96 96 0 010-192h55.41M312.59 160H368a96 96 0 010 192h-56.66M169.07 256h175.86" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48"/>
                                            </svg>

                                        </button>

                                        {/*<ModalEditCardClient
                                        idCompany={card.idCompany as any}
                                        idCard={card.id as any}
                                        cardName={card.cardName as any}
                                        layout={card.layout as any}
                                        arrowColor={card.arrowColor as any}
                                        textColor={card.textColor as any}
                                        textIconColor={card.textIconColor as any}
                                        iconColor={card.iconColor as any}
                                        backgroundIconColor={card.backgroundIconColor as any}
                                        
                                        nSocialIcons={card.nSocialIcons as any}
                                        phone={card.phone as any}
                                        email={card.email as any}
                                        site={card.site as any}
                                        face={card.face as any}
                                        faceId={card.faceId as any}
                                        insta={card.insta as any}
                                        whats={card.whats as any}
                                        location={card.location as any}
                                        youtube={card.youtube as any}
                                        linkedin={card.linkedin as any}
                                        github={card.github as any}
                                        twitter={card.twitter as any}
                                        pinterest={card.pinterest as any}

                                        personalName={card.personalName as any}
                                        description={card.description as any}
                                        occupation={card.occupation as any}
                                        occupationComplement={card.occupationComplement as any}

                                        backgroundF={card.backgroundF as any}
                                        backgroundB={card.backgroundB as any}
                                        photo={card.photo as any}

                                        counter={card.counter as any}
                                        
                                        >

                                        </ModalEditCardClient>*/}

                                        <ModalEditCard
                                        idCompany={card.idCompany as any}
                                        idCard={card.id as any}
                                        cardName={card.cardName as any}
                                        layout={card.layout as any}
                                        arrowColor={card.arrowColor as any}
                                        textColor={card.textColor as any}
                                        textIconColor={card.textIconColor as any}
                                        textIconColorHover={card.textIconColorHover as any}
                                        iconColor={card.iconColor as any}
                                        iconColorHover={card.iconColorHover as any}
                                        backgroundIconColor={card.backgroundIconColor as any}
                                        backgroundIconColorHover={card.backgroundIconColorHover as any}
                                        
                                        nSocialIcons={card.nSocialIcons as any}
                                        phone={card.phone as any}
                                        email={card.email as any}
                                        site={card.site as any}
                                        face={card.face as any}
                                        faceId={card.faceId as any}
                                        insta={card.insta as any}
                                        whats={card.whats as any}
                                        location={card.location as any}
                                        tour={card.tour as any}
                                        youtube={card.youtube as any}
                                        linkedin={card.linkedin as any}
                                        github={card.github as any}
                                        twitter={card.twitter as any}
                                        pinterest={card.pinterest as any}

                                        logoControl={card.logoControl as any}

                                        pix={card.pix as any}
                                        bank={card.bank as any}
                                        menu={card.menu as any}
                                        schedule={card.schedule as any}

                                        android={card.android as any}
                                        ios={card.ios as any}
                                        delivery={card.delivery as any}

                                        whatsCC={card.whatsCC as any}
                                        phoneCC={card.phoneCC as any}
                                        phoneCustomCC={card.phoneCustomCC as any}
                                        phoneCustomCC1={card.phoneCustomCC1 as any}
                                        phoneCustomCC2={card.phoneCustomCC2 as any}
                                        phoneCustomCC3={card.phoneCustomCC3 as any}

                                        personalName={card.personalName as any}
                                        description={card.description as any}
                                        occupation={card.occupation as any}
                                        occupationComplement={card.occupationComplement as any}

                                        backgroundF={card.backgroundF as any}
                                        backgroundB={card.backgroundB as any}
                                        photo={card.photo as any}

                                        counter={card.counter as any}
                                        names={names as any}

                                        standardColor={card.standardColor as any}
                                        iconText={card.iconText as any}

                                        contentIcon={card.contentIcon as any}
                                        contentIcon1={card.contentIcon1 as any}
                                        contentIcon2={card.contentIcon2 as any}
                                        contentIcon3={card.contentIcon3 as any}

                                        typeIcon={card.typeIcon as any}
                                        typeIcon1={card.typeIcon1 as any}
                                        typeIcon2={card.typeIcon2 as any}
                                        typeIcon3={card.typeIcon3 as any}
                                        
                                        >

                                        </ModalEditCard>

                                    </ClientCard>
                                );
                            })
                        ) : (
                            <h5>Nenhum cartão encontrado!</h5>
                        )}
                        </div>
                    </main>
                </div>
            }
        </div>
    )
}