import { useState, useEffect } from "react";
import { Button, Modal, OverlayTrigger, Popover } from "react-bootstrap";

import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/modal.scss';
import { database } from "../services/firebase";
import toast from "react-hot-toast";
//import Toggle from "react-toggle";
import { DropzoneEdit } from "./DropzoneEdit";

import { firebase } from "../services/firebase";
import Input from "../components/Input";


export function ModalEditCompany(props: any) {

    const [selectedFileLogoEdit, setSelectedFileLogoEdit] = useState<File>();
    const [selectedFileSeoEdit, setSelectedFileSeoEdit] = useState<File>();

    const [show, setShow] = useState(false);

    const [name, setName] = useState(props.name);
    const [email, setEmail] = useState(props.email);
    const [card, setCard] = useState(props.nCards);
    const [cnpj, setCnpj] = useState(props.cnpj);
    const [address, setAddress] = useState(props.address);
    const [phone, setPhone] = useState(props.phone);
    const [description, setDescription] = useState(props.description);
    const [logo, setLogo] = useState(props.logo);
    const [seo, setSeo] = useState(props.seo);
    const [site, setSite] = useState(props.site);
    const [facebook, setFacebook] = useState(props.facebook);
    const [instagram, setInstagram] = useState(props.instagram);
    const [whatsapp, setWhatsapp] = useState(props.whatsapp);
    const [admin, setAdmin] = useState<boolean>(props.admin);

    useEffect( () => {
        setName(props.name);
        setEmail(props.email);
        setCard(props.nCards);
        setCnpj(props.cnpj);
        setAddress(props.address);
        setPhone(props.phone);
        setDescription(props.description);
        setLogo(props.logo);
        setSeo(props.seo);
        setSite(props.site);
        setFacebook(props.facebook);
        setInstagram(props.instagram);
        setWhatsapp(props.whatsapp);
        setAdmin(props.admin);
        
    }, [props] );
    
    const handleClose = () => {
        setShow(false);

        setName(props.name);
        setEmail(props.email);
        setCard(props.nCards);
        setCnpj(props.cnpj);
        setAddress(props.address);
        setPhone(props.phone);
        setDescription(props.description);
        setLogo(props.logo);
        setSeo(props.seo);
        setSite(props.site);
        setFacebook(props.facebook);
        setInstagram(props.instagram);
        setWhatsapp(props.whatsapp);
        setAdmin(props.admin);

        setSelectedFileLogoEdit( undefined );
        setSelectedFileSeoEdit( undefined );
    };

    const handleCloseSubmitted = () => {
        setShow(false);

        setSelectedFileLogoEdit( undefined );
        setSelectedFileSeoEdit( undefined );
    }
    

    const handleShow = () => setShow(true);

    const putCompany = async () => {

        let logo = '';
        let seo = '';

        handleCloseSubmitted();

        await database.collection("companies").doc(props.id).update({
            name: name,
            email: email,
            nCards: card,
            cnpj: cnpj,
            address: address,
            phone: phone,
            description: description,
            site: site,
            facebook: facebook,
            instagram: instagram,
            whatsapp: whatsapp,
            admin: admin,

            updated_at: new Date()

        }).catch((error) => {
            console.log(error);
        });

        if(selectedFileLogoEdit){
            //Deleta a imagem do logo da empresa 
            await firebase.storage().ref(`${props.id}/Logo`).listAll().then((listImages) => {
                
                const promises = listImages.items.map((item) => {
                    return item.delete();
                });

                Promise.all(promises);
            });

            //Insere a nova Logo da empresa
            await firebase.storage().ref(`${props.id}/Logo/${selectedFileLogoEdit?.name}`).put(selectedFileLogoEdit as File).then( async function(snapshot) {
        
                await snapshot.ref.getDownloadURL().then(function(downloadURL) {
                    logo = downloadURL;
                });
            });
            
            //Atualiza o link do logo
            await database.collection("companies").doc(props.id).update({
                logo: logo,

                updated_at: new Date()
            });
        }

        if(selectedFileSeoEdit){
            //Deleta a imagem do logo da empresa 
            await firebase.storage().ref(`${props.id}/SEO`).listAll().then((listImages) => {
                
                const promises = listImages.items.map((item) => {
                    return item.delete();
                });

                Promise.all(promises);
            });

            //Insere a nova Logo da empresa
            await firebase.storage().ref(`${props.id}/SEO/${selectedFileSeoEdit?.name}`).put(selectedFileSeoEdit as File).then( async function(snapshot) {
        
                await snapshot.ref.getDownloadURL().then(function(downloadURL) {
                    seo = downloadURL;
                });
            });
            
            //Atualiza o link do logo
            await database.collection("companies").doc(props.id).update({
                seo: seo,

                updated_at: new Date()
            });
        }

        setSelectedFileLogoEdit( undefined );
        setSelectedFileSeoEdit( undefined );
    }

    const popoverDescription = (
        <Popover id="popover-basic">
          <Popover.Body>
            Esse campo é utilizado para <strong>SEO</strong> do Cartão Digital. Preencha corretamente!
          </Popover.Body>
        </Popover>
    );

    const popoverLogo = (
        <Popover id="popover-basic">
          <Popover.Body>
            Utilize imagens no formato <strong>PNG</strong> com dimensão de <strong>200x100</strong> pixels em alta resolução para preservar a qualidade do Cartão Digital.<p style={{ marginTop: "8px" }}>Tamanho máximo da imagem: <strong>200 KB</strong></p>
          </Popover.Body>
        </Popover>        
    );

    const popoverSeo = (
        <Popover id="popover-basic">
          <Popover.Body>
            Utilize imagens no formato <strong>PNG</strong> com dimensão de <strong>1200x628</strong> pixels em alta resolução para preservar a qualidade do SEO.<p style={{ marginTop: "8px" }}>Tamanho máximo da imagem: <strong>200 KB</strong></p>
          </Popover.Body>
        </Popover>        
    );
      
    const Description = () => (
        <OverlayTrigger trigger="hover" placement="bottom" overlay={popoverDescription}>
            <svg style={{ marginLeft: "8px" }} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" className="svg-inline--fa fa-info-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" width="18px" viewBox="0 0 512 512">
                <path fill="#c4c4c4" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
            </svg>
        </OverlayTrigger>
    );

    const Logo = () => (
        <OverlayTrigger trigger="hover" placement="bottom" overlay={popoverLogo}>
            <svg style={{ marginLeft: "8px" }} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" className="svg-inline--fa fa-info-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" width="18px" viewBox="0 0 512 512">
                <path fill="#c4c4c4" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
            </svg>
        </OverlayTrigger>
    );

    const Seo = () => (
        <OverlayTrigger trigger="hover" placement="bottom" overlay={popoverSeo}>
            <svg style={{ marginLeft: "8px" }} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" className="svg-inline--fa fa-info-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" width="18px" viewBox="0 0 512 512">
                <path fill="#c4c4c4" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
            </svg>
        </OverlayTrigger>
    );

    return (
        <>
            <button
                className="edit-button"
                type="button"
                aria-label="Editar empresa"
                onClick={handleShow}
            >
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/>
                    <path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"/>
                </svg>
            </button>

            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar empresa {props.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={putCompany}>

                        <label htmlFor="companyName">Nome da empresa: </label>
                        <input 
                            type="text"
                            id="companyName"
                            placeholder="Digite o nome da empresa"
                            onChange={event => setName(event.target.value)}
                            value={name} 
                        />
                        
                        {/*<label htmlFor="femail">E-mail:</label>
                        <input
                            type="email"
                            id="femail"
                            placeholder="Digite o Email"
                            onChange={event => setEmail(event.target.value)}
                            value={email}
                        />*/}

                        <label htmlFor="fcard">Número de cartões digitais:</label>
                        <input
                            type="number"
                            id="fcard"
                            placeholder="Número de Cartões Digitais"
                            onChange={event => setCard(event.target.value)}
                            value={card}
                        />

                        <label htmlFor="fcnpj">CNPJ/CPF: </label>
                        <Input
                            type="text" 
                            mask="cnpj"
                            id="fcnpj"
                            placeholder="Digite o CNPJ"
                            onInputChanged={setCnpj}
                            
                            onChange={event => setCnpj(event.target.value)}
                            value={cnpj}
                        />

                        <label htmlFor="faddress">Endereço:</label>
                        <input
                            type="text"
                            id="faddress"
                            placeholder="Digite o Endereço"
                            onChange={event => setAddress(event.target.value)}
                            value={address}
                        />

                        <label htmlFor="fphone">Telefone: </label>
                        <Input
                            type="text"
                            mask="phone"
                            id="fphone"
                            placeholder="Digite o Telefone"
                            onInputChanged={setPhone}
                            
                            onChange={event => setPhone(event.target.value)}
                            value={phone}
                        />

                        <label htmlFor="fwhatsapp">WhatsApp: </label>
                        <Input
                            type="text" 
                            mask="phone"
                            placeholder="WhatsApp da empresa" 
                            onInputChanged={setWhatsapp}
                            
                            onChange={event => setWhatsapp(event.target.value)}
                            value={whatsapp}
                        />
                        
                        {/*<LabelWithInfo htmlForText="description" labelText="Descrição: " popoverText={["Esse campo é utilizado para ", <strong>SEO</strong>,  " do Cartão Digital. Preencha corretamente!"]} />*/}
                        <div style={{ display: "flex", alignItems: "end" }}>
                            <label htmlFor="description">Descrição da empresa: </label>
                            <Description />
                        </div>
                        <input
                            type="text"
                            id="description"
                            placeholder="Digite a descrição da empresa"
                            onChange={event => setDescription(event.target.value)}
                            value={description}
                        />

                        {/*<LabelWithInfo htmlForText="fileLogoCompany" labelText="Logo da empresa: " popoverText={["Utilize imagens no formato ", <strong>PNG</strong>,  " com tamanho de ", <strong>200x100</strong> ," pixels em alta resolução para preservar a qualidade do Cartão Digital."]} ></LabelWithInfo>*/}
                        <div style={{ display: "flex", alignItems: "end" }}>
                            <label htmlFor="fileLogoCompany">Logo da empresa: </label>
                            <Logo />
                        </div>
                        <DropzoneEdit onFileUploaded={setSelectedFileLogoEdit} logo={props.logo} linkLogo={setLogo} />

                        {/*<LabelWithInfo htmlForText="fileLogoCompany" labelText="Imagem para SEO: " popoverText={["Utilize imagens no formato ", <strong>PNG</strong>,  " com tamanho de ", <strong>1200x628</strong> ," pixels em alta resolução para preservar a qualidade do SEO."]} ></LabelWithInfo>*/}
                        <div style={{ display: "flex", alignItems: "end" }}>
                            <label htmlFor="fileSeoCompany">Imagem para SEO: </label>
                            <Seo />
                        </div>
                        <DropzoneEdit onFileUploaded={setSelectedFileSeoEdit} logo={props.seo} linkLogo={setSeo} />

                        <label htmlFor="fsite">Site:</label>
                        <input
                            type="text"
                            id="fsite"
                            placeholder="Use (https ou http) ex: https://gwcard.com.br"
                            onChange={event => setSite(event.target.value)}
                            value={site}
                        />

                        <label htmlFor="ffacebook">Facebook:</label>
                        <input
                            type="text"
                            id="ffacebook"
                            placeholder="Use (https ou http) ex: https://facebook.com"
                            onChange={event => setFacebook(event.target.value)}
                            value={facebook}
                        />
                        
                        <label htmlFor="finstagram">Instagram:</label>
                        <input
                            type="text"
                            placeholder="Use (https ou http) ex: https://instagram.com"
                            onChange={event => setInstagram(event.target.value)}
                            value={instagram}
                        />
                        
                        {/*<div className="div-admin">
                            <label className="label-admin" htmlFor="fadmin">Administrador?</label>
            
                            <Toggle
                                defaultChecked={props.admin}
                                defaultValue={props.admin}
                                onChange={(e) => {setAdmin(e.target.checked)}}
                            />
                        </div>*/}
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseSubmitted}>Cancelar</Button>
                    <Button variant="primary" onClick={() => {
                        toast.promise(
                            putCompany(),
                            {
                               loading: 'Salvando alterações da empresa...',
                               success: <b>Informações salvas com sucesso!</b>,
                               error: <b>Could not save.</b>,
                            }
                        );
                    }}
                    >Salvar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}