import { ReactNode } from 'react';
import toast from 'react-hot-toast';
import '../styles/company.scss';
import '../styles/toggle.scss';

type CardProps = {
  id: string;
  name: string;
  active: boolean;
  children: ReactNode;
}

export function ClientCard(props: CardProps) {

  return (
    <div className="company">
      <div className="header-company-client-card">
        <div onClick={async () => {

          await navigator.clipboard.writeText(props.name).then( () => {
              toast.success("Nome do cartão digital copiado para área de transferência!");
          });

          }}>
          <p style={{ cursor: "pointer" }}>{props.name}</p>

        </div>
      </div>
  
      <footer>
        <div className="separator"></div>
        <div className="icons">
          {props.children}
        </div>
      </footer>
      
    </div>
  );
}