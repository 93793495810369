import logoImg from '../assets/images/gwcardlogo.svg';
import { useHistory, useParams } from 'react-router-dom';

import '../styles/dashboard.scss';
import { useEffect, useState } from 'react';
import { Card } from '../components/Card';
import { database } from '../services/firebase';
import { ModalLogoutUser } from '../components/ModalLogoutUser';
import { CheckUserAuth } from '../hooks/checkUserAuth';
import { ModalDeleteCard } from '../components/ModalDeleteCard';
import { ModalEditCard } from '../components/ModalEditCard';
import { ModalAddCard } from '../components/ModalAddCard';
import toast from 'react-hot-toast';
import { Loading } from '../components/Loading';
import { Logout } from '../hooks/logout';

type Cards = Record<string,{
    id : string
    idCompany: string,
    backgroudF: string,
    backgroudB: string,
    cardName: string,
    active: boolean,
    layout: number,
    nSocialicons: number,
    qrCode: string,
    phone?: string,
    whats?: string,
    insta?: string,
    face?: string,
    email?: string,
    site?: string,
    location?: string,
    youtube?: string,
    linked?: string,
    github?: string,
    twitter?: string,
    pinterest?: string,
    /////////////////////
    photo?: string,
    personalName?: string,
    occupation?: string,
    occupationComplement?: string,
    description?: string,

    whatsCC?: string,
    phoneCC?: string,
    phoneCustomCC?: string,
    phoneCustomCC1?: string,
    phoneCustomCC2?: string,
    phoneCustomCC3?: string,
}>

export function AdminCards(){

    const history = useHistory();

    const [isLoading, setIsLoading] = useState(true);

    const [cards, setCards] = useState<Cards[]>([]);
    const [foundCards, setFoundCards] = useState(cards);
    const [nameCompany, setNameCompany] = useState('');
    const [logoCompany, setLogoCompany] = useState('');

    const [names, setNames] = useState<Array<string>>([]);
 
    const { idCompanyAdmin } = useParams<{idCompanyAdmin?: string}>();
    const { idCompanyClient } = useParams<{idCompanyClient?: string}>();

    async function getData () {

        let docCompanyRef = database.collection("companies").doc(`${idCompanyAdmin}`);

        await docCompanyRef.get().then((doc) => {
            
            if (doc.exists) {
                //Verifica se o usuário é um administrador 
                if(doc.data()?.admin === false){
                    history.push('/');
                    return;
                }

                if(doc.data()?.active === false){
                    Logout();
                    history.push('/');

                    return;
                }

            } else {
                history.push('/pageNotFound');
                return;
            }
        }).catch((error) => {
            if(error){
                history.push('/');

                return;
            }
        });

        let docRef = database.collection("companies").doc(`${idCompanyClient}`);

        await docRef.get().then((doc) => {
            if (doc.exists) {

                setNameCompany(doc.data()?.name);
                setLogoCompany(doc.data()?.logo);
                
            } else {
                history.push('/pageNotFound');
            }
        }).catch((error) => {
            if(error){
                history.push('/');
            }
        });

        setIsLoading(false);
    }

    CheckUserAuth(idCompanyAdmin as string);

    getData();

    useEffect( () => {

        var  unsubscribe = database.collection("cards").orderBy("cardName").onSnapshot((querySnapshot) => {
            let parsedCards = [{}];
            let parsedNameCards: Array<string> = [];
            parsedCards.shift();
            querySnapshot.forEach((doc) => {
                if(doc.data().idCompany === idCompanyClient) {
                    parsedCards.push(Object.assign(doc.data(), {id: doc.id}));
                    parsedNameCards.push(doc.data().cardName);
                }
            });
            
            setCards(parsedCards);
            setNames(parsedNameCards);
            setFoundCards(parsedCards);

        }, (error) => {
           
            if(error){
                history.push('/');
            }
        });

        return () => {
            unsubscribe();
        }

    }, [history]);

    const [name, setName] = useState('');
    
    const filter = (e: any) => {
        const keyword = e.target.value;

        if (keyword !== '') {

            const lowerBusca = keyword.toLowerCase().normalize('NFD').replace(/[^a-zA-Zs]/g, "");

            const results = cards.filter((card: any) => {

                return card.cardName.toLowerCase().normalize('NFD').replace(/[^a-zA-Zs]/g, "").includes(lowerBusca);
            });

            setFoundCards(results);
        } else {
        setFoundCards(cards);
        }
        setName(keyword);
    };

    function viewCard(cardId: string) {

        const win = window.open(`/card/${idCompanyClient}/${cardId}`);
        win?.focus();
    }

    return(
        <div>
            {isLoading ? <Loading /> :
                <div id="page-dashboard">
                    <header>
                        <div className="content">
                            <img onClick={() => history.push(`/dashboard/admin/${idCompanyAdmin}`)} style={{ cursor: "pointer" }} src={logoImg} alt="Logo Cartão Online Prime" />
                            <ModalLogoutUser />
                        </div>
                    </header>

                    <main style={{ marginBottom: "64px" }}>
                        <div className="path-site">
                            <p>Painel do Administrador - <span style= {{ cursor: "pointer" }} onClick={() => history.goBack()}> Empresas </span> / Cartões</p>
                        </div>
                        <div className="title">
                            <h1>Cartões da empresa {nameCompany}</h1>
                            {cards.length > 0 && <span>{cards.length}</span>}
                            <ModalAddCard
                                logo={logoCompany}
                                nameCompany={nameCompany}
                                idCompany={idCompanyClient}
                                names={names}
                            >

                            </ModalAddCard>
                            
                        </div>

                        <label htmlFor="ffilter" style={{ marginRight: "14px" }}>Busque pela cartão:</label>
                        <input
                            type="search"
                            id="ffilter"
                            value={name}
                            onChange={filter}
                            className="input-filter"
                            placeholder="Filtro"
                        />

                        <div className="company-list">
                        {foundCards && foundCards.length > 0 ? (
                            foundCards.map((card) => {
                                return(
                                    <Card 
                                        key={card.id as any}
                                        name={card.cardName as any}
                                        active={card.active as any}
                                        id={card.id as any}
                                    >
                                        <button
                                            className="cards"
                                            type="button"
                                            aria-label="Visualizar os cartão"
                                            onClick={() => viewCard(card.id as any)}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/>
                                                <path d="M12 6c3.79 0 7.17 2.13 8.82 5.5C19.17 14.87 15.79 17 12 17s-7.17-2.13-8.82-5.5C4.83 8.13 8.21 6 12 6m0-2C7 4 2.73 7.11 1 11.5 2.73 15.89 7 19 12 19s9.27-3.11 11-7.5C21.27 7.11 17 4 12 4zm0 5c1.38 0 2.5 1.12 2.5 2.5S13.38 14 12 14s-2.5-1.12-2.5-2.5S10.62 9 12 9m0-2c-2.48 0-4.5 2.02-4.5 4.5S9.52 16 12 16s4.5-2.02 4.5-4.5S14.48 7 12 7z"/>
                                            </svg>
                                        </button>

                                        <button
                                            className="copy-card-link"
                                            type="button"
                                            aria-label="Visualizar os cartão"
                                            onClick={ async () => {
                                                
                                                await navigator.clipboard.writeText(`https://gowebbycard.com.br/card/${idCompanyClient}/${card.id}`).then( () => {
                                                    toast.success("Link do cartão digital copiado para área de transferência!");
                                                });
                                            }}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 512 512" width="24px" height="24px">
                                                <path d="M200.66 352H144a96 96 0 010-192h55.41M312.59 160H368a96 96 0 010 192h-56.66M169.07 256h175.86" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48"/>
                                            </svg>

                                        </button>

                                        <ModalEditCard
                                        idCompany={card.idCompany as any}
                                        idCard={card.id as any}
                                        cardName={card.cardName as any}
                                        layout={card.layout as any}
                                        arrowColor={card.arrowColor as any}
                                        textColor={card.textColor as any}
                                        textIconColor={card.textIconColor as any}
                                        textIconColorHover={card.textIconColorHover as any}
                                        iconColor={card.iconColor as any}
                                        iconColorHover={card.iconColorHover as any}
                                        backgroundIconColor={card.backgroundIconColor as any}
                                        backgroundIconColorHover={card.backgroundIconColorHover as any}
                                        
                                        nSocialIcons={card.nSocialIcons as any}
                                        phone={card.phone as any}
                                        email={card.email as any}
                                        site={card.site as any}
                                        face={card.face as any}
                                        faceId={card.faceId as any}
                                        insta={card.insta as any}
                                        whats={card.whats as any}
                                        location={card.location as any}
                                        tour={card.tour as any}
                                        youtube={card.youtube as any}
                                        linkedin={card.linkedin as any}
                                        github={card.github as any}
                                        twitter={card.twitter as any}
                                        pinterest={card.pinterest as any}

                                        logoControl={card.logoControl as any}

                                        pix={card.pix as any}
                                        bank={card.bank as any}
                                        menu={card.menu as any}
                                        schedule={card.schedule as any}

                                        android={card.android as any}
                                        ios={card.ios as any}
                                        delivery={card.delivery as any}

                                        whatsCC={card.whatsCC as any}
                                        phoneCC={card.phoneCC as any}
                                        phoneCustomCC={card.phoneCustomCC as any}
                                        phoneCustomCC1={card.phoneCustomCC1 as any}
                                        phoneCustomCC2={card.phoneCustomCC2 as any}
                                        phoneCustomCC3={card.phoneCustomCC3 as any}

                                        personalName={card.personalName as any}
                                        description={card.description as any}
                                        occupation={card.occupation as any}
                                        occupationComplement={card.occupationComplement as any}

                                        backgroundF={card.backgroundF as any}
                                        backgroundB={card.backgroundB as any}
                                        photo={card.photo as any}

                                        counter={card.counter as any}
                                        names={names as any}

                                        standardColor={card.standardColor as any}
                                        iconText={card.iconText as any}

                                        contentIcon={card.contentIcon as any}
                                        contentIcon1={card.contentIcon1 as any}
                                        contentIcon2={card.contentIcon2 as any}
                                        contentIcon3={card.contentIcon3 as any}

                                        typeIcon={card.typeIcon as any}
                                        typeIcon1={card.typeIcon1 as any}
                                        typeIcon2={card.typeIcon2 as any}
                                        typeIcon3={card.typeIcon3 as any}
                                        
                                        >

                                        </ModalEditCard>

                                        <ModalDeleteCard
                                            id={card.id as any}
                                            idCompany={card.idCompany as any}
                                            layout = {card.layout as any}
                                            name={card.cardName as any}
                                        >
                                        </ModalDeleteCard>

                                    </Card>
                                );
                            })
                        ) : (
                            <h5>Nenhum cartão encontrado!</h5>
                        )}
                        </div>

                        {/*<Pagination limit={9} total={cards.length} offset={offSet} setOffset={setOffSet} />*/}

                    </main>
                </div>
            }

        </div>
    )
}