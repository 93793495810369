import { ReactNode, useState } from 'react';
import toast from 'react-hot-toast';
import Toggle from 'react-toggle';
import { database } from '../services/firebase';
import '../styles/company.scss';
import '../styles/toggle.scss';

type CompanyProps = {
  id: string;
  idUser: string;
  name: string;
  email: string;
  active: boolean;
  children: ReactNode;
}

export function Company(props: CompanyProps) {

  const [toggle, setToggle] = useState(props.active);

  return (
    <div className="company">
      <div className="header-company">
        <div onClick={async () => {

          await navigator.clipboard.writeText(props.name).then( () => {
              toast.success("Nome da empresa copiado para área de transferência!");
          });

        }}>
          <p style={{ cursor: "pointer" }}>Empresa: {props.name}</p>
          

        </div>

        <Toggle
          id='cheese-status'
          defaultChecked={props.active}
          defaultValue={props.active as any}
          onChange={(e) => {
            setToggle(e.target.checked);
            database.collection("companies").doc(props.id).set({
              active: e.target.checked,
            }, { merge: true } )
            .then(() => {
                {toggle===true && toast.success(`A empresa ${props.name} foi desativada`)}
                {toggle===false && toast.success(`A empresa ${props.name} foi ativada`)}
            })
            .catch((error) => {
                toast.error("Erro ao editar informações da empresa!")
            });
          }}
        />
      </div>

      <p onClick={ async ()=>{
        await navigator.clipboard.writeText(props.id).then( () => {
          toast.success("ID copiado para área de transferência!");
        });
      }} style={{ cursor: "pointer", fontSize: "12px", color: "#c4c4c4", marginTop: "12px" }}>ID: {props.id}</p>

      <p onClick={ async ()=>{
        await navigator.clipboard.writeText(props.email).then( () => {
          toast.success("Email copiado para área de transferência!");
        });
      }} style={{ cursor: "pointer", fontSize: "12px", color: "#c4c4c4", marginTop: "12px" }}>E-mail: {props.email}</p>
  
      <div className="separator"></div>
      <footer>
        <div className="icons">
          {props.children}
        </div>
      </footer>
      
    </div>
  );
}