import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import pageNotFound from '../assets/images/pageNotFound.svg'
import { Loading } from '../components/Loading';
import { auth, database } from '../services/firebase';

import '../styles/pageNotFound.scss';


export function PageNotFound() {

    const history = useHistory();

    const [userAuth, setUserAuth] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    let idCompany = '';
    let isAdmin = false;

    auth.onAuthStateChanged( async (user) => {
        if (user){

            const result = await database.collection("companies").where("idUser", "==", `${user.uid}`).get();
            
            result.forEach( (doc) => {
                idCompany = doc.id;
                isAdmin = doc.data().admin;
            });

            setIsLoading(false);
            setUserAuth(true);
            return;
        } else {
            setIsLoading(false);
            setUserAuth(false);
            return;
        }
    });

    const backToDashboard = () => {

        if(isAdmin === true){
            history.push(`/dashboard/admin/${idCompany}`);
            return;
        } else {
            history.push(`/dashboard/client/${idCompany}`);
            return;
        }
    }

    return (
        
        <div className="page-not-found">
            {isLoading ? <Loading /> :
                <div style={{ textAlign: 'center', marginTop: "36px" }}>
                    <img style={{ maxWidth: "600px", maxHeight: "600px" }} src={pageNotFound} alt="Imagem de página não encontrada" />
                    <h2>OPSS... Página não encontrada</h2>
                    {userAuth ?
                        <button onClick={() => backToDashboard()}>VOLTAR AO PAINEL</button>
                        :
                        <button onClick={() => history.push('/')}>FAZER LOGIN</button>
                    }
                </div>
            }
        </div>
    );
} 