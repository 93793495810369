import React, { InputHTMLAttributes, ReactNode, useCallback } from "react";
import { cep, phone, cnpj } from './masks';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    mask: "cep" | "phone" | "cnpj" | "facebook";
    country?: "BR" | "PY" | "AR" ;
    onInputChanged: (input: string) => void ;
}

const Input: React.FC<InputProps> = ({mask, onInputChanged, country, ...props}) => {


    const handleKeyUp = useCallback( (e: React.FormEvent<HTMLInputElement> ) => {

        if(mask === "cep"){
            cep(e);
        }
        if(mask === "phone"){
            phone(e, country);
        }
        if(mask === "cnpj"){
            cnpj(e);
        }

        onInputChanged(e.currentTarget.value);
        
    }, [mask, country, onInputChanged]);

    return (
        <>
            <input {...props} onKeyUp={ handleKeyUp } />
        </>
    );
}

export default Input;

