import logoGowebby from '../assets/images/gwcardlogo.svg';

import '../styles/loading.scss';

export function Loading(){
  return (
    
      <div className="content-loading">
        <img className="img-loading bounce" src={logoGowebby} alt="" />
      </div>
    
  )
}