import {useCallback, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import toast from 'react-hot-toast';

import './style.scss';

interface DropzoneProps {
    onFileUploaded: (file: File) => void;
    text: string;
}

export function Dropzone( { onFileUploaded, ...props } : DropzoneProps) {
    const [selectedFileUrl, setSelectedFileUrl] = useState('');
    const [selectedFileName, setSelectedFileName] = useState('');

  const onDrop = useCallback(acceptedFiles => {
      const file = acceptedFiles[0];

      if(!file){
        toast.error("Imagem inválida!");
        return;
      }

      const fileUrl = URL.createObjectURL(file);

      setSelectedFileUrl(fileUrl);
      setSelectedFileName(file.name);

      onFileUploaded(file);
      
  }, [onFileUploaded])

  const {getRootProps, getInputProps} = useDropzone({onDrop, accept: 'image/*', maxSize: 200000});   

  return (
    <div className="dropzone" {...getRootProps()}>
      <input {...getInputProps()} accept="image/*"/>
      { selectedFileUrl
        ? <div className="div-file-img">
            <img className="file-img" src={selectedFileUrl} alt="Background frontal do cartão" /> 
            <p>{selectedFileName}</p>
          </div>
        : <p>
        <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24"/></g><g>
            <path d="M18,15v3H6v-3H4v3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H18z M7,9l1.41,1.41L11,7.83V16h2V7.83l2.59,2.58L17,9l-5-5L7,9z"/></g>
        </svg>
            {props.text}
      </p> }
    </div>
  )
}