import logoImg from '../assets/images/gwcardlogo.svg';
import { useHistory, useParams } from 'react-router-dom';

import '../styles/dashboard.scss';
import { useEffect, useState } from 'react';
import { Company } from '../components/Company';
import { ModalAddCompany } from '../components/ModalAddCompany';
import { database } from '../services/firebase';
import { ModalLogoutUser } from '../components/ModalLogoutUser';
import { CheckUserAuth } from '../hooks/checkUserAuth';
//import { ModalDeleteCompany } from '../components/ModalDeleteCompany';
import { ModalEditCompany } from '../components/ModalEditCompany';
import { Loading } from '../components/Loading';
import { Logout } from '../hooks/logout';

type Companies = Record<string,{
    id : string
    idUser: string,
    name: string,
    email: string,
    phone: string,
    admin: boolean,
    active: boolean
}>

export function AdminDashboard(){

    const { idCompany } = useParams<{idCompany?: string}>();

    CheckUserAuth(idCompany as string );

    async function getData() {

        let docCompanyRef = database.collection("companies").doc(`${idCompany}`);

        let admin = false;
        let active = false;

        await docCompanyRef.get().then((doc) => {
            if (doc.exists) {
                admin = doc.data()?.admin;
                active = doc.data()?.active;

            } else {
                history.push('/pageNotFound');
                return;
            }
        }).catch((error) => {
            if(error){
                history.push('/');
                return;
            }
        });

        //Verifica se o usuário é um administrador 
        if(admin === false){
            history.push('/');
            return;
        }

        if(active === false){
            Logout();
            
            history.push('/');
            return;
        }
    }

    const history = useHistory();
    
    const [companies, setCompanies] = useState<Companies[]>([]);
    const [emails, setEmails] = useState<Array<string>>([]);
    const [foundCompanies, setFoundCompanies] = useState(companies);

    const [isLoading, setIsLoading] = useState(true);

    getData();

    useEffect(() => {

        var unsubscribe = database.collection("companies").orderBy("name").onSnapshot((querySnapshot) => {
            let parsedCompanies = [{}];
            let parsedEmails: Array<string> = [];
            parsedCompanies.shift();
            querySnapshot.forEach((doc) => {
                parsedEmails.push(doc.data().email);

                if(doc.data().mode !== 'operational' ){
                    parsedCompanies.push(Object.assign(doc.data(), {id: doc.id}));
                }
            });
    
            setCompanies(parsedCompanies);
            setEmails(parsedEmails);
            setFoundCompanies(parsedCompanies);
            setIsLoading(false);

            return;

        }, (error) => {
            if(error){
                history.push('/');
                return;
            }
        });

        return () => {
            unsubscribe();
        }

    }, [history]);

    function clickCards(idCompanyClient: string){
        const idCompanyAdmin = idCompany;

        history.push(`/dashboard/admin/${idCompanyAdmin}/${idCompanyClient}/cards`);
    }

    const [name, setName] = useState('');

    const filter = (e: any) => {

        const keyword = e.target.value;

        if (keyword !== '') {

            const lowerBusca = keyword.toLowerCase().normalize('NFD').replace(/[^a-zA-Zs]/g, "");

            const results = companies.filter((company: any) => {

                return company.name.toLowerCase().normalize('NFD').replace(/[^a-zA-Zs]/g, "").includes(lowerBusca);
            });

            setFoundCompanies(results);
        } else {
        setFoundCompanies(companies);
        }
        setName(keyword);
    };
    

    return(
        <div>
            {isLoading ? <Loading /> :
                <div id="page-dashboard">
                    <header>
                        <div className="content">
                            <img src={logoImg} alt="Cartão Online Prime" />
                            <ModalLogoutUser />
                        </div>
                    </header>

                    <main style={{ marginBottom: "64px" }}>
                        <div className="path-site">
                            <p>Painel do Administrador - Empresas</p>
                        </div>
                        <div className="title">
                            <h1>Listagem das empresas</h1>
                            {companies.length > 0 && <span>{companies.length}</span>}
                            <ModalAddCompany emails={ emails } />
                            
                        </div>

                        <label style={{ marginRight: '14px' }} htmlFor="ffilter">Busque pela empresa:</label>
                        <input
                            type="search"
                            id="ffilter"
                            value={name}
                            onChange={filter}
                            className="input-filter"
                            placeholder="Filtro"
                        />

                        <div className="company-list">
                        {foundCompanies && foundCompanies.length > 0 ? (
                            foundCompanies.map((company) => {
                                return(
                                    <Company 
                                        key={company.id as any}
                                        name={company.name as any}
                                        email={company.email as any}
                                        idUser={company.idUser as any}
                                        active={company.active as any}
                                        id={company.id as any}
                                    >
                                        
                                        <button
                                            className="cards"
                                            onClick={() => clickCards(company.id as any)}
                                            type="button"
                                            aria-label="Visualizar os cartões"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><rect fill="none" height="24" width="24" y="0"/></g><g><g><rect height="1.5" width="4" x="14" y="12"/><rect height="1.5" width="4" x="14" y="15"/>
                                                <path d="M20,7h-5V4c0-1.1-0.9-2-2-2h-2C9.9,2,9,2.9,9,4v3H4C2.9,7,2,7.9,2,9v11c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V9 C22,7.9,21.1,7,20,7z M11,7V4h2v3v2h-2V7z M20,20H4V9h5c0,1.1,0.9,2,2,2h2c1.1,0,2-0.9,2-2h5V20z"/><circle cx="9" cy="13.5" r="1.5"/><path d="M11.08,16.18C10.44,15.9,9.74,15.75,9,15.75s-1.44,0.15-2.08,0.43C6.36,16.42,6,16.96,6,17.57V18h6v-0.43 C12,16.96,11.64,16.42,11.08,16.18z"/></g></g>
                                            </svg>
                                        </button>

                                        <ModalEditCompany
                                            id={company.id}
                                            name={company.name}
                                            description={company.description}
                                            address={company.address}
                                            cnpj={company.cnpj}
                                            email={company.email}
                                            employees={company.employees}
                                            facebook={company.facebook}
                                            instagram={company.instagram}
                                            whatsapp={company.whatsapp}
                                            logo={company.logo}
                                            seo={company.seo}
                                            nCards={company.nCards}
                                            phone={company.phone}
                                            site={company.site}
                                            admin={company.admin}
                                        >

                                        </ModalEditCompany>

                                        {/*<ModalDeleteCompany
                                            id={company.id as any}
                                            name={company.name as any}
                                        >
                                        </ModalDeleteCompany>*/}

                                    </Company>
                                );
                            })
                        ) : (
                            <h5>Nenhuma empresa encontrada!</h5>
                        )}
                        </div>
                    </main>
                </div>
            }
        </div>    
    )
}