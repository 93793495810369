import notFound from '../assets/images/notFound.svg';

export function CardNotFound() {
    return(
        <div>

            <div style={{ textAlign: 'center', marginTop: "36px" }}>
                <img src={notFound} style={{ maxWidth: "550px", maxHeight: "550px", padding: '20px' }} alt="Cartão Digital não encontrado" />
                <h2>OPSS... Cartão digital não encontrado!</h2>
            </div>

        </div>
    );
}