import toast from 'react-hot-toast';
//import { useHistory } from 'react-router-dom';
import { auth } from '../services/firebase';


export function Logout () {

    auth.signOut().then(() => {
        toast.success('Você foi deslogado com sucesso.');

        return;

    }).catch((error) => {
        toast.error('Não foi possível deslogar da conta.');

        return;
    });
}