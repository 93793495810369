import {useCallback, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import toast from 'react-hot-toast';

import '../Dropzone/style.scss';

interface DropzoneEditProps {
    onFileUploaded: (file: File) => void;
    linkLogo: (link: string) => void;
    logo: string;
}

export function DropzoneEdit( { onFileUploaded, linkLogo, ...props } : DropzoneEditProps) {
    const [selectedFileUrl, setSelectedFileUrl] = useState('');
    const [selectedFileName, setSelectedFileName] = useState('');

  const onDrop = useCallback(acceptedFiles => {
      const file = acceptedFiles[0];

      if(!file){
        toast.error("Imagem inválida!");
        return;
      }

      const fileUrl = URL.createObjectURL(file);

      setSelectedFileUrl(fileUrl);
      setSelectedFileName(file.name);

      onFileUploaded(file);
      linkLogo(fileUrl);
      
  }, [onFileUploaded, linkLogo])

  const {getRootProps, getInputProps} = useDropzone({onDrop, accept: 'image/*', maxSize: 200000 });   

  return (
    <div className="dropzone" {...getRootProps()}>
      <input {...getInputProps()} accept="image/*"/>
      { selectedFileUrl
        ? <div className="div-file-img">
            <img className="file-img" src={selectedFileUrl} alt="Background frontal do cartão" /> 
            <p>{selectedFileName}</p>
          </div>
        : <p>
        <img src={props.logo} alt=""/>
      </p> }
    </div>
  )
}