import { ReactNode, useState, useEffect } from "react";
import { Button, Modal, OverlayTrigger, Popover } from "react-bootstrap";
import { DropzoneEdit } from "../components/DropzoneEdit";

import Input from "../components/Input";

import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/modal.scss';
import '../styles/card.scss';
import { database, firebase} from "../services/firebase";
import toast from "react-hot-toast";

import Toggle from "react-toggle";
import { ErrorMessage, Field, Form, Formik } from "formik";
import ReactFlagsSelect from "react-flags-select";
//import schemaCard from "../schemas/schemaCard";
//import schemaCardL2 from "../schemas/schemaCardL2";
//import schemaCardL3 from "../schemas/schemaCardL3";
//import schemaCardL4 from "../schemas/schemaCardL4";
//import LabelWithInfo from "./LabelWithInfo";

type ModalEditProps = {
    idCard?: string;
    idCompany?: string;
    logo?: string;
    layout?: number;
    arrowColor?: string;
    textColor?: string;
    textIconColor?: string;
    textIconColorHover?: string;
    iconColor?: string;
    iconColorHover?: string;
    backgroundIconColor?: string;
    backgroundIconColorHover?: string;

    backgroundF: string;
    backgroundB: string;
    photo: string;

    nSocialIcons?: number;

    phone?: string;
    email?: string;
    site?: string;
    face?: string;
    faceId?: string;
    insta?: string;
    whats?: string;
    location?: string;
    tour?: string;
    youtube?: string;
    linkedin?: string;
    github?: string;
    twitter?: string;
    pinterest?: string;

    logoControl?: boolean;

    whatsCC?: string,
    phoneCC?: string,
    phoneCustomCC?: string,
    phoneCustomCC1?: string,
    phoneCustomCC2?: string,
    phoneCustomCC3?: string,

    pix?: string;
    bank?: string;
    menu?: string;
    schedule?: string;

    android?: string;
    ios?: string;
    delivery?: string;

    standardColor?: boolean;
    iconText?: boolean;

    typeIcon?: string;
    typeIcon1?: string;
    typeIcon2?: string;
    typeIcon3?: string;

    contentIcon?: string;
    contentIcon1?: string;
    contentIcon2?: string;
    contentIcon3?: string;

    personalName?: string;
    description?: string;
    occupation?: string;
    occupationComplement?: string;

    counter: number;

    names: string[];

    nameCompany?: string;
    cardName: string;
    children: ReactNode;
}

export function ModalEditCard(props: ModalEditProps) {

    const [nameExists, setNameExists] = useState(false);

    const [selectedFileBackgroundFEdit, setSelectedFileBackgroundFEdit] = useState<File>();
    const [selectedFileBackgroundBEdit, setSelectedFileBackgroundBEdit] = useState<File>();
    const [selectedFileProfilePictureEdit, setSelectedFileProfilePictureEdit] = useState<File>();

    const [backgroundF, setBackgroundF] = useState(props.backgroundF);
    const [backgroundB, setBackgroundB] = useState(props.backgroundB);
    const [photo, setPhoto] = useState(props.photo);

    /** Controle de código de países **/

    const [whatsappSelected, setWhatsappSelected] = useState('');
    const [whatsappCountryCode, setWhatsappCountryCode] = useState(props.whatsCC);

    const [phoneSelected, setPhoneSelected] = useState('');
    const [phoneCountryCode, setPhoneCountryCode] = useState(props.phoneCC);

    const [phoneCustomSelected1, setPhoneCustomSelected1] = useState('');
    const [phoneCustomCountryCode1, setPhoneCustomCountryCode1] = useState(props.phoneCustomCC);
    const [phoneCustomSelected2, setPhoneCustomSelected2] = useState('');
    const [phoneCustomCountryCode2, setPhoneCustomCountryCode2] = useState(props.phoneCustomCC1);
    const [phoneCustomSelected3, setPhoneCustomSelected3] = useState('');
    const [phoneCustomCountryCode3, setPhoneCustomCountryCode3] = useState(props.phoneCustomCC2);
    const [phoneCustomSelected4, setPhoneCustomSelected4] = useState('');
    const [phoneCustomCountryCode4, setPhoneCustomCountryCode4] = useState(props.phoneCustomCC3);

    //Cores dinâmicas do cartão digital
    const [arrowIconColor, setArrowIconColor] = useState(props.arrowColor);
    const [textColor, setTextColor] = useState(props.textColor);
    const [iconColor, setIconColor] = useState(props.iconColor);
    const [iconColorHover, setIconColorHover] = useState(props.iconColorHover);
    const [backgroundIconColor, setBackgroundIconColor] = useState(props.backgroundIconColor);
    const [backgroundIconColorHover, setBackgroundIconColorHover] = useState(props.backgroundIconColorHover);
    const [textIconColor, setTextIconColor] = useState(props.textIconColor);
    const [textIconColorHover, setTextIconColorHover] = useState(props.textIconColorHover);

    const [showAddLayout, setShowAddLayout] = useState(false);
    const [showAddCard, setShowAddCard] = useState(false);
    const [showAddCardColor, setShowAddCardColor] = useState(false);
    const [showControlSocial, setControlSocial] = useState(false);

    const [idMediaControl, setIdMediaControl] = useState('');

    const [cardName, setCardName] = useState(props.cardName);
    const [layout, setLayout] = useState(props.layout);

    // Dynamic Social Icons 
    const [nSocialIcons, setNSocialIcons] = useState(props.nSocialIcons);

    const [phone, setPhone] = useState(props.phone);
    const [email, setEmail] = useState(props.email);
    const [site, setSite] = useState(props.site);
    const [face, setFace] = useState(props.face);
    const [faceId, setFaceId] = useState(props.faceId);
    const [insta, setInsta] = useState(props.insta);
    const [whats, setWhats] = useState(props.whats);
    const [location, setLocation] = useState(props.location);
    const [tour, setTour] = useState(props.tour);
    const [youtube, setYoutube] = useState(props.youtube);
    const [linkedin, setLinkedin] = useState(props.linkedin);
    const [github, setGithub] = useState(props.github);
    const [twitter, setTwitter] = useState(props.twitter);
    const [pinterest, setPinterest] = useState(props.pinterest);


    const [pix, setPix] = useState(props.pix);
    const [bank, setBank] = useState(props.bank);
    const [menu, setMenu] = useState(props.menu);
    const [schedule, setSchedule] = useState(props.schedule);

    const [android, setAndroid] = useState(props.android);
    const [ios, setIos] = useState(props.ios);
    const [delivery, setDelivery] = useState(props.delivery);

    //Variaveis de controle para as social media

    const[countTrueState, setCountTrueState] = useState(0);

    const [phoneControl, setPhoneControl] = useState(false);
    const [emailControl, setEmailControl] = useState(false);
    const [siteControl, setSiteControl] = useState(false);
    const [faceControl, setFaceControl] = useState(false);
    const [facePageControl, setFacePageControl] = useState(false); 
    const [instaControl, setInstaControl] = useState(false);
    const [whatsControl, setWhatsControl] = useState(false);
    const [locationControl, setLocationControl] = useState(false);
    const [tourControl, setTourControl] = useState(false);
    const [youtubeControl, setYoutubeControl] = useState(false);
    const [linkedinControl, setLinkedinControl] = useState(false);
    const [githubControl, setGithubControl] = useState(false);
    const [twitterControl, setTwitterControl] = useState(false);
    const [pinterestControl, setPinterestControl] = useState(false);

    const [logoControl, setLogoControl] = useState(props.logoControl);

    const [pixControl, setPixControl] = useState(false);
    const [bankControl, setBankControl] = useState(false);
    const [menuControl, setMenuControl] = useState(false);
    const [scheduleControl, setScheduleControl] = useState(false);

    const [androidControl, setAndroidControl] = useState(false);
    const [iosControl, setIosControl] = useState(false);
    const [deliveryControl, setDeliveryControl] = useState(false);

    const [iconControl, setIconControl] = useState(false);
    const [iconControl1, setIconControl1] = useState(false);
    const [iconControl2, setIconControl2] = useState(false);
    const [iconControl3, setIconControl3] = useState(false);

    const [standardColor, setStandardColor] = useState(props.standardColor);
    const [iconText, setIconText] = useState(props.iconText);

    const [typeIcon, setTypeIcon] = useState(props.typeIcon);
    const [typeIcon1, setTypeIcon1] = useState(props.typeIcon1);
    const [typeIcon2, setTypeIcon2] = useState(props.typeIcon2);
    const [typeIcon3, setTypeIcon3] = useState(props.typeIcon3);

    const [contentIcon, setContentIcon] = useState(props.contentIcon);
    const [contentIcon1, setContentIcon1] = useState(props.contentIcon1);
    const [contentIcon2, setContentIcon2] = useState(props.contentIcon2);
    const [contentIcon3, setContentIcon3] = useState(props.contentIcon3);

    //Variaveis de componentes especificos de layouts
    const [description, setDescription] = useState(props.description);
    const [personalName, setPersonalName] = useState(props.personalName);
    const [occupation, setOccupation] = useState(props.occupation);
    const [occupationComplement, setOccupationComplement] = useState(props.occupationComplement);

    useEffect( () => {

        // Atualiza os códigos de área dos países

        setWhatsappCountryCode(props.whatsCC === undefined ? "55" : props.whatsCC);
        setPhoneCountryCode(props.phoneCC === undefined ? "55" : props.phoneCC);
        setPhoneCustomCountryCode1(props.phoneCustomCC === undefined ? "55" : props.phoneCustomCC);
        setPhoneCustomCountryCode2(props.phoneCustomCC1 === undefined ? "55" : props.phoneCustomCC1);
        setPhoneCustomCountryCode3(props.phoneCustomCC2 === undefined ? "55" : props.phoneCustomCC2);
        setPhoneCustomCountryCode4(props.phoneCustomCC3 === undefined ? "55" : props.phoneCustomCC3);

        setWhatsappSelected( (props.whatsCC === "55" ? "BR" : "") || (props.whatsCC === "595" ? "PY" : "") || (props.whatsCC === "54" ? "AR" : "") || (props.whatsCC === undefined ? "BR" : ""));
        setPhoneSelected( (props.phoneCC === "55" ? "BR" : "") || (props.phoneCC === "595" ? "PY" : "") || (props.phoneCC === "54" ? "AR" : "") || (props.phoneCC === undefined ? "BR" : "") );
        setPhoneCustomSelected1( (props.phoneCustomCC === "55" ? "BR" : "") || (props.phoneCustomCC === "595" ? "PY" : "") || (props.phoneCustomCC === "54" ? "AR" : "") || (props.phoneCustomCC === undefined ? "BR" : ""));
        setPhoneCustomSelected2( (props.phoneCustomCC1 === "55" ? "BR" : "") || (props.phoneCustomCC1 === "595" ? "PY" : "") || (props.phoneCustomCC1 === "54" ? "AR" : "") || (props.phoneCustomCC1 === undefined ? "BR" : ""));
        setPhoneCustomSelected3( (props.phoneCustomCC2 === "55" ? "BR" : "") || (props.phoneCustomCC2 === "595" ? "PY" : "") || (props.phoneCustomCC2 === "54" ? "AR" : "") || (props.phoneCustomCC2 === undefined ? "BR" : ""));
        setPhoneCustomSelected4( (props.phoneCustomCC3 === "55" ? "BR" : "") || (props.phoneCustomCC3 === "595" ? "PY" : "") || (props.phoneCustomCC3 === "54" ? "AR" : "") || (props.phoneCustomCC3 === undefined ? "BR" : ""));

        //atualiza as props de informações do cartão
        setDescription(props.description);
        setPersonalName(props.personalName);
        setOccupation(props.occupation);
        setOccupationComplement(props.occupationComplement);

        //Atualiza as props das mídias sociais
        setNSocialIcons(props.nSocialIcons);

        setPhone(props.phone);
        setEmail(props.email);
        setSite(props.site);
        setFace(props.face);
        setFaceId(props.faceId);
        setInsta(props.insta);
        setWhats(props.whats);
        setLocation(props.location);
        setTour(props.tour);
        setYoutube(props.youtube);
        setLinkedin(props.linkedin);
        setGithub(props.github);
        setTwitter(props.twitter);
        setPinterest(props.pinterest);

        if( props.logoControl === undefined){
            setLogoControl(true);
        } else {
            setLogoControl(props.logoControl);
        }

        if( props.pix === undefined ){
            setPix('');
        } else {
            setPix(props.pix);
        }

        if( props.bank === undefined ){
            setBank('');
        } else {
            setBank(props.bank);
        }

        if( props.menu === undefined ){
            setMenu('');
        } else {
            setMenu(props.menu);
        }

        if( props.schedule === undefined ){
            setSchedule('');
        } else {
            setSchedule(props.schedule);
        }

        if( props.android === undefined ){
            setAndroid('');
        } else {
            setAndroid(props.android);
        }

        if( props.ios === undefined ){
            setIos('');
        } else {
            setIos(props.ios);
        }

        if( props.delivery === undefined ){
            setDelivery('');
        } else {
            setDelivery(props.delivery);
        }

        //atualiza as props das cores do cartão
        setArrowIconColor(props.arrowColor);
        setTextColor(props.textColor);
        setIconColor(props.iconColor);

        if(props.iconColorHover === undefined || props.iconColorHover === ''){
            setIconColorHover(props.iconColor);

        } else {
            setIconColorHover(props.iconColorHover);
        }
        
        setBackgroundIconColor(props.backgroundIconColor);
        setBackgroundIconColorHover(props.backgroundIconColorHover);
        setTextIconColor(props.textIconColor);
        setTextIconColorHover(props.textIconColorHover);
        setStandardColor(props.standardColor);
        setIconText(props.iconText);

        //atualiza as props das mídias sociais personalizadas
        setTypeIcon(props.typeIcon);
        setTypeIcon1(props.typeIcon1);
        setTypeIcon2(props.typeIcon2);
        setTypeIcon3(props.typeIcon3);

        setContentIcon(props.contentIcon);
        setContentIcon1(props.contentIcon1);
        setContentIcon2(props.contentIcon2);
        setContentIcon3(props.contentIcon3);

    }, [props]);

    const getMediaControl = async () => {

        (await database.collection("mediaControl").where("idCard", "==", `${props.idCard}`).get()).forEach( (doc) => {

                setIdMediaControl(doc?.id);

                setCountTrueState(doc?.data().counter);
                setPhoneControl(doc?.data().phone);
                setWhatsControl(doc?.data().whats);
                setEmailControl(doc?.data().email);
                setSiteControl(doc?.data().site);
                setInstaControl(doc?.data().insta);
                setFaceControl(doc?.data().face);
                setFacePageControl(doc?.data().facePage);
                setLocationControl(doc?.data().location);
                setTourControl(doc?.data().tour);
                setYoutubeControl(doc?.data().youtube);
                setLinkedinControl(doc?.data().linkedin);
                setGithubControl(doc?.data().github);
                setTwitterControl(doc?.data().twitter);
                setPinterestControl(doc?.data().pinterest);

                if(doc?.data().logo === undefined){
                    setLogoControl(true);
                } else {
                    setLogoControl(doc?.data().logo);
                }

                if(doc?.data().pix === undefined){
                    setPixControl(false);
                } else{
                    setPixControl(doc?.data().pix);
                }

                if(doc?.data().bank === undefined){
                    setBankControl(false);
                } else{
                    setBankControl(doc?.data().bank);
                }

                if(doc?.data().menu === undefined){
                    setMenuControl(false);
                } else{
                    setMenuControl(doc?.data().menu);
                }

                if(doc?.data().schedule === undefined){
                    setScheduleControl(false);
                } else{
                    setScheduleControl(doc?.data().schedule);
                }

                if(doc?.data().android === undefined){
                    setAndroidControl(false);
                } else{
                    setAndroidControl(doc?.data().android);
                }

                if(doc?.data().ios === undefined){
                    setIosControl(false);
                } else{
                    setIosControl(doc?.data().ios);
                }

                if(doc?.data().delivery === undefined){
                    setDeliveryControl(false);
                } else{
                    setDeliveryControl(doc?.data().delivery);
                }

                setIconControl(doc?.data().icon);
                setIconControl1(doc?.data().icon1);
                setIconControl2(doc?.data().icon2);
                setIconControl3(doc?.data().icon3);
        });
    }

    //transação de layout para cards
    const layoutClick =  () => {
        setShowAddLayout(false);
        setShowAddCard(true);
    }

    const addInfoCardClick = () => {
        setShowAddLayout(false);
        setShowAddCardColor(true);
    }

    const addCardColorClick = async () => {
        await getMediaControl();
        setShowAddLayout(false);
        setControlSocial(true);
    }

    const backToEdit = () => {
        setShowAddCard(false);
        setControlSocial(false);
        setShowAddCardColor(false);
        setShowAddLayout(true);

        setSelectedFileBackgroundFEdit( undefined );
        setSelectedFileBackgroundBEdit( undefined );
        setSelectedFileProfilePictureEdit( undefined );
    }
    
    const handleClose = () => {
        setShowAddLayout(false);
        setShowAddCard(false);
        setControlSocial(false);
        setShowAddCardColor(false);

        setSelectedFileBackgroundFEdit( undefined );
        setSelectedFileBackgroundBEdit( undefined );
        setSelectedFileProfilePictureEdit( undefined );
    }

    const handleShow = () => setShowAddLayout(true);

    const putCardColor = async () => {

        setShowAddCardColor(false);

        await database.collection('cards').doc(props.idCard).update({
            //Cores do cartão digital
            arrowColor: arrowIconColor,
            textColor: textColor,
            iconColor: iconColor,
            iconColorHover: iconColorHover,
            backgroundIconColor: backgroundIconColor,
            backgroundIconColorHover: backgroundIconColorHover,
            textIconColor: textIconColor,
            textIconColorHover: textIconColorHover,

            standardColor: standardColor,
            iconText: iconText,

            updated_at: new Date()

        }).catch((error) => {
            console.log("Erro ao editar as cores do cartão", error);
        });
    }

    const putCardSocialMedia = async () => {

        setControlSocial(false);

        console.log(logoControl);

        await database.collection('cards').doc(props.idCard).update({
            phone: phone,
            phoneCC: phoneCountryCode,

            email: email,
            location: location,
            tour: tour,
            site: site,
            face: face,
            faceId: faceId,
            insta: insta,

            whats: whats,
            whatsCC: whatsappCountryCode,

            youtube: youtube,
            linkedin: linkedin,
            github: github,
            twitter: twitter,
            pinterest: pinterest,

            pix: pix,
            bank: bank,
            menu: menu,
            schedule: schedule,

            android: android,
            ios: ios,
            delivery: delivery,

            contentIcon: contentIcon,
            phoneCustomCC: phoneCustomCountryCode1,
            contentIcon1: contentIcon1,
            phoneCustomCC1: phoneCustomCountryCode2,
            contentIcon2: contentIcon2,
            phoneCustomCC2: phoneCustomCountryCode3,
            contentIcon3: contentIcon3,
            phoneCustomCC3: phoneCustomCountryCode4,

            typeIcon: typeIcon,
            typeIcon1: typeIcon1,
            typeIcon2: typeIcon2,
            typeIcon3: typeIcon3,
            
            updated_at: new Date()

        }).catch((error) => {
            console.log("Erro ao alterar os links das mídias sociais.", error);
        });

        //Atualiza os controles das mídias sociais
        await database.collection("mediaControl").doc(idMediaControl).update({

            counter: countTrueState,
            phone: phoneControl,
            email: emailControl,
            site: siteControl,
            face: faceControl,
            facePage: facePageControl,
            insta: instaControl,
            whats: whatsControl,
            location: locationControl,
            tour: tourControl,
            youtube: youtubeControl,
            linkedin: linkedinControl,
            github: githubControl,
            twitter: twitterControl,
            pinterest: pinterestControl,

            pix: pixControl,
            bank: bankControl,
            menu: menuControl,
            schedule: scheduleControl,

            android: androidControl,
            ios: iosControl,
            delivery: deliveryControl,

            icon: iconControl,
            icon1: iconControl1,
            icon2: iconControl2,
            icon3: iconControl3,

            updated_at: new Date()

        }).catch((error) => {
            console.log("Erro ao alterar os controles das mídias sociais.", error);
        });
    }

    async function putCardInfo( values: any) {

        setShowAddLayout(false);
        setControlSocial(false);
        setShowAddCard(false);

        let backgroundF = '';
        let backgroundB = '';
        let profilePicture = '';
        
        switch (layout) {
            case 0:
                console.log("Layout is undefined.");
                break;
            case 1:
                await database.collection("cards").doc(props.idCard).update({
                    cardName: values.cardName,
                    nSocialIcons: nSocialIcons,

                    logoControl: logoControl,

                    personalName: values.personalName,
                    occupation: values.occupation,
                    occupationComplement: values.occupationComplement,

                    updated_at: new Date()
                });

                if(selectedFileProfilePictureEdit){
                    
                    //Deleta a imagem do logo da empresa 
                    await firebase.storage().ref(`${props.idCompany}/Cards/${props.idCard}/ProfilePicture`).listAll().then((listImages) => {
                
                        const promises = listImages.items.map((item) => {
                            return item.delete();
                        });

                        Promise.all(promises);
                    });

                    //Insere a nova Logo da empresa
                    await firebase.storage().ref(`${props.idCompany}/Cards/${props.idCard}/ProfilePicture/${selectedFileProfilePictureEdit?.name}`).put(selectedFileProfilePictureEdit as File).then( async function(snapshot) {
                
                        await snapshot.ref.getDownloadURL().then(function(downloadURL) {
                            profilePicture = downloadURL;
                        });
                    });
            
                    //Atualiza o link do logo
                    await database.collection("cards").doc(props.idCard).update({
                        photo: profilePicture
                    });
                }
                break;

            case 2:
                await database.collection("cards").doc(props.idCard).update({
                    cardName: values.cardName,
                    nSocialIcons: nSocialIcons,

                    logoControl: logoControl,

                    personalName: values.personalName,
                    description: values.companyDescription,
                    occupation: values.occupation,

                    updated_at: new Date()
                });
            break;

            case 3:
                await database.collection("cards").doc(props.idCard).update({
                    cardName: values.cardName,
                    nSocialIcons: nSocialIcons,

                    logoControl: logoControl,

                    updated_at: new Date()
                });
            break;

            case 4:
                await database.collection("cards").doc(props.idCard).update({
                    cardName: values.cardName,
                    nSocialIcons: nSocialIcons,

                    logoControl: logoControl,

                    description: values.companyDescription,

                    updated_at: new Date()
                })
            break;

            case 5:
                await database.collection("cards").doc(props.idCard).update({
                    cardName: values.cardName,
                    nSocialIcons: nSocialIcons,

                    logoControl: logoControl,

                    updated_at: new Date()
                });
            break;

            case 6:
                await database.collection("cards").doc(props.idCard).update({
                    cardName: values.cardName,
                    nSocialIcons: nSocialIcons,

                    logoControl: logoControl,

                    description: values.companyDescription, //companyDescription

                    updated_at: new Date()
                });
            break;

            case 7:
                await database.collection("cards").doc(props.idCard).update({
                    cardName: values.cardName,
                    nSocialIcons: nSocialIcons,

                    logoControl: logoControl,

                    description: values.companyDescription,

                    updated_at: new Date()
                });
            break;
        }

        if(selectedFileBackgroundFEdit){

            //Deleta o Background Frontal do Cartão Digital 
            await firebase.storage().ref(`${props.idCompany}/Cards/${props.idCard}/Background/Front`).listAll().then((listImages) => {
                
                const promises = listImages.items.map((item) => {
                    return item.delete();
                });

                Promise.all(promises);
            });

            //Insere o novo Background Frontal do Cartão Digital 
            await firebase.storage().ref(`${props.idCompany}/Cards/${props.idCard}/Background/Front/${selectedFileBackgroundFEdit?.name}`).put(selectedFileBackgroundFEdit as File).then( async function(snapshot) {
        
                await snapshot.ref.getDownloadURL().then(function(downloadURL) {
                    backgroundF = downloadURL;
                });
            });

            //Atualiza o link do background frontal
            await database.collection("cards").doc(props.idCard).update({
                backgroundF: backgroundF,

                updated_at: new Date()
            });
    
        }

        if(selectedFileBackgroundBEdit){

            //Deleta o Background Traseiro do Cartão Digital 
            await firebase.storage().ref(`${props.idCompany}/Cards/${props.idCard}/Background/Back`).listAll().then((listImages) => {
                
                const promises = listImages.items.map((item) => {
                    return item.delete();
                });

                Promise.all(promises);
            });

            //Insere o novo Background Traseiro do Cartão Digital 
            await firebase.storage().ref(`${props.idCompany}/Cards/${props.idCard}/Background/Back/${selectedFileBackgroundBEdit?.name}`).put(selectedFileBackgroundBEdit as File).then( async function(snapshot) {
        
                await snapshot.ref.getDownloadURL().then(function(downloadURL) {
                    backgroundB = downloadURL;
                });
            });

            //Atualiza o link do background traseiro
            await database.collection("cards").doc(props.idCard).update({
                backgroundB: backgroundB,

                updated_at: new Date()
            });

        }

        setSelectedFileBackgroundFEdit( undefined );
        setSelectedFileBackgroundBEdit( undefined );
        setSelectedFileProfilePictureEdit( undefined );
    }

    const verifyCardName = (nameCard: string) => {

        for (var i = 0; i < props.names.length; i++){
            
            if(props.names[i] === nameCard && props.cardName !== nameCard ){
                setNameExists(true);
                return;
            }
        }

        setNameExists(false);
        return;
    }

    const popoverName = (
        <Popover id="popover-basic">
          <Popover.Body>
            Esse campo é utilizado para <strong>SEO</strong> do Cartão Digital. Preencha corretamente!
          </Popover.Body>
        </Popover>
    );

    const popoverPicture = (
        <Popover id="popover-basic">
          <Popover.Body>
            Utilize imagens no formato <strong>PNG</strong> com dimensão de <strong>500x500</strong> pixels em alta resolução para preservar a qualidade do Cartão Digital.<p style={{ marginTop: "8px" }}>Tamanho máximo da imagem: <strong>200 KB</strong></p>
          </Popover.Body>
        </Popover>        
    );

    const popoverBackground = (
        <Popover id="popover-basic">
          <Popover.Body>
            Utilize imagens no formato <strong>PNG</strong> com dimensão de <strong>320x550</strong> pixels em alta resolução para preservar a qualidade do Cartão Digital.<p style={{ marginTop: "8px" }}>Tamanho máximo da imagem: <strong>200 KB</strong></p>
          </Popover.Body>
        </Popover>        
    );
      
    const Name = () => (
        <OverlayTrigger trigger={["hover", "hover"]} placement="bottom" overlay={popoverName}>
            <svg style={{ marginLeft: "8px" }} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" className="svg-inline--fa fa-info-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" width="18px" viewBox="0 0 512 512">
                <path fill="#c4c4c4" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
            </svg>
        </OverlayTrigger>
    );

    const Picture = () => (
        <OverlayTrigger trigger={["hover", "hover"]} placement="bottom" overlay={popoverPicture}>
            <svg style={{ marginLeft: "8px" }} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" className="svg-inline--fa fa-info-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" width="18px" viewBox="0 0 512 512">
                <path fill="#c4c4c4" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
            </svg>
        </OverlayTrigger>
    );

    const Background = () => (
        <OverlayTrigger trigger={["hover", "hover"]} placement="bottom" overlay={popoverBackground}>
            <svg style={{ marginLeft: "8px" }} aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle" className="svg-inline--fa fa-info-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" width="18px" viewBox="0 0 512 512">
                <path fill="#c4c4c4" d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"></path>
            </svg>
        </OverlayTrigger>
    );
  
    return (
        <>
            <button
                className="edit-button"
                type="button"
                aria-label="Editar cartão"
                onClick={handleShow}
            >
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/>
                    <path d="M14.06 9.02l.92.92L5.92 19H5v-.92l9.06-9.06M17.66 3c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z"/>
                </svg>
            </button>

            <Modal show={showAddLayout} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>O que você gostaria de editar?</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <p style={{ fontSize: '14px', color: '#999999' }}>Editando o cartão: {`${props.cardName}`}</p>

                    <div className="button-edit-card">
                        <button onClick={layoutClick}>Informações do cartão</button>

                        <button onClick={addInfoCardClick}>Cores do Cartão</button>

                        <button onClick={addCardColorClick}>Mídias Sociais</button>

                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showAddCard} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Editar informações do Cartão</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    { layout === 1 &&
                        
                        <Formik

                            //validationSchema={schemaCard}
                            initialValues={{
                                cardName: cardName,
                                personalName: personalName,
                                occupation: occupation,
                                occupationComplement: occupationComplement,
                            }}

                            onSubmit={
                                ( values ) => {

                                    toast.promise(
                                        putCardInfo( values as any ),
                                        {
                                            loading: 'Salvando alterações do cartão...',
                                            success: <b>Informações salvas com sucesso!</b>,
                                            error: <b>Could not save.</b>,
                                        }
                                    );
                                    //addInfoCardClick();
                                    setCardName(values.cardName);
                                    setPersonalName(values.personalName);
                                    setOccupation(values.occupation);
                                    setOccupationComplement(values.occupationComplement);
                            }}
                            >
                            { ( { handleBlur } ) => (

                                <Form className="form">

                                    {/*<LabelWithInfo htmlForText="cardName" labelText="Nome do cartão: " popoverText={["Esse campo é utilizado para ", <strong>SEO</strong>,  " do Cartão Digital. Preencha corretamente!"]} />*/}
                                    <div style={{ display: "flex", alignItems: "end" }}>
                                        <label htmlFor="cardName">Nome do cartão: </label>
                                        <Name />
                                    </div>
                                    <Field onBlur={ e => { handleBlur(e); verifyCardName(e.currentTarget.value); }} id="cardName" name="cardName" type="text" placeholder="ex: Cartão Online Prime - Amauri Garrido"></Field>
                                    <ErrorMessage component="span" name="cardName"/>
                                    { nameExists && <span>Nome já cadastrado no sistema!</span> }

                                    {/*<LabelWithInfo htmlForText="fileBackgroundFrontal" labelText="Background frontal do cartão: " popoverText={["Utilize imagens no formato ", <strong>PNG</strong>,  " com tamanho de ", <strong>320x550</strong> ," pixels em alta resolução para preservar a qualidade do Cartão Digital."]} />*/}
                                    <div style={{ display: "flex", alignItems: "end" }}>
                                        <label htmlFor="fileBackgroundFrontal">Background frontal do cartão: </label>
                                        <Background />
                                    </div>
                                    <DropzoneEdit onFileUploaded={setSelectedFileBackgroundFEdit} logo={props.backgroundF} linkLogo={setBackgroundF} />

                                    {/*<LabelWithInfo htmlForText="fileBackgroundBack" labelText="Background traseiro do cartão: " popoverText={["Utilize imagens no formato ", <strong>PNG</strong>,  " com tamanho de ", <strong>320x550</strong> ," pixels em alta resolução para preservar a qualidade do Cartão Digital."]} />*/}
                                    <div style={{ display: "flex", alignItems: "end" }}>
                                        <label htmlFor="fileBackgroundBack">Background traseiro do cartão: </label>
                                        <Background />
                                    </div>
                                    <DropzoneEdit onFileUploaded={setSelectedFileBackgroundBEdit} logo={props.backgroundB} linkLogo={setBackgroundB} />

                                    {/*<LabelWithInfo htmlForText="fileProfilePicture" labelText="Foto de perfil do cartão: " popoverText={["Utilize imagens no formato ", <strong>PNG</strong>,  " com tamanho de ", <strong>500x500</strong> ," pixels em alta resolução para preservar a qualidade do Cartão Digital."]} />*/}
                                    <div style={{ display: "flex", alignItems: "end" }}>
                                        <label htmlFor="fileProfilePicture">Foto de perfil do cartão: </label>
                                        <Picture />
                                    </div>
                                    <DropzoneEdit onFileUploaded={setSelectedFileProfilePictureEdit} logo={props.photo} linkLogo={setPhoto} />

                                    <div className="active-logo" style={{ display: 'flex', alignItems: 'center', marginTop: '24px', marginBottom: "32px" }}>
                                        <p style={{ marginRight: '20px' }} ><strong>Utilizar logo no Cartão Online Prime?</strong></p>
                                        <Toggle
                                            defaultValue={logoControl as any}
                                            defaultChecked={logoControl}
                                            onChange={(e) => {
                                                setLogoControl(e.target.checked);
                                            }}
                                        />
                                    </div>

                                    <label htmlFor="personalName">Nome da pessoa: </label>
                                    <Field id="personalName" name="personalName" type="text" placeholder="ex: Amauri Garrido"></Field>
                                    <ErrorMessage component="span" name="personalName"/>

                                    <label htmlFor="occupation">Cargo: </label>
                                    <Field id="occupation" name="occupation" type="text" placeholder="ex: Analista de E-commerce"></Field>
                                    <ErrorMessage component="span" name="occupation"/>

                                    <label htmlFor="occupationComplement">Complemento de Cargo: </label>
                                    <Field id="occupationComplement" name="occupationComplement" type="text" placeholder="ex: CRM: XXXXX-XX"></Field>
                                    <ErrorMessage component="span" name="occupationComplement"/>

                                    <div style={{ marginTop: "48px", paddingBottom: "0" }}className="modal-footer">
                                        <Button variant="secondary" onClick={backToEdit}>Voltar</Button>
                                        <Button disabled={ (nameExists === true) } variant="primary" type="submit">Salvar</Button>
                                    </div>
                                    
                                </Form>
                            )}
                        </Formik>
                    }

                    { layout === 2 &&
                        
                        <Formik

                            //validationSchema={schemaCardL2}
                            initialValues={{
                                cardName: cardName,
                                personalName: personalName,
                                occupation: occupation,
                                occupationComplement: occupationComplement,
                                companyDescription: description,
                            }}

                            onSubmit={
                                ( values ) => {

                                    toast.promise(
                                        putCardInfo( values as any ),
                                        {
                                            loading: 'Salvando alterações do cartão...',
                                            success: <b>Informações salvas com sucesso!</b>,
                                            error: <b>Could not save.</b>,
                                        }
                                    );

                                    setCardName(values.cardName);
                                    setPersonalName(values.personalName);
                                    setOccupation(values.occupation);
                                    setDescription(values.companyDescription);

                                    //addInfoCardClick();
                            }}
                            >
                            { ( { handleBlur } ) => (

                                <Form className="form">
                                    
                                    {/*<LabelWithInfo htmlForText="cardName" labelText="Nome do cartão: " popoverText={["Esse campo é utilizado para ", <strong>SEO</strong>,  " do Cartão Digital. Preencha corretamente!"]} />*/}
                                    <div style={{ display: "flex", alignItems: "end" }}>
                                        <label htmlFor="cardName">Nome do cartão: </label>
                                        <Name />
                                    </div>
                                    <Field onBlur={ e => { handleBlur(e); verifyCardName(e.currentTarget.value); }} id="cardName" name="cardName" type="text" placeholder="ex: Cartão Online Prime - Amauri Garrido"></Field>
                                    <ErrorMessage component="span" name="cardName"/>
                                    { nameExists && <span>Nome já cadastrado no sistema!</span> }

                                    {/*<LabelWithInfo htmlForText="fileBackgroundFrontal" labelText="Background frontal do cartão: " popoverText={["Utilize imagens no formato ", <strong>PNG</strong>,  " com tamanho de ", <strong>320x550</strong> ," pixels em alta resolução para preservar a qualidade do Cartão Digital."]} />*/}
                                    <div style={{ display: "flex", alignItems: "end" }}>
                                        <label htmlFor="fileBackgroundFrontal">Background frontal do cartão: </label>
                                        <Background />
                                    </div>
                                    <DropzoneEdit onFileUploaded={setSelectedFileBackgroundFEdit} logo={props.backgroundF} linkLogo={setBackgroundF} />

                                    {/*<LabelWithInfo htmlForText="fileBackgroundBack" labelText="Background traseiro do cartão: " popoverText={["Utilize imagens no formato ", <strong>PNG</strong>,  " com tamanho de ", <strong>320x550</strong> ," pixels em alta resolução para preservar a qualidade do Cartão Digital."]} />*/}
                                    <div style={{ display: "flex", alignItems: "end" }}>
                                        <label htmlFor="fileBackgroundBack">Background traseiro do cartão: </label>
                                        <Background />
                                    </div>
                                    <DropzoneEdit onFileUploaded={setSelectedFileBackgroundBEdit} logo={props.backgroundB} linkLogo={setBackgroundB} />

                                    <div className="active-logo" style={{ display: 'flex', alignItems: 'center', marginTop: '24px', marginBottom: "32px" }}>
                                        <p style={{ marginRight: '20px' }} ><strong>Utilizar logo no Cartão Online Prime?</strong></p>
                                        <Toggle
                                            defaultValue={logoControl as any}
                                            defaultChecked={logoControl}
                                            onChange={(e) => {
                                                setLogoControl(e.target.checked);
                                            }}
                                        />
                                    </div>
                                    
                                    <label htmlFor="personalName">Nome da pessoa: </label>
                                    <Field id="personalName" name="personalName" type="text" placeholder="ex: Amauri Garrido"></Field>
                                    <ErrorMessage component="span" name="personalName"/>

                                    <label htmlFor="occupation">Cargo: </label>
                                    <Field id="occupation" name="occupation" type="text" placeholder="ex: Analista de E-commerce"></Field>
                                    <ErrorMessage component="span" name="occupation"/>

                                    <label htmlFor="companyDescription">Descrição: </label>
                                    <Field id="companyDescription" name="companyDescription" type="text" placeholder="ex: Empresa de Marketing Digital"></Field>
                                    <ErrorMessage component="span" name="companyDescription"/>

                                    <div style={{ marginTop: "48px", paddingBottom: "0" }}className="modal-footer">
                                        <Button variant="secondary" onClick={backToEdit}>Voltar</Button>
                                        <Button disabled={ (nameExists === true) } variant="primary" type="submit">Salvar</Button>
                                    </div>
                                    
                                </Form>
                        )}
                        </Formik>

                    }

                    { (layout === 3 || layout === 5) &&
                        
                        <Formik

                            //validationSchema={schemaCardL3}
                            initialValues={{
                                cardName: cardName,
                            }}

                            onSubmit={
                                ( values ) => {

                                    toast.promise(
                                        putCardInfo( values as any ),
                                        {
                                            loading: 'Salvando alterações do cartão...',
                                            success: <b>Informações salvas com sucesso!</b>,
                                            error: <b>Could not save.</b>,
                                        }
                                    );

                                    setCardName(values.cardName);
                                
                                    //addInfoCardClick();
                            }}
                            >
                            { ( { handleBlur } ) => (

                                <Form className="form">
                                    
                                    {/*<LabelWithInfo htmlForText="cardName" labelText="Nome do cartão: " popoverText={["Esse campo é utilizado para ", <strong>SEO</strong>,  " do Cartão Digital. Preencha corretamente!"]} />*/}
                                    <div style={{ display: "flex", alignItems: "end" }}>
                                        <label htmlFor="cardName">Nome do cartão: </label>
                                        <Name />
                                    </div>
                                    <Field onBlur={ e => { handleBlur(e); verifyCardName(e.currentTarget.value); }} id="cardName" name="cardName" type="text" placeholder="ex: Cartão Online Prime - Amauri Garrido"></Field>
                                    <ErrorMessage component="span" name="cardName"/>
                                    { nameExists && <span>Nome já cadastrado no sistema!</span> }

                                    {/*<LabelWithInfo htmlForText="fileBackgroundFrontal" labelText="Background frontal do cartão: " popoverText={["Utilize imagens no formato ", <strong>PNG</strong>,  " com tamanho de ", <strong>320x550</strong> ," pixels em alta resolução para preservar a qualidade do Cartão Digital."]} />*/}
                                    <div style={{ display: "flex", alignItems: "end" }}>
                                        <label htmlFor="fileBackgroundFrontal">Background frontal do cartão: </label>
                                        <Background />
                                    </div>
                                    <DropzoneEdit onFileUploaded={setSelectedFileBackgroundFEdit} logo={props.backgroundF} linkLogo={setBackgroundF} />

                                    {/*<LabelWithInfo htmlForText="fileBackgroundBack" labelText="Background traseiro do cartão: " popoverText={["Utilize imagens no formato ", <strong>PNG</strong>,  " com tamanho de ", <strong>320x550</strong> ," pixels em alta resolução para preservar a qualidade do Cartão Digital."]} />*/}
                                    <div style={{ display: "flex", alignItems: "end" }}>
                                        <label htmlFor="fileBackgroundBack">Background traseiro do cartão: </label>
                                        <Background />
                                    </div>
                                    <DropzoneEdit onFileUploaded={setSelectedFileBackgroundBEdit} logo={props.backgroundB} linkLogo={setBackgroundB} />

                                    <div className="active-logo" style={{ display: 'flex', alignItems: 'center', marginTop: '24px', marginBottom: "32px" }}>
                                        <p style={{ marginRight: '20px' }} ><strong>Utilizar logo no Cartão Online Prime?</strong></p>
                                        <Toggle
                                            defaultValue={logoControl as any}
                                            defaultChecked={logoControl}
                                            onChange={(e) => {
                                                setLogoControl(e.target.checked);
                                            }}
                                        />
                                    </div>
                                    
                                    <div style={{ marginTop: "48px", paddingBottom: "0" }}className="modal-footer">
                                        <Button variant="secondary" onClick={backToEdit}>Voltar</Button>
                                        <Button disabled={ (nameExists === true) } variant="primary" type="submit">Salvar</Button>
                                    </div>
                                    
                                </Form>
                            )}
                        </Formik>
                    }

                    { (layout === 4 || layout === 6 || layout === 7)  &&

                        <Formik

                        //validationSchema={schemaCardL4}
                        initialValues={{
                            cardName: cardName,
                            companyDescription: description,
                        }}

                        onSubmit={
                            ( values ) => {

                                toast.promise(
                                    putCardInfo( values as any ),
                                    {
                                        loading: 'Salvando alterações do cartão...',
                                        success: <b>Informações salvas com sucesso!</b>,
                                        error: <b>Could not save.</b>,
                                    }
                                );

                                setCardName(values.cardName);
                                setDescription(values.companyDescription);

                        }}
                        >
                        { ( { handleBlur } ) => (

                            <Form className="form">
                                
                                {/*<LabelWithInfo htmlForText="cardName" labelText="Nome do cartão: " popoverText={["Esse campo é utilizado para ", <strong>SEO</strong>,  " do Cartão Digital. Preencha corretamente!"]} />*/}
                                <div style={{ display: "flex", alignItems: "end" }}>
                                        <label htmlFor="cardName">Nome do cartão: </label>
                                        <Name />
                                    </div>
                                <Field onBlur={ e => { handleBlur(e); verifyCardName(e.currentTarget.value); }} id="cardName" name="cardName" type="text" placeholder="ex: Cartão Online Prime - Amauri Garrido"></Field>
                                <ErrorMessage component="span" name="cardName"/>
                                { nameExists && <span>Nome já cadastrado no sistema!</span> }

                                {/*<LabelWithInfo htmlForText="fileBackgroundFrontal" labelText="Background frontal do cartão: " popoverText={["Utilize imagens no formato ", <strong>PNG</strong>,  " com tamanho de ", <strong>320x550</strong> ," pixels em alta resolução para preservar a qualidade do Cartão Digital."]} />*/}
                                <div style={{ display: "flex", alignItems: "end" }}>
                                    <label htmlFor="fileBackgroundFrontal">Background frontal do cartão: </label>
                                    <Background />
                                </div>
                                <DropzoneEdit onFileUploaded={setSelectedFileBackgroundFEdit} logo={props.backgroundF} linkLogo={setBackgroundF} />

                                {/*<LabelWithInfo htmlForText="fileBackgroundBack" labelText="Background traseiro do cartão: " popoverText={["Utilize imagens no formato ", <strong>PNG</strong>,  " com tamanho de ", <strong>320x550</strong> ," pixels em alta resolução para preservar a qualidade do Cartão Digital."]} />*/}
                                <div style={{ display: "flex", alignItems: "end" }}>
                                    <label htmlFor="fileBackgroundBack">Background traseiro do cartão: </label>
                                    <Background />
                                </div>
                                <DropzoneEdit onFileUploaded={setSelectedFileBackgroundBEdit} logo={props.backgroundB} linkLogo={setBackgroundB} />

                                <div className="active-logo" style={{ display: 'flex', alignItems: 'center', marginTop: '24px', marginBottom: "32px" }}>
                                        <p style={{ marginRight: '20px' }} ><strong>Utilizar logo no Cartão Online Prime?</strong></p>
                                        <Toggle
                                            defaultValue={logoControl as any}
                                            defaultChecked={logoControl}
                                            onChange={(e) => {
                                                setLogoControl(e.target.checked);
                                            }}
                                        />
                                </div>
                                
                                <label htmlFor="companyDescription">Descrição: </label>
                                <Field id="companyDescription" name="companyDescription" type="text" placeholder="ex: Empresa de Marketing Digital"></Field>
                                <ErrorMessage component="span" name="companyDescription"/>

                                <div style={{ marginTop: "48px", paddingBottom: "0" }}className="modal-footer">
                                    <Button variant="secondary" onClick={backToEdit}>Voltar</Button>
                                    <Button disabled={ (nameExists === true) } variant="primary" type="submit">Salvar</Button>
                                </div>
                                
                            </Form>
                        )}
                        </Formik>
                    }
                </Modal.Body>
            </Modal>

            <Modal show={showAddCardColor} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Cores do Cartão</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                        <div className="standard-color" style={{ display: 'flex', alignItems: 'center' }}>
                            <p style={{ marginRight: '10px',  marginLeft: '20px' }} ><strong>Utilizar cor original dos ícones?</strong></p>
                            <Toggle
                                defaultValue={standardColor as any}
                                defaultChecked={standardColor}
                                onChange={(e) => {
                                    setStandardColor(e.target.checked);
                                }}
                            />
                        </div>

                        <div className="text-icon" style={{ display: 'flex', alignItems: 'center', marginTop: '15px', marginBottom: '15px' }}>
                            <p style={{ marginRight: '10px',  marginLeft: '20px' }} ><strong>Utilizar textos nos ícones?</strong></p>
                            <Toggle
                                defaultValue={iconText as any}
                                defaultChecked={iconText}
                                onChange={(e) => {
                                    setIconText(e.target.checked);
                                }}
                            />
                        </div>

                        <form className="form-color-card">

                            <div style={{ display: 'flex' }}>
                                
                                <label style={{ marginRight: "10px", marginTop: "0" }} htmlFor="arrowIconColor">Seta que vira o cartão digital: </label>
                                <input 
                                    type="color"
                                    id="arrowIconColor"
                                    placeholder="Selecione a cor da seta"
                                    onChange={event => setArrowIconColor(event.target.value)}
                                    value={arrowIconColor} 
                                />

                            </div>

                            <div style={{ display: 'flex', marginTop: "10px" }}>

                                <label style={{ marginRight: "10px", marginTop: "0" }} htmlFor="textColor">Textos do cartão digital: </label>
                                <input 
                                    type="color"
                                    id="textColor"
                                    placeholder="Selecione a cor dos textos"
                                    onChange={event => setTextColor(event.target.value)}
                                    value={textColor} 
                                />

                            </div>

                            <div style={{ display: 'flex', marginTop: "10px" }}>

                                <label style={{ marginRight: "10px", marginTop: "0" }} htmlFor="iconColor">Cor dos ícones: </label>
                                <input
                                    type="color"
                                    id="iconColor"
                                    placeholder="Selecione a cor dos ícones"
                                    onChange={event => setIconColor(event.target.value)}
                                    value={iconColor}
                                />

                            </div>

                            <div style={{ display: 'flex', marginTop: "10px" }}>

                                <label style={{ marginRight: "10px", marginTop: "0" }} htmlFor="backgroundIconColor">Cor de fundo dos ícones: </label>
                                <input
                                    type="color"
                                    id="backgroundIconColor"
                                    placeholder="Selecione a cor de fundo dos ícones"
                                    onChange={event => setBackgroundIconColor(event.target.value)}
                                    value={backgroundIconColor}
                                />
                            
                            </div>

                            <div style={{ display: 'flex', marginTop: "10px" }}>

                                <label style={{ marginRight: "10px", marginTop: "0" }} htmlFor="iTextIconColor">Cor do texto dos ícones: </label>
                                <input
                                    type="color"
                                    placeholder="Selecione a cor do texto dos ícones"
                                    onChange={event => setTextIconColor(event.target.value)}
                                    value={textIconColor}
                                />

                            </div>

                            <p style={{ fontSize: '18px', marginTop: '20px', paddingBottom: "10px", borderBottom: "1px solid #dee2e6" }}><strong>Cor passando o mouse: </strong></p>

                            <div style={{ display: 'flex', marginTop: "10px" }}>

                                <label style={{ marginRight: "10px", marginTop: "0" }} htmlFor="backgroundIconColorHover">Fundo dos ícones: </label>
                                <input
                                    type="color"
                                    id="backgroundIconColorHover"
                                    placeholder="Selecione a cor de fundo dos ícones"
                                    onChange={event => setBackgroundIconColorHover(event.target.value)}
                                    value={backgroundIconColorHover}
                                />

                            </div>

                            <div style={{ display: 'flex', marginTop: "10px" }}>

                                <label style={{ marginRight: "10px", marginTop: "0" }} htmlFor="iconColorHover">Cor dos ícones: </label>
                                <input
                                    type="color"
                                    id="iconColorHover"
                                    placeholder="Cor dos ícones"
                                    onChange={event => setIconColorHover(event.target.value)}
                                    value={iconColorHover}
                                />

                            </div>

                            <div style={{ display: 'flex', marginTop: "10px" }}>

                                <label style={{ marginRight: "10px", marginTop: "0" }} htmlFor="textIconColorHover">Texto dos ícones: </label>
                                <input
                                    type="color"
                                    id="textIconColorHover"
                                    placeholder="Selecione a cor do texto dos ícones"
                                    onChange={event => setTextIconColorHover(event.target.value)}
                                    value={textIconColorHover}
                                />

                            </div>

                            <div style={{ marginTop: "48px", paddingBottom: "0" }}className="modal-footer">
                                <Button variant="secondary" onClick={backToEdit}>Voltar</Button>
                                <Button disabled={ (nameExists === true) } variant="primary" onClick={() => {
                                    toast.promise(
                                        putCardColor(),
                                        {
                                            loading: 'Salvando alterações do cartão...',
                                            success: <b>Informações salvas com sucesso!</b>,
                                            error: <b>Could not save.</b>,
                                        }
                                    );
                                }}>Salvar</Button>
                            </div>

                        </form>

                </Modal.Body>
            </Modal>

            <Modal className="modal-social-media" show={showControlSocial} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Controle de mídias sociais.</Modal.Title>
                    <p className="quantity-social-medias">Max. {nSocialIcons}</p>
                </Modal.Header>
                <Modal.Body>
                    <form className="form-control-media">
                        
                        <label htmlFor="iWhatsApp">WhatsApp: </label>
                        <div style={{ display: "flex", alignItems: "center" }}>

                            <ReactFlagsSelect

                                selected={whatsappSelected}

                                onSelect={(code) => {

                                    setWhatsappSelected(code);

                                    if(code === "BR")
                                        setWhatsappCountryCode("55");
                                    if(code === "PY")
                                        setWhatsappCountryCode("595");
                                    if(code === "AR")
                                        setWhatsappCountryCode("54");
                                }}

                                placeholder="País"
                                countries={["BR", "PY", "AR"]}
                                customLabels={{
                                    "BR": { primary: "+55" },
                                    "PY": { primary: "+595" },
                                    "AR": { primary: "+54" }
                                }}

                                optionsSize={16}
                                selectedSize={16}

                            />
                            <Input 
                                id="iWhatsApp"
                                type="text"
                                mask="phone"
                                country={whatsappSelected as any}
                                placeholder="Número do WhatsApp"
                                onInputChanged={setWhats}

                                onChange={event => setWhats(event.target.value)}
                                value={whats}
                            />
                            <Toggle
                                defaultValue={whatsControl as any}
                                defaultChecked={whatsControl}
                                disabled={ ( countTrueState === nSocialIcons) && (whatsControl !== true)}
                                onChange={(e) => {
                                    setWhatsControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1)
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1)
                                }}
                            />

                        </div>

                        <label htmlFor="iPhone">Telefone: </label>
                        <div style={{ display: "flex", alignItems: "center" }}>

                            <ReactFlagsSelect

                                selected={phoneSelected}

                                onSelect={(code) => {

                                    setPhoneSelected(code);

                                    if(code === "BR")
                                        setPhoneCountryCode("55");
                                    if(code === "PY")
                                        setPhoneCountryCode("595");
                                    if(code === "AR")
                                        setPhoneCountryCode("54");
                                }}

                                placeholder="País"
                                countries={["BR", "PY", "AR"]}
                                customLabels={{
                                    "BR": { primary: "+55" },
                                    "PY": { primary: "+595" },
                                    "AR": { primary: "+54" }
                                }}

                                optionsSize={16}
                                selectedSize={16}
                            />
                            <Input 
                                mask="phone"
                                country={phoneSelected as any}
                                type="text"
                                id="iPhone"
                                placeholder="Digite o Telefone"
                                onInputChanged={setPhone}

                                onChange={event => setPhone(event.target.value)}
                                value={phone}
                            />
                            <Toggle
                                defaultValue={phoneControl as any}
                                defaultChecked={phoneControl}
                                disabled={( countTrueState === nSocialIcons) && (phoneControl !== true)}
                                onChange={(e) => {
                                    setPhoneControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />
                        </div>

                        <label htmlFor="iEmail">E-mail: </label>
                        <div style={{ display: "flex" }}>
                            <input
                                type="email"
                                id="iEmail"
                                placeholder="ex: atendimento@gowebby.com.br"
                                onChange={event => setEmail(event.target.value)}
                                value={email}
                            />
                            <Toggle
                                defaultValue={emailControl as any}
                                defaultChecked={emailControl}
                                disabled={( countTrueState === nSocialIcons) && (emailControl !== true)}
                                onChange={(e) => {
                                    setEmailControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />
                        </div>

                        <label htmlFor="iSite">Site: </label>
                        <div style={{ display: "flex" }}>
                            <input
                                type="text"
                                id="iSite"
                                placeholder="Use (https ou http) ex: https://gwcard.com.br"
                                onChange={event => setSite(event.target.value)}
                                value={site}
                            />
                            <Toggle
                                defaultValue={siteControl as any}
                                defaultChecked={siteControl}
                                disabled={( countTrueState === nSocialIcons) && (siteControl !== true)}
                                onChange={
                                    (e) => {setSiteControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />
                        </div>

                        <label htmlFor="iInstagram">Instagram: </label>
                        <div style={{ display: "flex" }}>
                            <input
                                type="text"
                                id="iInstagram"
                                placeholder="Use (https ou http) ex: https://instagram.com"
                                onChange={event => setInsta(event.target.value)}
                                value={insta}
                            />
                            <Toggle
                                defaultValue={instaControl as any}
                                defaultChecked={instaControl}
                                disabled={( countTrueState === nSocialIcons) && (instaControl !== true)}
                                onChange={
                                    (e) => {setInstaControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />
                        </div>

                        <label htmlFor="iFacebook">Facebook: </label>
                        <div style={{ display: "flex" }}>
                            <input
                                type="text"
                                id="iFacebook"
                                placeholder="Use (https ou http) ex: https://facebook.com"
                                onChange={event => setFace(event.target.value)}
                                value={face}
                            />
                            <Toggle
                                defaultValue={faceControl as any}
                                defaultChecked={faceControl}
                                disabled={( countTrueState === nSocialIcons) && (faceControl !== true)}
                                onChange={
                                    (e) => {setFaceControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />
                        </div>

                        { faceControl &&
                            <div className="div-admin">
                                <label className="label-admin" htmlFor="fadmin">É uma página de empresa?</label>
                
                                <Toggle
                                    className="isCompany"
                                    defaultChecked={facePageControl}
                                    defaultValue={facePageControl as any}
                                    onChange={(e) => {setFacePageControl(e.target.checked)}}
                                />
                            </div> 
                        }

                        { (facePageControl && faceControl) &&

                            <div className="face-page-control">
                                <label htmlFor="iFacebookId">ID do Facebook para Página de Empresa: </label>
                                <input
                                    type="text"
                                    id="iFacebookId"
                                    placeholder="Digite o ID do Facebook"
                                    onChange={event => setFaceId(event.target.value)}
                                    value={faceId}
                                />
                            </div>

                        }

                        { (faceControl && facePageControl === false ) &&

                            <div className="face-page-control">
                            <label htmlFor="iLocation">ID do Facebook para Perfil Pessoal: </label>
                            <input
                                type="text"
                                id="iLocation"
                                placeholder="Digite o ID do Facebook"
                                onChange={event => setFaceId(event.target.value)}
                                value={faceId}
                            />
                            </div>

                        }

                        <label htmlFor="iLocation">Localização: </label>
                        <div style={{ display: "flex" }}>
                            <input
                                type="text"
                                id="iLocation"
                                placeholder="Use (https ou http) ex: https://goo.gl/maps"
                                onChange={event => setLocation(event.target.value)}
                                value={location}
                            />
                            <Toggle
                                defaultValue={locationControl as any}
                                defaultChecked={locationControl}
                                disabled={( countTrueState === nSocialIcons) && (locationControl !== true)}
                                onChange={
                                    (e) => {setLocationControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />
                        </div>

                        <label htmlFor="tour360">Tour 360º: </label>
                        <div style={{ display: "flex" }}>

                            <input
                                type="text"
                                id="tour360"
                                placeholder="Use (https ou http) ex: https://gwcard.com.br"
                                onChange={event => setTour(event.target.value)}
                                value={tour}
                            />
                            <Toggle
                                defaultValue={tourControl as any}
                                defaultChecked={tourControl}
                                disabled={( countTrueState === nSocialIcons) && (tourControl !== true)}
                                onChange={
                                    (e) => {setTourControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />

                        </div>

                        <label htmlFor="iYoutube">Youtube: </label>
                        <div style={{ display: "flex" }}>
                            <input
                                type="text"
                                id="iYoutube"
                                placeholder="Use (https ou http) ex: https://youtube.com"
                                onChange={event => setYoutube(event.target.value)}
                                value={youtube}
                            />
                            <Toggle
                                defaultValue={youtubeControl as any}
                                defaultChecked={youtubeControl}
                                disabled={( countTrueState === nSocialIcons) && (youtubeControl !== true)}
                                onChange={
                                    (e) => {setYoutubeControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />
                        </div>

                        <label htmlFor="iLinkedin">Linkedin: </label>
                        <div style={{ display: "flex" }}>
                            <input
                                type="text"
                                id="iLinkedin"
                                placeholder="Use (https ou http) ex: https://linkedin.com"
                                onChange={event => setLinkedin(event.target.value)}
                                value={linkedin}
                            />
                            <Toggle
                                defaultValue={linkedinControl as any}
                                defaultChecked={linkedinControl}
                                disabled={( countTrueState === nSocialIcons) && (linkedinControl !== true)}
                                onChange={
                                    (e) => {setLinkedinControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />
                        </div>

                        <label htmlFor="iGithub">GitHub: </label>
                        <div style={{ display: "flex" }}>
                            <input
                                type="text"
                                id="iGithub"
                                placeholder="Use (https ou http) ex: https://github.com"
                                onChange={event => setGithub(event.target.value)}
                                value={github}
                            />
                            <Toggle
                                defaultValue={githubControl as any}
                                defaultChecked={githubControl}
                                disabled={( countTrueState === nSocialIcons) && (githubControl !== true)}
                                onChange={
                                    (e) => {setGithubControl(e.target.checked)
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1)
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1)
                                }}
                            />
                        </div>

                        <label htmlFor="iTwitter">Twitter: </label>
                        <div style={{ display: "flex" }}>
                            <input
                                type="text"
                                id="iTwitter"
                                placeholder="Use (https ou http) ex: https://twitter.com"
                                onChange={event => setTwitter(event.target.value)}
                                value={twitter}
                            />
                            <Toggle
                                defaultValue={twitterControl as any}
                                defaultChecked={twitterControl}
                                disabled={( countTrueState === nSocialIcons) && (twitterControl !== true)}
                                onChange={
                                    (e) => {setTwitterControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />
                        </div>

                        <label htmlFor="iPinterest">Pinterest: </label>
                        <div style={{ display: "flex" }}>
                            <input
                                type="text"
                                id="iPinterest"
                                placeholder="Use (https ou http) ex: https://pinterest.com"
                                onChange={event => setPinterest(event.target.value)}
                                value={pinterest}
                            />
                            <Toggle
                                defaultValue={pinterestControl as any}
                                defaultChecked={pinterestControl}
                                disabled={( countTrueState === nSocialIcons) && (pinterestControl !== true)}
                                onChange={
                                    (e) => {setPinterestControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />
                        </div>

                        <label htmlFor="iPix">Pix: </label>
                        <div style={{ display: "flex" }}>
                            <input
                                type="text"
                                id="iPix"
                                placeholder="Use (https ou http) ex: https://banco.com.br"
                                onChange={event => setPix(event.target.value)}
                                value={pix}
                            />
                            <Toggle
                                defaultValue={pixControl as any}
                                defaultChecked={pixControl}
                                disabled={( countTrueState === nSocialIcons) && (pixControl !== true)}
                                onChange={
                                    (e) => {setPixControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />
                        </div>

                        <label htmlFor="iBank">Banco: </label>
                        <div style={{ display: "flex" }}>
                            <input
                                type="text"
                                id="iBank"
                                placeholder="Use (https ou http) ex: https://sharedLink.com.br"
                                onChange={event => setBank(event.target.value)}
                                value={bank}
                            />
                            <Toggle
                                defaultValue={bankControl as any}
                                defaultChecked={bankControl}
                                disabled={( countTrueState === nSocialIcons) && (bankControl !== true)}
                                onChange={
                                    (e) => {setBankControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />
                        </div>

                        <label htmlFor="iMenu">Cardápio: </label>
                        <div style={{ display: "flex" }}>
                            <input
                                type="text"
                                id="iMenu"
                                placeholder="Use (https ou http) ex: https://sharedLink.com.br"
                                onChange={event => setMenu(event.target.value)}
                                value={menu}
                            />
                            <Toggle
                                defaultValue={menuControl as any}
                                defaultChecked={menuControl}
                                disabled={( countTrueState === nSocialIcons) && (menuControl !== true)}
                                onChange={ 
                                    (e) => {setMenuControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />
                        </div>

                        <label htmlFor="iSchedule">Agendamento: </label>
                        <div style={{ display: "flex" }}>
                            <input
                                type="text"
                                id="iSchedule"
                                placeholder="Use (https ou http) ex: https://sharedLink.com.br"
                                onChange={event => setSchedule(event.target.value)}
                                value={schedule}
                            />
                            <Toggle
                                defaultValue={scheduleControl as any}
                                defaultChecked={scheduleControl}
                                disabled={( countTrueState === nSocialIcons) && (scheduleControl !== true)}
                                onChange={ 
                                    (e) => {setScheduleControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />
                        </div>

                        <label htmlFor="iPlayStore">Play Store: </label>
                        <div style={{ display: "flex" }}>

                            <input
                                type="text"
                                id="iPlayStore"
                                placeholder="Use (https ou http) ex: https://playstore.com.br"
                                onChange={event => setAndroid(event.target.value)}
                                value={android}
                            />
                            <Toggle
                                defaultValue={androidControl as any}
                                defaultChecked={androidControl}
                                disabled={( countTrueState === nSocialIcons) && (androidControl !== true)}
                                onChange={
                                    (e) => {setAndroidControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />

                        </div>

                        <label htmlFor="iAppleStore">Apple Store: </label>
                        <div style={{ display: "flex" }}>

                            <input
                                type="text"
                                id="iAppleStore"
                                placeholder="Use (https ou http) ex: https://applestore.com.br"
                                onChange={event => setIos(event.target.value)}
                                value={ios}
                            />
                            <Toggle
                                defaultValue={iosControl as any}
                                defaultChecked={iosControl}
                                disabled={( countTrueState === nSocialIcons) && (iosControl !== true)}
                                onChange={
                                    (e) => {setIosControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />

                        </div>

                        <label htmlFor="iDelivery">Delivery: </label>
                        <div style={{ display: "flex" }}>

                            <input
                                type="text"
                                id="iDelivery"
                                placeholder="Use (https ou http) ex: https://delivery.com.br"
                                onChange={event => setDelivery(event.target.value)}
                                value={delivery}
                            />
                            <Toggle
                                defaultValue={deliveryControl as any}
                                defaultChecked={deliveryControl}
                                disabled={( countTrueState === nSocialIcons) && (deliveryControl !== true)}
                                onChange={
                                    (e) => {setDeliveryControl(e.target.checked);
                                    if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                    if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                }}
                            />

                        </div>

                        <h5 style={{ marginTop: "32px", paddingBottom: "10px", borderBottom: "1px solid #dee2e6" }}><strong>Opções Personalizadas</strong></h5>

                        
                        <span style={{ color: "black", fontSize: "18px", marginRight: "15px" }} ><strong>#1</strong></span>
                        <select name="typeIcon" id="type-icon" style={{ width: "145px" }} onChange={event => setTypeIcon(event.target.value)} value={typeIcon}>
                            <option value="whatsapp">WhatsApp</option>
                            <option value="telefone">Telefone</option>
                            <option value="email">E-mail</option>
                            <option value="site">Site</option>
                            <option value="siteTour">Site Tour</option>
                            <option value="instagram">Intagram</option>
                            <option value="localização">Localização</option>
                            <option value="youtube">Youtube</option>
                            <option value="linkedin">Linkedin</option>
                            <option value="github">GitHub</option>
                            <option value="twitter">Twitter</option>
                            <option value="pinterest">Pinterest</option>
                            <option value="pix">Pix</option>
                            <option value="bank">Banco</option>
                            <option value="menu">Cardápio</option>
                            <option value="schedule">Agendamento</option>
                            <option value="android">Play Store</option>
                            <option value="ios">Apple Store</option>
                            <option value="delivery">Delivery</option>
                            <option value="tiktok">TikTok</option>
                            <option value="360">Tour 360</option>
                            <option value="ifood">Ifood</option>
                            <option value="waze">Waze</option>
                            <option value="salonDigital">Salon Digital</option>
                            <option value="guiaFacil">Guia Fácil</option>
                        </select>

                        { typeIcon === 'whatsapp' || typeIcon === 'telefone' ? 

                            <div style={{ display: "flex", marginTop: "10px", alignItems: "center" }}>

                                <ReactFlagsSelect

                                    selected={phoneCustomSelected1}

                                    onSelect={(code) => {

                                        setPhoneCustomSelected1(code);

                                        if(code === "BR")
                                            setPhoneCustomCountryCode1("55");
                                        if(code === "PY")
                                            setPhoneCustomCountryCode1("595");
                                        if(code === "AR")
                                            setPhoneCustomCountryCode1("54");
                                    }}

                                    placeholder="País"
                                    countries={["BR", "PY", "AR"]}
                                    customLabels={{
                                        "BR": { primary: "+55" },
                                        "PY": { primary: "+595" },
                                        "AR": { primary: "+54" }
                                    }}

                                    optionsSize={16}
                                    selectedSize={16}
                                />
                                <Input 
                                    id="customInput1"
                                    type="text"
                                    mask="phone"
                                    country={phoneCustomSelected1 as any}
                                    onInputChanged={setContentIcon}

                                    onChange={event => setContentIcon(event.target.value)}
                                    value={contentIcon}
                                />

                                <Toggle
                                    defaultValue={iconControl as any}
                                    defaultChecked={iconControl}
                                    disabled={ ( countTrueState === nSocialIcons) && (iconControl !== true)}
                                    onChange={(e) => {
                                        setIconControl(e.target.checked);
                                        if(e.target.checked === true) setCountTrueState(countTrueState + 1)
                                        if(e.target.checked === false) setCountTrueState(countTrueState - 1)
                                    }}
                                />

                            </div>

                            :

                            <div style={{ display: "flex", marginTop: "10px" }}>
                                <input
                                    type="text"
                                    id="customInput1"
                                    placeholder="Use (https ou http) ex: https://gwcard.com.br"
                                    onChange={event => setContentIcon(event.target.value)}
                                    value={contentIcon}
                                />
                                <Toggle
                                    defaultValue={iconControl as any}
                                    defaultChecked={iconControl}
                                    disabled={( countTrueState === nSocialIcons) && (iconControl !== true)}
                                    onChange={
                                        (e) => {setIconControl(e.target.checked);
                                        if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                        if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                    }}
                                />
                                    
                            </div>
    
                        }


                        <div style={{ borderTop: "1px solid #dee2e6", marginTop: "10px", paddingTop: "8px" }} className="personalization-input">
                            <span style={{ color: "black", fontSize: "18px", marginRight: "15px" }} ><strong>#2</strong></span>
                            <select name="typeIcon" id="type-icon" style={{ width: "145px" }} onChange={event => setTypeIcon1(event.target.value)} value={typeIcon1}>
                                <option value="whatsapp">WhatsApp</option>
                                <option value="telefone">Telefone</option>
                                <option value="email">E-mail</option>
                                <option value="site">Site</option>
                                <option value="siteTour">Site Tour</option>
                                <option value="instagram">Intagram</option>
                                <option value="localização">Localização</option>
                                <option value="youtube">Youtube</option>
                                <option value="linkedin">Linkedin</option>
                                <option value="github">GitHub</option>
                                <option value="twitter">Twitter</option>
                                <option value="pinterest">Pinterest</option>
                                <option value="pix">Pix</option>
                                <option value="bank">Banco</option>
                                <option value="menu">Cardápio</option>
                                <option value="schedule">Agendamento</option>
                                <option value="android">Play Store</option>
                                <option value="ios">Apple Store</option>
                                <option value="delivery">Delivery</option>
                                <option value="tiktok">TikTok</option>
                                <option value="360">Tour 360</option>
                                <option value="ifood">Ifood</option>
                                <option value="waze">Waze</option>
                                <option value="salonDigital">Salon Digital</option>
                                <option value="guiaFacil">Guia Fácil</option>
                            </select>

                            { typeIcon1 === 'whatsapp' || typeIcon1 === 'telefone' ? 

                                <div style={{ display: "flex", marginTop: "10px", alignItems: "center" }}>

                                    <ReactFlagsSelect

                                        selected={phoneCustomSelected2}

                                        onSelect={(code) => {

                                            setPhoneCustomSelected2(code);

                                            if(code === "BR")
                                                setPhoneCustomCountryCode2("55");
                                            if(code === "PY")
                                                setPhoneCustomCountryCode2("595");
                                            if(code === "AR")
                                                setPhoneCustomCountryCode2("54");
                                        }}

                                        placeholder="País"
                                        countries={["BR", "PY", "AR"]}
                                        customLabels={{
                                            "BR": { primary: "+55" },
                                            "PY": { primary: "+595" },
                                            "AR": { primary: "+54" }
                                        }}

                                        optionsSize={16}
                                        selectedSize={16}
                                    />
                                    <Input 
                                        id="customInput2"
                                        type="text"
                                        mask="phone"
                                        country={phoneCustomSelected2 as any}
                                        onInputChanged={setContentIcon1}

                                        onChange={event => setContentIcon1(event.target.value)}
                                        value={contentIcon1}
                                    />

                                    <Toggle
                                        defaultValue={iconControl1 as any}
                                        defaultChecked={iconControl1}
                                        disabled={ ( countTrueState === nSocialIcons) && (iconControl1 !== true)}
                                        onChange={(e) => {
                                            setIconControl1(e.target.checked);
                                            if(e.target.checked === true) setCountTrueState(countTrueState + 1)
                                            if(e.target.checked === false) setCountTrueState(countTrueState - 1)
                                        }}
                                    />

                                </div>

                                :

                                <div style={{ display: "flex", marginTop: "10px" }}>
                                    <input
                                        type="text"
                                        id="customInput2"
                                        placeholder="Use (https ou http) ex: https://gwcard.com.br"
                                        onChange={event => setContentIcon1(event.target.value)}
                                        value={contentIcon1}
                                    />
                                    <Toggle
                                        defaultValue={iconControl1 as any}
                                        defaultChecked={iconControl1}
                                        disabled={ ( countTrueState === nSocialIcons) && (iconControl1 !== true) }
                                        onChange={
                                            (e) => {setIconControl1(e.target.checked);
                                            if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                            if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                        }}
                                    />
                                        
                                </div>
                            }
                        </div>

                        <div style={{ borderTop: "1px solid #dee2e6", marginTop: "10px", paddingTop: "8px" }} className="personalization-input">
                            <span style={{ color: "black", fontSize: "18px", marginRight: "15px" }} ><strong>#3</strong></span>
                            <select name="typeIcon" id="type-icon" style={{ width: "145px" }} onChange={event => setTypeIcon2(event.target.value)} value={typeIcon2}>
                                <option value="whatsapp">WhatsApp</option>
                                <option value="telefone">Telefone</option>
                                <option value="email">E-mail</option>
                                <option value="site">Site</option>
                                <option value="siteTour">Site Tour</option>
                                <option value="instagram">Intagram</option>
                                <option value="localização">Localização</option>
                                <option value="youtube">Youtube</option>
                                <option value="linkedin">Linkedin</option>
                                <option value="github">GitHub</option>
                                <option value="twitter">Twitter</option>
                                <option value="pinterest">Pinterest</option>
                                <option value="pix">Pix</option>
                                <option value="bank">Banco</option>
                                <option value="menu">Cardápio</option>
                                <option value="schedule">Agendamento</option>
                                <option value="android">Play Store</option>
                                <option value="ios">Apple Store</option>
                                <option value="delivery">Delivery</option>
                                <option value="tiktok">TikTok</option>
                                <option value="360">Tour 360</option>
                                <option value="ifood">Ifood</option>
                                <option value="waze">Waze</option>
                                <option value="salonDigital">Salon Digital</option>
                                <option value="guiaFacil">Guia Fácil</option>
                            </select>

                            { typeIcon2 === 'whatsapp' || typeIcon2 === 'telefone' ? 

                                <div style={{ display: "flex", marginTop: "10px", alignItems: "center" }}>

                                    <ReactFlagsSelect

                                        selected={phoneCustomSelected3}

                                        onSelect={(code) => {

                                            setPhoneCustomSelected3(code);

                                            if(code === "BR")
                                                setPhoneCustomCountryCode3("55");
                                            if(code === "PY")
                                                setPhoneCustomCountryCode3("595");
                                            if(code === "AR")
                                                setPhoneCustomCountryCode3("54");
                                        }}

                                        placeholder="País"
                                        countries={["BR", "PY", "AR"]}
                                        customLabels={{
                                            "BR": { primary: "+55" },
                                            "PY": { primary: "+595" },
                                            "AR": { primary: "+54" }
                                        }}

                                        optionsSize={16}
                                        selectedSize={16}
                                    />

                                    <Input 
                                        id="customInput3"
                                        type="text"
                                        mask="phone"
                                        country={phoneCustomSelected3 as any}
                                        onInputChanged={setContentIcon2}

                                        onChange={event => setContentIcon2(event.target.value)}
                                        value={contentIcon2}
                                    />

                                    <Toggle
                                        defaultValue={iconControl2 as any}
                                        defaultChecked={iconControl2}
                                        disabled={ ( countTrueState === nSocialIcons) && (iconControl2 !== true)}
                                        onChange={(e) => {
                                            setIconControl2(e.target.checked);
                                            if(e.target.checked === true) setCountTrueState(countTrueState + 1)
                                            if(e.target.checked === false) setCountTrueState(countTrueState - 1)
                                        }}
                                    />

                                </div>

                                :

                                <div style={{ display: "flex", marginTop: "10px" }}>
                                    <input
                                        type="text"
                                        id="customInput3"
                                        placeholder="Use (https ou http) ex: https://gwcard.com.br"
                                        onChange={event => setContentIcon2(event.target.value)}
                                        value={contentIcon2}
                                    />
                                    <Toggle
                                        defaultValue={iconControl2 as any}
                                        defaultChecked={iconControl2}
                                        disabled={ ( countTrueState === nSocialIcons) && (iconControl2 !== true) }
                                        onChange={
                                            (e) => {setIconControl2(e.target.checked);
                                            if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                            if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                        }}
                                    />
                                        
                                </div>
                            }
                        </div>

                        <div style={{ borderTop: "1px solid #dee2e6", marginTop: "10px", paddingTop: "8px" }} className="personalization-input">
                            <span style={{ color: "black", fontSize: "18px", marginRight: "15px" }} ><strong>#4</strong></span>
                            <select name="typeIcon" id="type-icon" style={{ width: "145px" }} onChange={event => setTypeIcon3(event.target.value)} value={typeIcon3}>
                                <option value="whatsapp">WhatsApp</option>
                                <option value="telefone">Telefone</option>
                                <option value="email">E-mail</option>
                                <option value="site">Site</option>
                                <option value="siteTour">Site Tour</option>
                                <option value="instagram">Intagram</option>
                                <option value="localização">Localização</option>
                                <option value="youtube">Youtube</option>
                                <option value="linkedin">Linkedin</option>
                                <option value="github">GitHub</option>
                                <option value="twitter">Twitter</option>
                                <option value="pinterest">Pinterest</option>
                                <option value="pix">Pix</option>
                                <option value="bank">Banco</option>
                                <option value="menu">Cardápio</option>
                                <option value="schedule">Agendamento</option>
                                <option value="android">Play Store</option>
                                <option value="ios">Apple Store</option>
                                <option value="delivery">Delivery</option>
                                <option value="tiktok">TikTok</option>
                                <option value="360">Tour 360</option>
                                <option value="ifood">Ifood</option>
                                <option value="waze">Waze</option>
                                <option value="salonDigital">Salon Digital</option>
                                <option value="guiaFacil">Guia Fácil</option>
                            </select>

                            { typeIcon3 === 'whatsapp' || typeIcon3 === 'telefone' ? 

                                <div style={{ display: "flex", marginTop: "10px", alignItems: "center" }}>

                                    <ReactFlagsSelect

                                        selected={phoneCustomSelected4}

                                        onSelect={(code) => {

                                            setPhoneCustomSelected4(code);

                                            if(code === "BR")
                                                setPhoneCustomCountryCode4("55");
                                            if(code === "PY")
                                                setPhoneCustomCountryCode4("595");
                                            if(code === "AR")
                                                setPhoneCustomCountryCode4("54");
                                        }}

                                        placeholder="País"
                                        countries={["BR", "PY", "AR"]}
                                        customLabels={{
                                            "BR": { primary: "+55" },
                                            "PY": { primary: "+595" },
                                            "AR": { primary: "+54" }
                                        }}

                                        optionsSize={16}
                                        selectedSize={16}
                                    />
                                    <Input 
                                        id="customInput4"
                                        type="text"
                                        mask="phone"
                                        country={phoneCustomSelected4 as any}
                                        onInputChanged={setContentIcon3}

                                        onChange={event => setContentIcon3(event.target.value)}
                                        value={contentIcon3}
                                    />

                                    <Toggle
                                        defaultValue={iconControl3 as any}
                                        defaultChecked={iconControl3}
                                        disabled={ ( countTrueState === nSocialIcons) && (iconControl3 !== true)}
                                        onChange={(e) => {
                                            setIconControl3(e.target.checked);
                                            if(e.target.checked === true) setCountTrueState(countTrueState + 1)
                                            if(e.target.checked === false) setCountTrueState(countTrueState - 1)
                                        }}
                                    />

                                </div>

                                :

                                <div style={{ display: "flex", marginTop: "10px" }}>
                                    <input
                                        type="text"
                                        id="customInput4"
                                        placeholder="Use (https ou http) ex: https://gwcard.com.br"
                                        onChange={event => setContentIcon3(event.target.value)}
                                        value={contentIcon3}
                                    />
                                    <Toggle
                                        defaultValue={iconControl3 as any}
                                        defaultChecked={iconControl3}
                                        disabled={ ( countTrueState === nSocialIcons) && (iconControl3 !== true) }
                                        onChange={
                                            (e) => {setIconControl3(e.target.checked);
                                            if(e.target.checked === true) setCountTrueState(countTrueState + 1);
                                            if(e.target.checked === false) setCountTrueState(countTrueState - 1);
                                        }}
                                    />
                                        
                                </div>
                            }
                        </div>

                        <div style={{ marginTop: "48px", paddingBottom: "0" }}className="modal-footer">
                            <Button variant="secondary" onClick={backToEdit}>Voltar</Button>
                            <Button disabled={ (nameExists === true) } variant="primary" onClick={() => {
                                toast.promise(
                                    putCardSocialMedia(),
                                    {
                                        loading: 'Salvando alterações do cartão...',
                                        success: <b>Informações salvas com sucesso!</b>,
                                        error: <b>Could not save.</b>,
                                    }
                                );
                            }}>Salvar</Button>
                        </div>

                    </form>

                </Modal.Body>
            </Modal>
        </>
    );
}