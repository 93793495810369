import { FormEvent, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import logoImg from '../assets/images/gwcardlogo.svg';
import { auth } from '../services/firebase';

import '../styles/modal.scss';

export function LoginChangePassword(){

    const history = useHistory();

    const [confirmEmail, setConfirmEmail] = useState('');
    const [sentEmail, setSentEmail] = useState(false);

    const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);

    const sendEmail = async (event: FormEvent) => {
      event.preventDefault();

      setIsLoadingUpdate(true);

      auth.sendPasswordResetEmail(confirmEmail)
      .then(() => {
          toast.success("E-mail para alteração de senha enviado!");
          setIsLoadingUpdate(false);
          setSentEmail(true);
      })
      .catch((error) => {
          if(error.code === "auth/user-not-found"){
            toast.error("E-mail não cadastrado no sistema.");
            setIsLoadingUpdate(false);
            return;
          } else {
            toast.error("Erro ao enviar e-mail.");
            setIsLoadingUpdate(false);
            return;
          }
      });
        
    }

    return (
        
        <div id="page-dashboard">
            <header>
                <div className="content-client-dashboard">
                    <div className="content-left">
                        <img style={{ cursor: "pointer" }} onClick={() => history.push("/")} src={logoImg} alt="Logo Cartão Online Prime" />
                        {/*<p style={{ fontWeight: 'bold' }}>Bem-vinda(o), {nameCompany}!</p>*/}
                    </div>
                    <div className="content-right">
                        <a style={{ textDecoration: 'none' }} href="https://api.whatsapp.com/send?phone=5517920002146" target="_blank" rel="noreferrer"><button className="button-entre-contato" >Entre em contato</button></a>
                    </div>
                </div>
            </header>
                {sentEmail ?
                    <main>
                        <div className="title" style={{ textAlign: "center", display: "block", marginBottom: "48px" }}>
                            <h1 style={{marginRight: '12px', marginTop: '10px'}}>E-mail para alteração de senha enviado!</h1>
                        </div>
                        <p style={{ lineHeight: "22px" }}>Enviamos um e-mail, para que você possa alterar sua senha! Verifique se o email está na sua <strong>Caixa de Spam</strong> ou <strong>Lixo Eletrônico</strong>. Em caso de dúvidas, entre em contato conosco!</p>
                        <button style={{ margin: "24px auto 0 auto" }} onClick={() => history.push('/')} className="button-entre-contato">Fazer Login</button>
                    </main>
                    
                    :
                    <main>  
                        <div className="title">
                            <h1 style={{ marginTop: '10px'}}>Deseja alterar sua senha?</h1>
                        </div>

                        <div style={{ display: 'grid', marginTop: '16px'}}>

                            <div style={{ display: 'flex' }}>
                              <form style={{ paddingLeft: "0" }}>
                                <label htmlFor="email-change-password">Digite seu email cadastrado: </label>
                                  <input
                                      style={{ marginRight: '12px' }}
                                      type="email"
                                      id="email-change-password"
                                      placeholder="E-mail"
                                      onChange={event => setConfirmEmail(event.target.value)}
                                      value={confirmEmail}
                                  />
                                  <button style={{ marginTop: '8px' }} onClick={sendEmail} className="button-change-password">
                                      { isLoadingUpdate ? 
                                          <Spinner
                                              animation="border"
                                              size="sm"
                                              role="status"
                                              aria-hidden="true"
                                          />
                                      :
                                          <>Alterar</>  
                                      } 
                                  </button>
                              </form>
                            </div>
                        </div>
                    </main>
                }
        </div>
    );        
}